// import React, { useState, useEffect } from 'react'
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import {  MuiThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";

const columns = [
  {
    name: "assemble_name",
    label: "Assemble Name",
    type: "select",
    error: false,
    disabled: false,
  },
  {
    name: "vendor_id",
    label: "Vendor",
    error: false,
    type: "select",
    options: ["vendor1", "vendor2", "vendor3"],
    disabled: false,
  },
  // {
  //     name: 'assemble_code',
  //     label: 'Assemble Code',
  //     type: 'text',
  //     error: false,
  //     disabled: true

  // },
  {
    name: "request_qty",
    label: "request Quantity",
    type: "number",
    disabled: false,
    error: false,
  },
  {
    name: "total_cost",
    label: "Cost",
    type: "number",
    error: false,
    disabled: false,
  },

  {
    name: "request_date",
    label: "Request Date",
    type: "date",
    error: false,
    disabled: true,
  },
];

const AddTableColumns = [
  {
    name: "item_name",
    label: "item",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "qty",
    label: "Quantity",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "avblqty",
    label: "Available Quantity",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "avalQty",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
];
const ViewTableColumns = [
  {
    name: "item_name",
    label: "item",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "qty",
    label: "Quantity",
    options: {
      filter: true,
      sort: false,
    },
  },
];

let updateRequest = false;

function MakeARequestForm(props) {
  const {
    fields,
    onChange,
    CONSTANTS,
    mode,
    makeRequest,
    assemble,
    equipment,
    vendors,
    availabelAssemble,
    availabelAssembledUnits,
  } = props;
  let history = useHistory();

  const getrawMaterials = () => {
    let collectRawmaterial = [];

    console.log(availabelAssemble);

    fields?.raw_material?.forEach((rawrl) => {
      if (availabelAssemble.length) {
        availabelAssemble.forEach((equp) => {
          if (rawrl.item_id === equp._id) {
            let updateQty =
              equp.equipment_qty -
              (fields.request_qty >= 1
                ? fields.request_qty * rawrl.item_qty
                : 0);

            if (mode === CONSTANTS.VIEW) {
              collectRawmaterial.push({
                item_id: equp._id,
                qty: parseInt(rawrl.item_qty),
                item_name: equipment[equp._id],
              });
            } else {
              collectRawmaterial.push({
                item_id: equp._id,
                qty: fields.request_qty
                  ? parseInt(fields.request_qty) * parseInt(rawrl.item_qty)
                  : parseInt(rawrl.item_qty),
                item_name: equipment[equp._id],
                avblqty: updateQty > 0 ? updateQty : 0,
                reqQty:
                  rawrl.item_qty < equp.equipment_qty
                    ? updateQty >= 0
                      ? true
                      : false
                    : false,
                avalQty:
                  rawrl.item_qty < equp.equipment_qty ? (
                    updateQty >= 0 ? (
                      <Badge badgeContent={true} color="primary" />
                    ) : (
                      <Badge badgeContent={true} color="error" />
                    )
                  ) : (
                    <Badge badgeContent={true} color="error" />
                  ),
              });
            }
          }
        });
      }
    });

    let makeRequest = collectRawmaterial.every(
      (item, i) => item.reqQty === true,
    );
    updateRequest = makeRequest;
    return collectRawmaterial;
  };
  const getAssemblyMaterials = () => {
    let collectAssemblyMaterial = [];

    console.log(availabelAssembledUnits);

    fields?.assemble_items?.forEach((rawrl) => {
      if (availabelAssembledUnits.length) {
        availabelAssembledUnits.forEach((assembledUnit) => {
          if (rawrl.item_id === assembledUnit._id) {
            let updateQty =
              assembledUnit.assembled_unit -
              (fields.request_qty >= 1
                ? fields.request_qty * rawrl.item_qty
                : 0);

            if (mode === CONSTANTS.VIEW) {
              collectAssemblyMaterial.push({
                item_id: assembledUnit._id,
                qty: parseInt(rawrl.item_qty),
                item_name: assembledUnit.assemble_name,
              });
            } else {
              collectAssemblyMaterial.push({
                item_id: assembledUnit._id,
                qty: fields.request_qty
                  ? parseInt(fields.request_qty) * parseInt(rawrl.item_qty)
                  : parseInt(rawrl.item_qty),
                item_name: assembledUnit.assemble_name,
                avblqty: updateQty > 0 ? updateQty : 0,
                reqQty:
                  rawrl.item_qty < assembledUnit.assembled_unit
                    ? updateQty >= 0
                      ? true
                      : false
                    : false,
                avalQty:
                  rawrl.item_qty < assembledUnit.assembled_unit ? (
                    updateQty >= 0 ? (
                      <Badge badgeContent={true} color="primary" />
                    ) : (
                      <Badge badgeContent={true} color="error" />
                    )
                  ) : (
                    <Badge badgeContent={true} color="error" />
                  ),
              });
            }
          }
        });
      }
    });

    let makeRequest = collectAssemblyMaterial.every(
      (item, i) => item.reqQty === true,
    );
    updateRequest = makeRequest;
    return collectAssemblyMaterial;
  };

  return (
    <>
      <Grid container style={{ minWidth: "1200px" }}>
        <Grid
          item
          xs={12}
          container
          spacing={1}
          style={{ padding: "10px", maxHeight: "400px" }}
        >
          {columns.map((column, i) => {
            if (column.type === "text" || column.type === "number") {
              return (
                <Grid item xs={3} key={i}>
                  <TextField
                    fullWidth
                    name={column.name}
                    label={column.label}
                    variant="outlined"
                    type={column.type}
                    value={fields[column.name]}
                    disabled={
                      column.disabled || mode === CONSTANTS.VIEW ? true : false
                    }
                    onChange={mode === CONSTANTS.ADD ? onChange : null}
                    error={column.error}
                  />
                </Grid>
              );
            }
            if (column.type === "select") {
              return (
                <Grid item xs={3} key={i}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      {column.label}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={column.label}
                      name={column.name}
                      value={fields[column.name]}
                      onChange={mode === CONSTANTS.ADD ? onChange : null}
                      type={column.type}
                      disabled={
                        column.disabled || mode === CONSTANTS.VIEW
                          ? true
                          : false
                      }
                    >
                      {column.name === "assemble_name"
                        ? assemble.map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              {item.assemble_name}
                            </MenuItem>
                          ))
                        : vendors.map((vendor, index) => (
                            <MenuItem key={index} value={vendor._id}>
                              {vendor.vendor_name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              );
            }
          })}
        </Grid>
      </Grid>
      <Divider style={{ margin: " 20px 0px" }} />
      <Grid container style={{ minWidth: "1200px", margin: "auto" }}>
        <Grid item xs={5}>
           {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
            <MUIDataTable
              title="Equipments"
              data={getrawMaterials()}
              columns={
                mode === CONSTANTS.VIEW ? ViewTableColumns : AddTableColumns
              }
              options={{
                rowsPerPage: 5,
                selectableRows: false,
                rowsPerPageOptions: [5, 10, 15],
                search: false,
                download: false,
                print: false,
                filter: false,
              }}
            />
          </MuiThemeProvider>
        </Grid>
        <Grid item xs={5} style={{ marginLeft: "10px" }}>
           {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
            <MUIDataTable
              title="Assembly"
              data={getAssemblyMaterials()}
              columns={
                mode === CONSTANTS.VIEW ? ViewTableColumns : AddTableColumns
              }
              options={{
                rowsPerPage: 5,
                selectableRows: false,
                rowsPerPageOptions: [5, 10, 15],
                search: false,
                download: false,
                print: false,
                filter: false,
              }}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
      {mode === CONSTANTS.ADD && (
        <Grid
          style={{
            width: "100%",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            size="medium"
            color="primary"
            onClick={makeRequest}
            disabled={updateRequest ? false : true}
          >
            Make A Request
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="medium"
            style={{ marginLeft: "10px", backgroundColor: "#fe6854" }}
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Grid>
      )}
    </>
  );
}

export default MakeARequestForm;
