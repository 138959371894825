import { Button, TextField, Grid, FormControl } from '@material-ui/core';
import React, { useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';


// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//         },
//     },
//     input: {
//         display: 'none',
//     },

//     item: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         padding: "20px"
//     },
//     dnd: {
//         border: "3px dashed #ccbbbb",
//         minWidth: "100%",
//         minHeight: "180px",
//         borderRadius: '10px',
//         backgroundColor: "#EBF1FB",
//         margin: '10px 0px'
//     },
//     hover: {
//         border: "5px dashed #ccbbbb",
//         minWidth: "100%",
//         minHeight: "180px",
//         borderRadius: '10px',
//         backgroundColor: "#EBF1FB",
//         margin: '10px 0px'
//     },

// }));

function ImporMonthtModal({ UpdateMonthDate, handleClose }) {

    // const classes = useStyles();
    const [date, setDate] = useState(new Date().toDateString())

    const dateHandler = (values) => {
        setDate(values);
    }

    const handleSubmit = () => {
        console.log("ajajj--hello")
        UpdateMonthDate(date)
        handleClose()

    }
    // const deleteFile = (index) => {
    //     setDate(new Date().toDateString());
    // }

    return (
        <>
            <Grid item xs={12} sm={6} >

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl fullWidth >
                        <DatePicker
                            views={['year', 'month']}
                            variant='outlined'
                            label="Filter Date"
                            fullWidth

                            value={date}
                            onChange={dateHandler}
                            renderInput={(params) => <TextField variant='outlined'
                                {...params} />}
                        />
                    </FormControl>
                </MuiPickersUtilsProvider>
            </Grid>
            {/* <Grid container spacing={1}>
                <Grid item>
                    <Chip
                        label={date.toDateString()}

                        onDelete={() => deleteFile()}

                        variant="outlined"
                    />
                </Grid>
            </Grid> */}

            <Grid
                style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >

                <Button
                    variant="contained"
                    type='submit'
                    size="medium"
                    color="secondary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>

            </Grid>
        </>
    )
}

export default ImporMonthtModal