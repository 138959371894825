import React, { useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";

function MakeAnOrder(props) {
  const { handleMakeAnOrder, orders } = props;

  const [order, setOrder] = useState(null);
  const [note, setNote] = useState("");

  async function createOrder(values) {
    if (order === null) {
      toast.warning("Order is required!");
      return "";
    }
    let data = {
      orderId: order._id,
      note: note,
    };
    handleMakeAnOrder(data);
  }

  // Function to calculate total quantity from the orders array
  const calculateTotalQuantity = () => {
    if (!order || !order.order_items || !order.order_items.length) {
      return 0;
    }
    return order.order_items.reduce((totalQuantity, product) => {
      return totalQuantity + product.quantity;
    }, 0);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid>
          <Grid>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id="disable-clearable"
                    options={orders}
                    name="order"
                    getOptionLabel={(option) =>
                      option?.user_info?.name +
                      " [ " +
                      option?.user_info?.phone +
                      " ] "
                    }
                    value={order}
                    onChange={(event, newValue) => {
                      setOrder(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Order"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option?._id}>
                        {option?.user_info?.name +
                          " [ " +
                          option?.user_info?.phone +
                          " ] "}
                      </li>
                    )}
                  />
                </Grid>
                {order && (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Order Info
                    </Typography>
                    <TableContainer component={Paper} variant="outlined">
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell>{order._id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>User Name</TableCell>
                            <TableCell>{order.user_info.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>User Phone</TableCell>
                            <TableCell>{order.user_info.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Product Quantity</TableCell>
                            <TableCell>{calculateTotalQuantity()}</TableCell>
                          </TableRow>
                          {/* Add more rows for other fields you want to display */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="Note"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    onChange={(event) => {
                      setNote(event.target.value);
                    }}
                    style={{ marginTop: "10px" }}
                  />
                </Grid>
                <Grid
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={createOrder}
                    size="medium"
                    color="secondary"
                  >
                    Add Return
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MakeAnOrder;
