import React, { useEffect, useState } from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import * as Yup from "yup";
import { useFormik } from "formik";
import API from '../../../../services/API';



const columns = [

    {
        name: 'equipment_name',
        label: 'Equipment Name',
        type: 'text',
    },
    {
        name: 'equipment_sku',
        label: 'SKU',
        type: 'text',
    },
    // {
    //     name:  'equipment_qty',
    //     label: 'Equipment Quantity',
    //     type: 'number',
    // },
    {
        name: 'equipment_unit',
        label: 'Unit',
        type: 'select',
        options: ['kg', 'piece', 'litre', 'ml', 'other']

    },
    {
        name: 'equipment_category',
        label: 'Categories',
        type: 'select',
        options: ['Electronics', 'Electronics']
    },
    // {
    //     name: 'equipment_cost',
    //     label: 'Cost',
    //     type: 'number',
    // },


]


const EquipmentSchema = Yup.object().shape({
    equipment_name: Yup.string()
        .min(3, "Equipment Name must be 3 characters at minimum")
        .required("Equipment Name is required"),
    // equipment_cost: Yup.string()
    //     .min(1, "Cost must be 3 characters at minimum")
        // .required("Cost is required"),
    equipment_sku: Yup.string()
        .required("SKU  is required"),
    // equipment_qty: Yup.string()
    //     .required("Equipment quantity  is required"),
        equipment_category: Yup.string()
        .required("Categories  is required"),
    equipment_unit: Yup.string()
        .required("Unit is required"),

});





function ModalForm(props) {

    const { CONSTANTS, modal, fields, handleSave, handleUpdate } = props

    const [categories, setCategories] = useState([])


    let formik = useFormik({
        initialValues: fields,
        validationSchema: EquipmentSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
            handleSubmit(values)
        },
    });



    const handleSubmit = (values) => {

        if (modal === CONSTANTS.ADD) {
            handleSave({ ...values })
        } else if (modal === CONSTANTS.EDIT) {
            handleUpdate({ ...values })
        }

    }


    useEffect(() => {

        API.get('/equipment-categories/all').then(response => {
            console.log();
            setCategories([...response.data.Categories])
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            console.log('finally')
        })

        if (modal === CONSTANTS.ADD) {
            formik.resetForm();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                {columns.map((column, i) => {
                    if (column.type === 'text' || column.type === 'number') {
                        return (
                            <Grid item xs={12} sm={6} key={i}>
                                <TextField
                                    fullWidth
                                    name={column.name}
                                    label={column.label}
                                    variant="outlined"
                                    type={column.type}
                                    value={formik.values[column.name]}
                                    disabled={modal === CONSTANTS.VIEW}
                                    onChange={formik.handleChange}
                                    error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                    helperText={formik.touched[column.name] && formik.errors[column.name]}
                                />
                            </Grid>
                        )
                    }
                    if (column.type === 'select') {
                        return (
                            <Grid item xs={12} sm={6} key={i}>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={column.label}
                                        value={formik.values[column.name]}
                                        onChange={formik.handleChange(column.name)}
                                        type={column.type}
                                        disabled={(modal === CONSTANTS.VIEW || column.name === 'equipment_code') ? true : false}
                                        error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                        helperText={formik.touched[column.name] && formik.errors[column.name]}

                                    >
                                        {
                                            column.name === 'equipment_category' ? categories?.map((item, index) => (<MenuItem key={index} value={item._id}>{item.category_name}</MenuItem>)): (
                                                column?.options?.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                                            )
                                        }
                                        {
                                          ( column.name === 'equipment_category' && modal !== CONSTANTS.ADD ) && <MenuItem  value='deleted_category'>Deleted Category</MenuItem> 

                                        }

                                    </Select>
                                    {
                                        (formik.touched[column.name] && Boolean(formik.errors[column.name])) && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{formik.errors[column.name]}</p>
                                    }
                                </FormControl>
                            </Grid>
                        )
                    }
                  return ""
                })}



            </Grid>

            {
                (modal !== CONSTANTS.VIEW) && (<Grid
                    style={{
                        width: "100%",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >

                    <Button
                        variant="contained"
                        type='submit'
                        size="medium"
                        color="primary"
                    >
                        {modal === CONSTANTS.ADD ? 'Add ' : "Edit"}
                    </Button>

                </Grid>)
            }


        </form>
    )
}

export default ModalForm