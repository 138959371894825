import { Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, MuiThemeProvider, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import PageTitle from '../../../components/PageTitle/PageTitle'
import * as Yup from "yup";
import { useFormik } from "formik";
import MUIDataTable from 'mui-datatables';
import Visibility from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';


const COLUMNS = [
    {
        name: 'role',
        label: 'Roles',
        type: 'text'
    },
    {
        label: 'Vendor',
        name: 'vendor',
        type: 'label',

    },
    {
        label: 'Inventory',
        name: 'inventory',
        type: 'label',

    },
    {
        label: 'Equipment',
        name: 'equipment',
        type: 'label',
    },
    {
        label: 'Categories',
        name: 'categories',
        type: 'label',
    },
    {
        label: 'Assemble',
        name: 'assemble',
        type: 'label',
    },
    {
        label: 'Assemble Request',
        name: 'assemble_request',
        type: 'label',
    },

]

const TABLE_TITLE = [

    {
        name: 'role',
        label: 'Role',
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "action",
        label: "Action",
        options: {
            filter: true,
            sort: false,
        },
    },


]

const TABLE_DATA = [
    {
        id: 0,
        role: 'Supervisor',
        vendor_add: false,
        vendor_edit: false,
        vendor_view: true,
        vendor_delete: false,
        inventory_add: false,
        inventory_edit: false,
        inventory_view: true,
        inventory_delete: false,
        equipment_add: false,
        equipment_edit: false,
        equipment_view: true,
        equipment_delete: false,
        categories_add: false,
        categories_edit: false,
        categories_view: true,
        categories_delete: false,
        assemble_add: false,
        assemble_edit: false,
        assemble_view: true,
        assemble_delete: false,
        assemble_request_add: false,
        assemble_request_edit: false,
        assemble_request_view: true,
        assemble_request_delete: false,
    },
    {
        id: 1,
        role: 'Admin',
        vendor_add: true,
        vendor_edit: true,
        vendor_view: true,
        vendor_delete: true,
        inventory_add: true,
        inventory_edit: true,
        inventory_view: true,
        inventory_delete: true,
        equipment_add: true,
        equipment_edit: true,
        equipment_view: true,
        equipment_delete: true,
        categories_add: true,
        categories_edit: true,
        categories_view: true,
        categories_delete: true,
        assemble_add: true,
        assemble_edit: true,
        assemble_view: true,
        assemble_delete: true,
        assemble_request_add: true,
        assemble_request_edit: true,
        assemble_request_view: true,
        assemble_request_delete: true,
    },
    {
        id: 2,
        role: 'Manager',
        vendor_add: true,
        vendor_edit: true,
        vendor_view: true,
        vendor_delete: true,
        inventory_add: true,
        inventory_edit: true,
        inventory_view: true,
        inventory_delete: true,
        equipment_add: true,
        equipment_edit: true,
        equipment_view: false,
        equipment_delete: false,
        categories_add: false,
        categories_edit: false,
        categories_view: false,
        categories_delete: false,
        assemble_add: false,
        assemble_edit: false,
        assemble_view: false,
        assemble_delete: false,
        assemble_request_add: false,
        assemble_request_edit: false,
        assemble_request_view: false,
        assemble_request_delete: false,
    },
]



const INITIAL_FIELDS = {
    role: "",
    vendor_add: false,
    vendor_edit: false,
    vendor_view: false,
    vendor_delete: false,
    inventory_add: false,
    inventory_edit: false,
    inventory_view: false,
    inventory_delete: false,
    equipment_add: false,
    equipment_edit: false,
    equipment_view: false,
    equipment_delete: false,
    categories_add: false,
    categories_edit: false,
    categories_view: false,
    categories_delete: false,
    assemble_add: false,
    assemble_edit: false,
    assemble_view: false,
    assemble_delete: false,
    assemble_request_add: false,
    assemble_request_edit: false,
    assemble_request_view: false,
    assemble_request_delete: false,
}

const CONSTANTS = {
    VIEW: 'view',
    ADD: 'add',
    EDIT: 'edit',
}


const RoleSchema = Yup.object().shape({
    role: Yup.string()
        .min(3, "Role Name must be 3 characters at minimum")
        .required("Role Name is required"),

});

function Roles() {

    const [data, setData] = useState([...TABLE_DATA])
    const [mode, setMode] = useState(CONSTANTS.ADD)
    const [fields, setFields] = useState({ ...INITIAL_FIELDS })

    let formik = useFormik({
        initialValues: { ...fields },
        enableReinitialize: true,
        validationSchema: RoleSchema,
        onSubmit: (values) => {

            console.log(values)
            if (mode === CONSTANTS.ADD) {
                setData([...data, { ...values, id: data.length }])
            } else if (mode === CONSTANTS.EDIT) {
                data[values.id] = values
                setData([...data])
            }

            handleClear()
        },
    });

 
    const setEditMode = (index) => {
        setFields(data[index])
        setMode(CONSTANTS.EDIT)
        formik.resetForm()
    }

    const setViewMode = (index) => {
        setFields(data[index])
        setMode(CONSTANTS.VIEW)
        formik.resetForm()
    }



    const handleClear = () => {
        setFields({ ...INITIAL_FIELDS })
        setMode(CONSTANTS.ADD)
        formik.resetForm()
    }

    const getRows = () => {
        return data.map((row, i) => {
            return {
                ...row,
                action: (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => {setViewMode(i) }}
                            color="primary"
                            style={{ marginRight: "10px" }}
                        >
                            <Visibility />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {setEditMode(i) }}
                            color="secondary"
                        >
                            <Edit />

                        </Button>
                    </>
                ),
            };
        });
    };

    return (
        <>
            <PageTitle title="Roles And Permissions" />

            <Grid container>
                <Grid item xs={6} style={{ padding: '20px 10px' }}>

                     {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
                        <MUIDataTable
                            title="Roles"
                            data={getRows()}
                            columns={[
                                ...TABLE_TITLE,
                            ]}
                        />
                    </MuiThemeProvider>

                </Grid>
                <Grid item xs={6} style={{ padding: '20px 10px' }}>
                    <Card>
                        <CardHeader title="Roles And Permissions" />
                        <Divider />
                        <CardContent>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={3}>

                                    {COLUMNS.map((column) => {
                                        if (column.type === "text") {
                                            return (
                                                <Grid item xs={12} >
                                                    <TextField
                                                        fullWidth
                                                        name={column.name}
                                                        label={column.label}
                                                        variant="outlined"
                                                        value={formik.values[column.name]}
                                                        onChange={(mode !==CONSTANTS.VIEW) && formik.handleChange}
                                                        error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                                        helperText={formik.touched[column.name] && formik.errors[column.name]}
                                                    />
                                                </Grid>
                                            )

                                        }

                                        if (column.type === "label") {
                                            return (

                                                <Grid item xs={12} container spacing={1} alignItems='center' >
                                                    <Grid item xs={3}>
                                                        <Typography variant='h5'>{column.label} </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} >
                                                        <FormControlLabel
                                                            control={<Checkbox checked={formik.values[`${column.name}_view`]} />}
                                                            label="View"
                                                            name={`${column.name}_view`}
                                                            onChange={(mode !==CONSTANTS.VIEW) && formik.handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} >
                                                        <FormControlLabel
                                                            control={<Checkbox checked={formik.values[`${column.name}_add`]} />}
                                                            label="Add"
                                                            name={`${column.name}_add`}
                                                            onChange={(mode !==CONSTANTS.VIEW) && formik.handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} >
                                                        <FormControlLabel
                                                            control={<Checkbox checked={formik.values[`${column.name}_edit`]} />}
                                                            label="Edit"
                                                            name={`${column.name}_edit`}
                                                            onChange={(mode !==CONSTANTS.VIEW) && formik.handleChange}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={2} >
                                                        <FormControlLabel
                                                            control={<Checkbox checked={formik.values[`${column.name}_delete`]} />}
                                                            label="Delete"
                                                            name={`${column.name}_delete`}
                                                            onChange={(mode !==CONSTANTS.VIEW) && formik.handleChange}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )

                                        }
                                        return "";
                                    })}
                                </Grid>

                                <Grid container spacing={3}>
                                    {
                                        (mode !== CONSTANTS.VIEW) && ( <Grid item>
                                            <Button variant="contained"
                                                size="medium"
                                                type='submit'
                                                color="secondary">
                                             {mode}
    
                                            </Button>
                                        </Grid>)
                                    }
                                   
                                    <Grid item>
                                        <Button variant="contained"
                                            size="medium"
                                            onClick={handleClear}
                                            color="primary">
                                            Clear

                                        </Button>
                                    </Grid>
                                </Grid>

                            </form>


                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Roles