import React, { useState, useEffect } from "react";
import { Button, Modal, IconButton, Grid } from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import ModalForm from "./components/ModalForm";
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import API from "../../../services/API";
import PublishIcon from "@material-ui/icons/Publish";
import ImportModal from "./components/ImportModal";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 340,
    maxWidth: 600,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    maxHeight: "90vh",
    overflowY: "scroll",
  },
  importModal: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const columns = [
  {
    name: "equipment_name",
    label: "Equipment Name",
    type: "text",
  },
  {
    name: "equipment_code",
    label: "Equipment Code",
    type: "text",
  },
  {
    name: "equipment_sku",
    label: "SKU",
    type: "text",
  },
  // {
  //   name: "equipment_qty",
  //   label: "Quantitty",
  //   type: "number",
  // },
  {
    name: "equipment_unit",
    label: "Unit",
    type: "select",
    options: ["kg", "piece", "litre", "ml", "other"],
  },
  // {
  //   name: "equipment_cost",
  //   label: "Cost",
  //   type: "currency",
  //   currencySetting: {
  //     currencyCode: "INR",
  //     minimumFractionDigits: 0,
  //     maximumFractionDigits: 2,
  //   },
  // },
  {
    name: "equipment_category_name",
    label: "Categories",
    type: "select",
  },
];

const CONSTANTS = {
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
};

const INITIAL_FIELDS = {
  equipment_name: "",
  equipment_sku: "",
  equipment_unit: "",
  // equipment_cost: "",
  // equipment_qty: "",
  equipment_category: "",
};

function Equipment() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [importModalOpen, setImportModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [modal, setModal] = useState(CONSTANTS.ADD);
  const [fields, setFields] = useState({
    equipment_name: "",
    equipment_sku: "",
    equipment_unit: "",
    // equipment_cost: "",
    // equipment_qty: "",
    equipment_category: "",
  });

  const handleOpenImportModal = () => {
    setImportModalOpen(true);
  };

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      handleDelete(e);
    },
    customToolbar: () => {
      return (
        <IconButton onClick={handleOpenImportModal}>
          <PublishIcon />
        </IconButton>
      );
    },
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseImportModal = () => {
    setImportModalOpen(false);
  };

  const handleOpen = () => {
    setFields({ ...INITIAL_FIELDS });
    setModal(CONSTANTS.ADD);
    setOpen(true);
  };

  const getRow = (data) => {
    return [data].map((item) => ({
      equipment_name: item.equipment_name,
      equipment_code: item.equipment_code,
      equipment_sku: item.equipment_sku,
      // equipment_qty: item.equipment_qty,
      equipment_unit: item.equipment_unit,
      // equipment_cost: item.equipment_cost,
      equipment_category: item.equipment_category || "deleted_category",
    }));
  };

  const handleView = (index) => {
    let fields = getRow(data[index]);
    console.log(fields);
    setFields(fields[0]);
    setModal(CONSTANTS.VIEW);
    setOpen(true);
  };

  const handleEdit = (index) => {
    setFields(data[index]);
    setModal(CONSTANTS.EDIT);
    setOpen(true);
  };

  const featchEquipment = () => {
    API.get("/equipment/all")
      .then((response) => {
        console.log(response.data);
        let data = response.data.equipment.map((item) => ({
          ...item,
          equipment_category_name: item.equipment_category?.category_name,
          equipment_category: item.equipment_category?._id,
        }));
        setData([...data]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  const handleSave = (values) => {
    console.log(values);
    API.post("/equipment/add", values)
      .then((response) => {
        setOpen(false);
        if (response.data.success) {
          toast.success("Equipment Added Successfully");
          let result = response.data.equipment.map((item) => ({
            ...item,
            equipment_category_name: item.equipment_category?.category_name,
            equipment_category: item.equipment_category?._id,
          }));
          setData([...result, ...data]);
        } else {
          toast.warning(response.data.message);
        }
        setFields({ ...INITIAL_FIELDS });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleUpdate = (values) => {
    setOpen(false);
    API.put(`/equipment/${values._id}`, values)
      .then((response) => {
        if (response.data.success) {
          toast.success("Equipment Updated Successfully");
          let result = [response.data.equipment].map((item) => ({
            ...item,
            equipment_category_name: item.equipment_category?.category_name,
            equipment_category: item.equipment_category?._id,
          }));
          const updateindex = data.findIndex(
            (item) => item._id === response.data.equipment._id,
          );
          data[updateindex] = result[0];
          setData([...data]);
        } else {
          toast.warning(response.data.message);
        }

        setFields({ ...INITIAL_FIELDS });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const importExcel = (file) => {
    setImportModalOpen(false);
    console.log(file);
    let formData = new FormData();
    formData.append("excel", file);
    API.post("/equipment/import", formData)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          toast.success("Equipment Imported Successfully");
          featchEquipment();
        } else {
          console.log(response.data);
          toast.warning("Equipments validation failed");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleDelete = (e) => {
    const array = e.data.map(item => data[item.index]._id);
    API.post(`/equipment/deletemany`, { equipmentids: array })
      .then((response) => {
        if (response.data.success) {
          toast.dark("Equipment Deleted Successfully");
        } else {
          toast.warning(response.data.message);
        }
        featchEquipment();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const getRows = () => {
    return data.map((row, i) => {
      return {
        ...row,

        action: (
          <>
            <Button
              variant="contained"
              onClick={() => {
                handleView(i);
              }}
              color="primary"
              style={{ marginRight: "10px" }}
            >
              <VisibilityIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleEdit(i);
              }}
                          style={{ margin: "5px",backgroundColor: "#ff9001" }}

            >
              <EditIcon />
            </Button>
          </>
        ),
        equipment_category_name:
          row.equipment_category_name || "Deleted Category",
      };
    });
  };

  useEffect(() => {
    featchEquipment();
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Equipment</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>
          <ModalForm
            columns={columns}
            CONSTANTS={CONSTANTS}
            modal={modal}
            fields={fields}
            handleSave={handleSave}
            handleUpdate={handleUpdate}
          />
        </div>
      </Modal>
      <Modal
        open={importModalOpen}
        // onClose={handleCloseImportModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.importModal}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Import</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleCloseImportModal} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>
          <ImportModal importExcel={importExcel} />
        </div>
      </Modal>

      <PageTitle
        title="Equipment"
        button={
          <Button
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Add Equipment
          </Button>
        }
      />

       {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
        <MUIDataTable
          title={"Equipment List"}
          data={getRows()}
          columns={[
            ...columns,
            {
              name: "action",
              label: "Action",
              options: {
                filter: true,
                sort: false,
              },
            },
          ]}
          options={options}
        />
      </MuiThemeProvider>
    </>
  );
}

export default Equipment;
