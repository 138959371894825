import React, { useState, useEffect } from "react";
import { Button, Modal, IconButton, Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import ModalForm from "./components/ModalForm";
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import API from "../../services/API";
import PublishIcon from "@material-ui/icons/Publish";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 340,
    maxWidth: 600,
    backgroundColor: theme.palette.background,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    maxHeight: "90vh",
    overflowY: "scroll",
  },
  importModal: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const columns = [
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "timing",
    label: "Timing",
    type: "date",
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "number",
  },
  {
    name: "city",
    label: "City",
    type: "text",
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    options: ["pending", "done"],
  },
];

const CONSTANTS = {
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
};

const INITIAL_FIELDS = {
  name: "",
  timing: "",
  notes: "",
  phone: "",
  city: "",
  status: "",
};

function Ticket() {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [importModalOpen, setImportModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [modal, setModal] = useState(CONSTANTS.ADD);
  const [fields, setFields] = useState({
    name: "",
    timing: "",
    notes: "",
    phone: "",
    city: "",
    status: "",
  });

  const handleOpenImportModal = () => {
    setImportModalOpen(true);
  };

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      handleDelete(e);
    },
    customToolbar: () => {
      return (
        <IconButton onClick={handleOpenImportModal}>
          <PublishIcon />
        </IconButton>
      );
    },
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseImportModal = () => {
    setImportModalOpen(false);
  };

  // const getRow = (data) => {
  //   return [data].map((item) => ({
  //     customer_name: item.customer_name,
  //     purchased_date: item.purchased_date,
  //     complaint: item.complaint,
  //     complaint_date: item.complaint_date,
  //     status: item.status,
  //   }));
  // };

  // const handleView = (index) => {
  //   let fields = getRow(data[index]);
  //   setFields(fields[0]);
  //   setModal(CONSTANTS.VIEW);
  //   setOpen(true);
  // };

  const handleEdit = (index) => {
    history.push({ pathname: "/app/demo-edit/"+data[index]._id});
  };

  const redirect = (url) => {
    history.push({ pathname: url});
  };

  const fetchTickets = () => {
    API.get("/demo/all")
      .then((response) => {
        setData([...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  const handleSave = (values) => {
    API.post("/demo/add", values)
      .then((response) => {
        setOpen(false);
        if (response.data.success) {
          toast.success("Added Successfully");
          fetchTickets();
        } else {
          toast.warning(response.data.message);
        }
        setFields({ ...INITIAL_FIELDS });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleUpdate = (values) => {
    setOpen(false);
    API.put(`/demo/${values._id}`, values)
      .then((response) => {
        if (response.data.success) {
          toast.success("Updated Successfully");
          fetchTickets();
          const updateindex = data.findIndex(
            (item) => item._id === response.data.ticket._id,
          );
          data[updateindex] = response.data.ticket[0];
          setData([...data]);
        } else {
          toast.warning(response.data.message);
        }

        setFields({ ...INITIAL_FIELDS });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  const handleDelete = (e) => {
    const array = e.data.map(item => data[item.index]._id);
    API.post(`/demo/delete-many`, { demoIds: array })
      .then((response) => {
        if (response.data.success) {
          toast.dark("Deleted Successfully");
        } else {
          toast.warning(response.data.message);
        }
        fetchTickets();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const getRows = () => {
    return data.map((row, i) => {
      return {
        ...row,

        action: (
          <>
            {/* <Button
              variant="contained"
              onClick={() => {
                handleView(i);
              }}
              color="primary"
              style={{ marginRight: "10px" }}
            >
              <VisibilityIcon />
            </Button> */}
            <Button
              variant="contained"
              onClick={() => {
                handleEdit(i);
              }}
              color="secondary"
              style={{ margin: "5px", backgroundColor: "#FF9001" }}
            >
              <EditIcon />
            </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  redirect('/pub/view-demo/' + row.phone);
                }}
                style={{ margin: "5px", backgroundColor: "#c2ff01" }}
              >
                <PublicIcon />
              </Button>
          </>
        ),
      };
    });
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Demos</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <ModalForm
            columns={columns}
            CONSTANTS={CONSTANTS}
            modal={modal}
            fields={fields}
            handleSave={handleSave}
            handleUpdate={handleUpdate}
            handleClose={handleClose}
          />
        </div>
      </Modal>
      <Modal
        open={importModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.importModal}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Import</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleCloseImportModal} variant="text">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Modal>

      <PageTitle
        title="Demos"
        button={
          <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              history.push({ pathname: "/app/demo-add" });
            }}
          >
            Add Demo
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              redirect('/pub/add-demo');
            }}
            color="secondary"
            style={{ margin: "5px", backgroundColor: "#c2ff01" }}
          >
            <PublicIcon />
          </Button>
        </div>
        }
      />

      {/* <MuiThemeProvider theme={getMuiTheme()}> */}
      <MuiThemeProvider>
        <MUIDataTable
          title={"Demo List"}
          data={getRows()}
          columns={[
            ...columns,
            {
              name: "action",
              label: "Action",
              options: {
                filter: true,
                sort: false,
              },
            },
          ]}
          options={options}
        />
      </MuiThemeProvider>
    </>
  );
}

export default Ticket;
