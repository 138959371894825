import React from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const CategoriesSchema = Yup.object().shape({
    category_name: Yup.string()
        .min(3, "Categories Name must be 3 characters at minimum")
        .required("Categories Name is required"),

});

const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: theme.palette.background.light,
      padding: theme.spacing(2, 4, 3),
    },
  }));

function Form(props) {
    const { fields, COLUMNS, CONSTANTS, form, handleClearForm, handleAdd, handleEdit } = props
    const classes = useStyles();


    let formik = useFormik({
        initialValues: fields,
        enableReinitialize: true,
        validationSchema: CategoriesSchema,
        onSubmit: (values) => {
            if (form === CONSTANTS.ADD) {

                console.log(values)
                handleAdd(values)
            } else if (form === CONSTANTS.EDIT) {
                handleEdit({...fields,...values})
            }

        },
    });



    return (

        <form onSubmit={formik.handleSubmit} className={classes.paper}>

            <Grid container spacing={3}>
                {COLUMNS.map((column, index) => {
                    return (
                        <Grid item xs={12} key={index} >
                            <TextField
                                fullWidth
                                name={column.name}
                                label={column.label}
                                variant="outlined"
                                type={column.type}
                                value={formik.values[column.name]}
                                onChange={formik.handleChange}
                                disabled={(column.name !== "category_code" ? false : true) || (form === CONSTANTS.VIEW ? true : false)}
                                error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                helperText={formik.touched[column.name] && formik.errors[column.name]}
                            />
                        </Grid>
                    )
                }
                )}

                <Grid item xs={12}>

                    {
                        form !== CONSTANTS.VIEW && (<Button
                            variant="contained"
                            onClick={() => { }}
                            color="primary"
                            style={{ marginRight: "10px" }}
                            type="submit"
                        >
                            {form === CONSTANTS.ADD ? "Add" : "Edit"}
                        </Button>)
                    }

                    <Button
                        variant="contained"
                        onClick={handleClearForm}
                        style={{ marginLeft: "10px", backgroundColor: "#fe6854" }}
                    >
                        clear
                    </Button>
                </Grid>
            </Grid>

        </form>
    )
}

export default Form