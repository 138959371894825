import { Button, Card, CardContent, CardHeader, Divider, FormControl, Grid, InputLabel, MenuItem, MuiThemeProvider, Select, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import PageTitle from '../../../components/PageTitle/PageTitle'
import * as Yup from "yup";
import { useFormik } from "formik";
import MUIDataTable from 'mui-datatables';
import Visibility from '@material-ui/icons/Visibility';
import Edit from '@material-ui/icons/Edit';


const COLUMNS = [
  {
    name: 'user_name',
    label: 'User Name',
    type: 'text'
  },
  {
    name: 'employee_id',
    label: 'Employee Id',
    type: 'text'
  },
  {
    name: 'role',
    label: 'Roles',
    type: 'select',
    options: ["Manager", 'Admin', 'Supervisor']
  }
]

const TABLE_TITLE = [

  {
    name: 'user_name',
    label: 'User Name',
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: 'employee_id',
    label: 'Employee Id',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'role',
    label: 'Roles',
    options: {
      filter: true,
      sort: false,
    },
  },

]

const TABLE_DATA = [
  {
    user_name: 'hirak',
    employee_id: '001',
    role: 'Admin'
  },
  {
    user_name: 'Dhruvil',
    employee_id: '002',
    role: 'Manager'
  },
  {
    user_name: 'Trusha',
    employee_id: '003',
    role: 'Manager'
  },
]

const INITIAL_FIELDS = {
  user_name: '',
  employee_id: '',
  role: ''
}




const UserSchema = Yup.object().shape({
  user_name: Yup.string()
    .min(3, "User Name must be 3 characters at minimum")
    .required("User Name is required"),

  employee_id: Yup.string()
    .min(3, "Employee Id must be 3 characters at minimum")
    .required("Employee Id is required"),
  role: Yup.string()
    .required("Roles is required")


});

const CONSTANTS = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
}

function User() {

  const [data, setData] = useState([...TABLE_DATA])
  const [mode, setMode] = useState(CONSTANTS.ADD)
  const [fields, setFields] = useState({ ...INITIAL_FIELDS })

  let formik = useFormik({
    initialValues: { ...fields },
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: (values) => {


      console.log(values)
      if (mode === CONSTANTS.ADD) {
        setData([...data, { ...values, id: data.length }])
      } else if (mode === CONSTANTS.EDIT) {
        data[values.id] = values
        setData([...data])
      }

      handleClear()

    },
  });

  const setEditMode = (index) => {
    setFields(data[index])
    setMode(CONSTANTS.EDIT)
    formik.resetForm()
  }

  const setViewMode = (index) => {
    setFields(data[index])
    setMode(CONSTANTS.VIEW)
    formik.resetForm()
  }



  const handleClear = () => {
    setFields({ ...INITIAL_FIELDS })
    setMode(CONSTANTS.ADD)
    formik.resetForm()
  }


  const getRows = () => {
    return data.map((row, i) => {
      return {
        ...row,
        action: (
          <>
            <Button
              variant="contained"
              onClick={() => { setViewMode(i) }}
              color="primary"
              style={{ marginRight: "10px" }}
            >
              <Visibility />
            </Button>
            <Button
              variant="contained"
              onClick={() => { setEditMode(i) }}
              color="secondary"
            >
              <Edit />

            </Button>
          </>
        ),
      };
    });
  };

  return (
    <>
      <PageTitle title="Users"  />

      <Grid container>
        <Grid item xs={8} style={{ padding: '20px 10px' }}>

           {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
            <MUIDataTable
              title="Equipment List"
              data={getRows()}
              columns={[
                ...TABLE_TITLE,
                {
                  name: "action",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                  },
                },
              ]}
            />
          </MuiThemeProvider>

        </Grid>
        <Grid item xs={4} style={{ padding: '20px 10px' }}>
          <Card>
            <CardHeader title="Users" />
            <Divider />
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>

                  {COLUMNS.map((column) => {
                    if (column.type === "text") {
                      return (
                        <Grid item xs={12} >
                          <TextField
                            fullWidth
                            name={column.name}
                            label={column.label}
                            variant="outlined"
                            value={formik.values[column.name]}
                            onChange={(mode !==CONSTANTS.VIEW) && formik.handleChange}
                            error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                            helperText={formik.touched[column.name] && formik.errors[column.name]}
                          />
                        </Grid>
                      )

                    }
                    if (column.type === "select") {
                      return (
                        <Grid item xs={12} >
                          <FormControl fullWidth variant='outlined'>
                            <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>


                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={column.label}
                              value={formik.values[column.name]}
                              onChange={(mode !==CONSTANTS.VIEW) && formik.handleChange(column.name)}
                   
                              type={column.type}
                              error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                              helperText={formik.touched[column.name] && formik.errors[column.name]}


                            >
                              {
                                column.options.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                              }

                            </Select>
                          </FormControl>
                        </Grid>
                      )

                    }
                    return "";
                  })}
                </Grid>
                <Grid container spacing={3}>
                  {
                    (mode !== CONSTANTS.VIEW) && (
                      <Grid item>
                        <Button variant="contained"
                          size="medium"
                          type='submit'
                          color="secondary">
                         {mode}

                        </Button>
                      </Grid>)
                  }


                  <Grid item>
                    <Button variant="contained"
                      size="medium"
                      onClick={handleClear}
                      color="primary">
                      Clear

                    </Button>
                  </Grid>
                </Grid>

              </form>


            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default User