import axios from "axios";


export const BASE_URL = "https://projects.xcitech.in/fifth-ventricle";
//  export const BASE_URL = "http://localhost:5959";
//  export const BASE_URL = "http://192.168.29.81:4545";



const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use((request) => {


  if (request.url.endsWith("login")) return request;


  const token = localStorage.getItem("jwtToken");

  request.headers["token"] = token;

  request.headers["Content-Type"] = "application/json";
  return request;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("401 error", error);

    return Promise.reject(error);
  }
);

export default API;
