import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from "formik";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Stepper, Step, StepLabel, Typography, Box } from '@material-ui/core';
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Select Product', 'Fill the details'];
}


const ordersColumns = [
    {
        name: 'order_name',
        label: 'order name',
        type: 'select',
        xs: 6

    },
    // {
    //     name: 'qty',
    //     label: 'Quantity',
    //     type: 'number',
    //     xs: 2
    // },
    // {
    //     name: 'price',
    //     label: 'Price',
    //     type: 'number',
    //     xs: 3
    // },
]

const initialOrders = {
    id: uuidv4(),
    order_name: '',
    order_id: '',
    chestoColor: '',
    payableAmount: '',

    selling_price: '',
};

const shipRocktInfoColumns = [
    {
        name: 'shipping_customer_name',
        label: 'Shipping Customer Name',
        type: 'text',
        sm: 6
    },
    {
        name: 'billing_country',
        label: 'Billing Country',
        type: 'text',
        sm: 6
    },
    {
        name: 'billing_last_name',
        label: 'Billing last name',
        type: 'text',
        sm: 6
    },
    {
        name: 'billing_state',
        label: 'Billing state',
        type: 'text',
        sm: 6
    },
    {
        name: 'shipping_address',
        label: 'Shipping Address',
        type: 'text',
        sm: 6
    },
    {
        name: 'shipping_city',
        label: 'Shipping City',
        type: 'text',
        sm: 6
    },
    {
        name: 'shipping_country',
        label: 'Shipping Country',
        type: 'text',
        sm: 6
    },
    {
        name: 'shipping_state',
        label: 'Shipping State',
        type: 'text',
        sm: 6
    },
    {
        name: 'shipping_phone',
        label: 'Shipping Phone',
        type: 'number',
        sm: 6
    },
    {
        name: 'coustomer_alternate_phone',
        label: 'Coustomer Alternate Phone',
        type: 'number',
        sm: 6
    },
    {
        name: 'shipping_pincode',
        label: 'Shipping Pincode',
        type: 'number',
        sm: 6
    },
    {
        name: 'breadth',
        label: 'Breadth',
        type: 'number',
        sm: 6
    },

    {
        name: 'weight',
        label: 'Box Weight',
        type: 'number',
        sm: 6
    },
    {
        name: 'length',
        label: 'Box length',
        type: 'number',
        sm: 6
    },
    {
        name: 'height',
        label: 'Box Height',
        type: 'number',
        sm: 6
    },
    {
        name: 'box_width',
        label: 'Box Width',
        type: 'number',
        sm: 6
    },
];
const shippingSchema = Yup.object().shape({
    billing_customer_name: Yup.string()
        .min(3, "Coustomer Name must be 3 characters at minimum")
        .required("Coustomer Name is required"),

    billing_phone: Yup.number()
        .min(10, "Minmum 10 number are required.")
        .required("Coustomer Phone 10 number is required"),

    coustomer_alternate_phone: Yup.string()
        .min(10, "Minmum 10 number are required.")
        .required("Coustomer Alternate Phone is required"),
    email: Yup.string()
        .required("Email is required"),

    billing_address: Yup.string()
        .required("Address line 1 is required"),
    address_line_2: Yup.string()
        .required("Address line 2 is required"),

    city: Yup.string()
        .required("City is required"),

    billing_pincode: Yup.string()
        .min(6, "Only 6 number are required.")
        .required("Pin Code is required At least 6 "),
    //shiprocket _mandonrtry fileds
    billing_country: Yup.string()
        .required("billing country is required"),
    billing_last_name: Yup.string()
        .required("billing last name is required"),
    billing_state: Yup.string()
        .required("State is required"),
    height: Yup.number()
        .required("Box Height id is required"),
    box_width: Yup.number()
        .required("Box Width id is required"),
    breadth: Yup.number()
        .required("Breadth  id is required"),
    length: Yup.number()
        .required("Length  id is required"),
    shipping_address: Yup.string()
        .required("shipping address is required"),
    shipping_city: Yup.string()
        .required("shipping city is required"),

    shipping_state: Yup.string()
        .required("shipping state is required"),
    weight: Yup.number()
        .required("Box Weight is required"),

    shipping_country: Yup.string()
        .required("shipping country is required"),
    shipping_customer_name: Yup.string()
        .required("shipping customer name is required"),
    shipping_phone: Yup.number()
        .required("shippin Phone 10 number is required"),
    shipping_pincode: Yup.string()
        .required("shipping pin code is required At least 6 "),
});
const initialShipRocket = {
    billing_customer_name: '',
    shipping_customer_name: '',

    billing_phone: '',
    shipping_phone: '',
    coustomer_alternate_phone: '',
    email: '',
    billing_address: '',
    address_line_2: '',
    city: '',
    billing_state: 'Gujarat',
    billing_pincode: '',
    shipping_pincode: '',
    weight: 10,
    height: 10,
    box_width: 10,
    billing_country: 'India',
    shipping_country: 'India',
    billing_last_name: '',
    shipping_state: 'Gujarat',
    shipping_city: '',
    shipping_address: '',
    breadth: 10,
    length: 10,
    order_date: '',
    shipping_is_billing: false,
    payment_method: 'razorpay',

}


export default function OrderViaShiprocket({ orders, handleMakeAnOrder }) {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    let shippingForm = useFormik({
        initialValues: initialShipRocket,
        validationSchema: shippingSchema,
        enableReinitialize: true,
        onSubmit: (values) => {

            let getOrderDetials = finOrder(ordersForm.values.order_name);


            let data = {
                ...values,
                order_items: [
                    {

                        // price: getOrderDetials.payableAmount,
                        selling_price: getOrderDetials.payableAmount,
                        quantity: getOrderDetials.quantity,
                        units: getOrderDetials.quantity,
                        name: getOrderDetials.chestoColor[0],
                        sku: getOrderDetials.sku || "vertical123",

                    },
                ]
            }
            handleMakeAnOrder(data, true)

        }
    });

    let ordersForm = useFormik({
        // validationSchema: ordersSchema,
        initialValues: initialOrders,
        orders: [],
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
        }
    });

    const handleNext = () => {
        let getOrderDetials = finOrder(ordersForm.values.order_name);

        if (getOrderDetials) {
            shippingForm.values["address_line_2"] = getOrderDetials.data[0].shippingData.address2;
            shippingForm.values["sub_total"] = getOrderDetials.payableAmount;
            shippingForm.values["order_date"] = new Date();
            shippingForm.values["order_id"] = getOrderDetials._id;
            shippingForm.values["shipping_phone"] = Number(getOrderDetials.data[0].shippingData.phone)
            shippingForm.values["coustomer_alternate_phone"] = Number(getOrderDetials.data[0].shippingData.phone)
            shippingForm.values["shipping_address"] = getOrderDetials.data[0].shippingData.address1
            shippingForm.values["shipping_state"] = getOrderDetials.data[0].shippingData.state && getOrderDetials.data[0].shippingData.state.label
            shippingForm.values["shipping_pincode"] = Number(getOrderDetials.data[0].shippingData.pincode)
            shippingForm.values["shipping_city"] = getOrderDetials.data[0].shippingData.city
            shippingForm.values["shipping_customer_name"] = getOrderDetials.data[0].shippingData.fname
            shippingForm.values["billing_address"] = getOrderDetials.data[0].billingData.address1
            shippingForm.values["billing_customer_name"] = getOrderDetials.data[0].billingData.fname
            shippingForm.values["billing_last_name"] = getOrderDetials.data[0].billingData.lname
            shippingForm.values["billing_phone"] = Number(getOrderDetials.data[0].billingData.phone)
            shippingForm.values["billing_pincode"] = Number(getOrderDetials.data[0].billingData.pincode)
            shippingForm.values["billing_state"] = getOrderDetials.data[0].billingData.state && getOrderDetials.data[0].billingData.state.label
            shippingForm.values["city"] = getOrderDetials.data[0].billingData.city
            shippingForm.values["email"] = getOrderDetials.data[0].billingData.email
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    let finOrder = (order_id) => {
        return orders.find((order) => order._id === order_id)
    }
    return (
        <Box sx={{ width: '100%' }}>
            <>
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Grid
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography className={classes.instructions}>All steps completed</Typography>
                                    <Button onClick={handleReset}>Reset</Button>
                                </Grid>
                            </div>
                        ) : (

                            <>
                                {
                                    activeStep === 0 &&

                                    <form onSubmit={ordersForm.handleSubmit}>{
                                        ordersColumns.map((column, index) => {
                                            return (
                                                <>
                                                    <Grid item xs={column.xs} key={index}>
                                                        <FormControl fullWidth variant='outlined'>
                                                            <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label={column.label}
                                                                value={ordersForm.values[column.name]}
                                                                onChange={ordersForm.handleChange(column.name)}
                                                                type={column.type}

                                                                error={ordersForm.touched[column.name] && Boolean(ordersForm.errors[column.name])}
                                                                helperText={ordersForm.touched[column.name] && ordersForm.errors[column.name]}
                                                            >
                                                                {
                                                                    column.name === 'order_name' ? orders?.map((order, index) => !order.ship_rocket && <MenuItem key={index} value={order._id}>{order.chestoColor}</MenuItem>) : null
                                                                }

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            )
                                        })
                                    }

                                    </form>
                                }

                                {
                                    activeStep === 1 &&

                                    <>

                                        <Grid>
                                            <Grid item xs={12}>
                                                <form onSubmit={shippingForm.handleSubmit}>
                                                    <Grid container spacing={2}>
                                                        {shipRocktInfoColumns.map((column) => {
                                                            if (column.type === 'text' || column.type === 'number' || column.type === 'textarea') {
                                                                return (
                                                                    <Grid item xs={12} sm={column.sm}>
                                                                        <TextField
                                                                            fullWidth
                                                                            name={column.name}
                                                                            label={column.label}
                                                                            variant="outlined"
                                                                            type={column.type}
                                                                            value={shippingForm.values[column.name]}

                                                                            onChange={shippingForm.handleChange}
                                                                            multiline={column.type === 'textarea' ? true : false}
                                                                            minRows={column.type === 'textarea' ? 3 : 1}
                                                                            error={shippingForm.touched[column.name] && Boolean(shippingForm.errors[column.name])}
                                                                            helperText={shippingForm.touched[column.name] && shippingForm.errors[column.name]}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            }
                                                            if (column.type === 'select') {
                                                                return (
                                                                    <Grid item xs={12} sm={column.sm}>
                                                                        <FormControl fullWidth variant='outlined'>
                                                                            <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                label={column.label}
                                                                                value={shippingForm.values[column.name]}
                                                                                onChange={shippingForm.handleChange(column.name)}
                                                                                type={column.type}

                                                                                error={shippingForm.touched[column.name] && Boolean(shippingForm.errors[column.name])}
                                                                                helperText={shippingForm.touched[column.name] && shippingForm.errors[column.name]}
                                                                            >
                                                                                {

                                                                                    column.options.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                                                                                }

                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                )
                                                            }
                                                            return "";
                                                        })}
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            width: "100%",
                                                            marginTop: "10px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            type='submit'
                                                            size="medium"
                                                            color="secondary"
                                                        >
                                                            Make An Order
                                                        </Button>
                                                    </Grid>
                                                </form>
                                            </Grid>

                                        </Grid>
                                    </>}

                                <Grid
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >

                                    <div className='text-center'>
                                        <Button
                                            variant="contained"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={activeStep === 1}
                                            onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </div>
                                </Grid>
                            </>
                        )}
                    </div>
                </div>



            </>
        </Box>
    )
}
