import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const columns = [
  {
    name: "customer_name",
    label: "Customer Name",
    type: "text",
  },
  {
    name: "phone",
    label: "Customer Phone",
    type: "number",
  },
  {
    name: "purchased_date",
    label: "Purchased Date",
    type: "date",
  },
  {
    name: "complaint_date",
    label: "Complaint Date",
    type: "date",
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    options: ["open", "closed"],
    default: "open",
  },
  {
    name: "resolution",
    label: "Resolution",
    type: "select",
    options: ["pending", "solved", "replacement", "return"],
    default: "pending",
  },
  {
    name: "complaint",
    label: "Complaint",
    type: "text",
  },
  {
    name: "notes",
    label: "Notes",
    type: "text",
  },
];

const EquipmentSchema = Yup.object().shape({
  customer_name: Yup.string().required("Customer Name is required"),
  phone: Yup.number().required("Customer Phone is required"),
  complaint: Yup.string().required("Customer Name is required"),
});

function ModalForm(props) {
  const { CONSTANTS, modal, fields, handleUpdate, handleSave } = props;

  const [dates, setDates] = useState({
    purchased_date: new Date().toDateString(),
    complaint_date: new Date().toDateString(),
  });

  let formik = useFormik({
    initialValues: fields,
    validationSchema: EquipmentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit({ ...values, ...dates });
    },
  });

  const dateHandler = (value, name) => {
    setDates({ ...dates, [name]: value });
  };

  const handleSubmit = (values) => {
    if (modal === CONSTANTS.ADD) {
      values.notes = [{ note: values.note ?? "" }];
      handleSave({ ...values });
    } else if (modal === CONSTANTS.EDIT) {
      handleUpdate({ ...values });
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {columns.map((column, i) => {
            if (
              (column.type === "text" || column.type === "number") &&
              column.name !== "complaint" &&
              column.name !== "notes"
            ) {
              return (
                <Grid item xs={12} sm={6} key={i}>
                  <TextField
                    fullWidth
                    name={column.name}
                    label={column.label}
                    variant="outlined"
                    type={column.type}
                    value={formik.values[column.name]}
                    disabled={modal === CONSTANTS.VIEW}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[column.name] &&
                      Boolean(formik.errors[column.name])
                    }
                    helperText={
                      formik.touched[column.name] && formik.errors[column.name]
                    }
                  />
                </Grid>
              );
            }
            if (column.type === "date") {
              return (
                <Grid item xs={12} sm={6} key={i}>
                  <FormControl fullWidth variant="outlined">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <FormControl fullWidth>
                        <DatePicker
                          value={dates[column.name]}
                          onChange={(v) => dateHandler(v, column.name)}
                          variant="outlined"
                          label={column.label}
                          disabled={
                            modal === CONSTANTS.VIEW ||
                            column.name === "equipment_code"
                              ? true
                              : false
                          }
                          fullWidth
                          autoOk
                        />
                      </FormControl>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              );
            }
            if (column.type === "select" && modal === CONSTANTS.EDIT) {
              return (
                <Grid item xs={6} sm={6} key={i}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      {column.label}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={column.label}
                      value={formik.values[column.name]}
                      onChange={formik.handleChange(column.name)}
                      type={column.type}
                      defaultValue={column.default}
                      disabled={
                        modal === CONSTANTS.VIEW ||
                        column.name === "equipment_code"
                          ? true
                          : false
                      }
                      error={
                        formik.touched[column.name] &&
                        Boolean(formik.errors[column.name])
                      }
                      helperText={
                        formik.touched[column.name] &&
                        formik.errors[column.name]
                      }
                    >
                      {column?.options?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[column.name] &&
                      Boolean(formik.errors[column.name]) && (
                        <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                          {formik.errors[column.name]}
                        </p>
                      )}
                  </FormControl>
                </Grid>
              );
            }
            if (
              column.name === "complaint" ||
              column.name === "note" ||
              column.name === "notes"
            ) {
              return (
                <Grid item xs={12} sm={12} key={i}>
                  <TextField
                    fullWidth
                    name={column.name}
                    label={column.label}
                    variant="outlined"
                    type={column.type}
                    value={formik.values[column.name]}
                    disabled={modal === CONSTANTS.VIEW}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[column.name] &&
                      Boolean(formik.errors[column.name])
                    }
                    helperText={
                      formik.touched[column.name] && formik.errors[column.name]
                    }
                    multiline
                    minRows={4}
                  />
                </Grid>
              );
            }
            return ""
          })}
        </Grid>

        {modal !== CONSTANTS.VIEW && (
          <Grid
            style={{
              width: "100%",
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
            >
              {modal === CONSTANTS.ADD ? "Add " : "Edit"}
            </Button>
          </Grid>
        )}
      </form>
      <ToastContainer />
    </>
  );
}

export default ModalForm;
