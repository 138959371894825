import { Button, IconButton, Modal, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { ToastContainer, toast } from "react-toastify";
import ModalForm from "./components/ModalForm";
import PageTitle from "../../components/PageTitle/PageTitle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import API from "../../services/API";
import ImportModal from "./components/ImportModal";
import PublishIcon from "@material-ui/icons/Publish";
import CloseIcon from "@material-ui/icons/Close";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 340,
    maxWidth: 600,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  importModal: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));
const columns = [
  {
    name: "vendor_name",
    label: "Vendor Name",
    type: "text",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "contact_number",
    label: "Contact",
    type: "text",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "address",
    label: "Address",
    type: "text",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "vendor_type",
    label: "Vendor Type",
    type: "select",
    select: ["Normal Vendor", "Service Provider"],
    options: {
      filter: true,
      sort: false,
    },
  },
  // {
  //   name: "alternative_contact_number",
  //   label: "Alternative Contact",
  //   type: "text",
  //   options: {
  //     filter: true,
  //     sort: false,
  //   },
  // },
  {
    name: "email",
    label: "Email",
    type: "text",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "gst_number",
    label: "GST Number (optional)",
    type: "text",
    options: {
      filter: true,
      sort: false,
    },
  },
];
const table_column = [
  {
    name: "vendor_name",
    label: "Name",
    type: "text",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "contact_number",
    label: "Contact",
    type: "text",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "address",
    label: "Address",
    type: "text",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "vendor_type",
    label: "Type",
    type: "select",
    select: ["Normal Vendor", "Service Provider"],
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "gst_number",
    label: "GST Number",
    type: "text",
    options: {
      filter: true,
      sort: false,
    },
  },
];

const CONSTANTS = {
  ADD_VENDOR: "Add Vendor",
  EDIT_VENDOR: "Edit Vendor",
  VIEW_VENDOR: "View Vendor",
};

const INITIAL_FIELDS = {
  vendor_name: "",
  contact_number: "",
  address: "",
  vendor_type: "",
  email: "",
  gst_number: "",
};

function Vendor() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [importModalOpen, setImportModalOpen] = React.useState(false);

  const [modal, setModal] = useState(CONSTANTS.ADD_VENDOR);
  const [fields, setFields] = useState({
    vendor_name: "",
    contact_number: "",
    address: "",
    vendor_type: "",
    email: "",
    gst_number: "",
  });

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      handleDelete(e);
    },
    customToolbar: () => {
      return (
        <IconButton onClick={handleOpenImportModal}>
          <PublishIcon />
        </IconButton>
      );
    },
  };

  const handleOpenImportModal = () => {
    setImportModalOpen(true);
  };
  const handleCloseImportModal = () => {
    setImportModalOpen(false);
  };

  const handleAdd = () => {
    setFields({ ...INITIAL_FIELDS });
    setModal(CONSTANTS.ADD_VENDOR);
    setOpen(true);
  };

  const handleView = (index) => {
    setFields(data[index]);
    setModal(CONSTANTS.VIEW_VENDOR);
    setOpen(true);
  };

  const handleEdit = (index) => {
    setFields(data[index]);
    setModal(CONSTANTS.EDIT_VENDOR);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const featchVendor = () => {
    API.get("/vendor/all")
      .then((response) => {
        console.log(response.data);
        setData(response.data.vendors);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  const handleSave = (values) => {
    setOpen(false);
    API.post("/vendor/add", values)
      .then((response) => {
        if (response.data.success) {
          toast.dark("Vendor added successfully");
          setData([response.data.vendor, ...data]);
        } else {
          toast.warning(response.data.message);
        }
        setFields({ ...INITIAL_FIELDS });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleUpdate = (values) => {
    setOpen(false);
    API.put(`/vendor/${values._id}`, values)
      .then((response) => {
        if (response.data.success) {
          toast.dark("Updation successfully completed");
          const updateindex = data.findIndex(
            (item) => item._id === response.data.vendor._id,
          );
          data[updateindex] = response.data.vendor;
          setData([...data]);
          setFields(Object.create(INITIAL_FIELDS));
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleDelete = (e) => {
    let array = [];
    e.data.map((item) => {
      array.push(data[item.index]._id);
      return item
    });
    API.post(`/vendor/deletemany`, { vendorids: array })
      .then((response) => {
        if (response.data.success) {
          toast.dark("Vendor deleted successfully");
        } else {
          toast.warning(response.data.message);
        }
        featchVendor();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const importExcel = (file) => {
    setImportModalOpen(false);
    console.log(file);
    let formData = new FormData();
    formData.append("excel", file);
    API.post("/vendor/import", formData)
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          toast.success("Vendor Imported Successfully");
          featchVendor();
        } else {
          console.log(response.data);
          toast.warning("Vendor validation failed");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const getRows = () => {
    return data.map((row, i) => {
      return {
        ...row,
        action: (
          <>
            <Button
              variant="contained"
              onClick={() => handleView(i)}
              color="primary"
              style={{ margin: "5px" }}
            >
              <VisibilityIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => handleEdit(i)}
              style={{ margin: "5px",backgroundColor: "#ff9001" }}
            >
              <EditIcon />
            </Button>
          </>
        ),
      };
    });
  };

  useEffect(() => {
    featchVendor();
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">{modal}</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text" >
                <CloseIcon color="error"  />
              </IconButton>
            </Grid>
          </Grid>

          <ModalForm
            columns={columns}
            CONSTANTS={CONSTANTS}
            modal={modal}
            fields={fields}
            handleSave={handleSave}
            handleUpdate={handleUpdate}
          />
        </div>
      </Modal>

      <Modal
        open={importModalOpen}
        onClose={handleCloseImportModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.importModal}>
          <h2 id="simple-modal-title">Import </h2>
          <ImportModal importExcel={importExcel} />
        </div>
      </Modal>

      <PageTitle
        title="Vendor"
        button={
          <Button
            variant="contained"
            onClick={handleAdd}
            size="medium"
            color="primary"
            startIcon={<AddIcon />}
          >
            Add Vendor
          </Button>
        }
      />

      <MuiThemeProvider >
        <MUIDataTable
          title={"Vendor List"}
          data={getRows()}
          columns={[
            ...table_column,
            {
              name: "action",
              label: "Action",
              options: {
                filter: true,
                sort: false,
              },
            },
          ]}
          options={options}
        />
      </MuiThemeProvider>
    </>
  );
}

export default Vendor;
