import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PageTitle from "../../../components/PageTitle/PageTitle";
import API from "../../../services/API";
import { ToastContainer, toast } from "react-toastify";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(2, 4, 3),
  },
}));

const columns = [
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "timing",
    label: "Timing",
    type: "text",
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "number",
  },
  {
    name: "city",
    label: "City",
    type: "text",
  },
  {
    name: "notes",
    label: "Notes",
    type: "text",
  },
  // {
  //   name: "status",
  //   label: "Status",
  //   type: "select",
  //   options: ["pending", "done"],
  // },
];

const INITIAL_FIELDS = {
  name: "",
  timing: "",
  notes: [],
  phone: "",
  city: "",
};

const EquipmentSchema = Yup.object().shape({
  name: Yup.string().required("Customer Name is required"),
});

function ModalForm(props) {

  const history = useHistory();

  const {
    CONSTANTS = {
      ADD: "Add",
      EDIT: "Edit",
      VIEW: "View",
    },
    modal = "Add",
  } = props;

  const classes = useStyles();
  const [fields, setFields] = useState({
    name: "",
    timing: "",
    notes: [],
    phone: "",
    city: "",
  });
  const [dates, setDates] = useState({
    purchased_date: new Date().toDateString(),
    complaint_date: new Date().toDateString(),
  });

  let formik = useFormik({
    initialValues: fields,
    validationSchema: EquipmentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit({ ...values });
    },
  });

  const dateHandler = (value, name) => {
    setDates({ ...dates, [name]: value });
  };

  const handleSubmit = (values) => {
    values.notes = {
      note: values.notes ?? "",
    };
    handleSave(values);
  };

  const handleSave = (values) => {
    API.post("/demo/add", { ...values })
      .then((response) => {
        if (response.data.success) {
          toast.success("Added Successfully");
          history.push("/app/demo");
        } else {
          toast.warning(response.data.message);
        }
        setFields({ ...INITIAL_FIELDS });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  useEffect(() => {
    setFields({ ...INITIAL_FIELDS });
  }, []);

  return (
    <>
      <PageTitle title="Create New Demo" />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} className={classes.paper}>
          {columns.map((column, i) => {
            if (
              (column.type === "text" || column.type === "number") &&
              column.name !== "notes"
            ) {
              return (
                <Grid item xs={6} sm={6} key={i}>
                  <TextField
                    fullWidth
                    name={column.name}
                    label={column.label}
                    variant="outlined"
                    type={column.type}
                    value={formik.values[column.name]}
                    disabled={modal === CONSTANTS.VIEW}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[column.name] &&
                      Boolean(formik.errors[column.name])
                    }
                    helperText={
                      formik.touched[column.name] && formik.errors[column.name]
                    }
                  />
                </Grid>
              );
            }
            if (column.type === "select") {
              return (
                <Grid item xs={8} sm={8} key={i}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      {column.label}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={column.label}
                      value={formik.values[column.name]}
                      onChange={formik.handleChange(column.name)}
                      type={column.type}
                      disabled={
                        modal === CONSTANTS.VIEW ||
                        column.name === "equipment_code"
                          ? true
                          : false
                      }
                      error={
                        formik.touched[column.name] &&
                        Boolean(formik.errors[column.name])
                      }
                      helperText={
                        formik.touched[column.name] &&
                        formik.errors[column.name]
                      }
                    >
                      {column?.options?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[column.name] &&
                      Boolean(formik.errors[column.name]) && (
                        <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                          {formik.errors[column.name]}
                        </p>
                      )}
                  </FormControl>
                </Grid>
              );
            }
            if (column.type === "date") {
              return (
                <Grid item xs={12} sm={6} key={i}>
                  <FormControl fullWidth variant="outlined">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <FormControl fullWidth>
                        <DatePicker
                          value={dates[column.name]}
                          onChange={(v) => dateHandler(v, column.name)}
                          variant="outlined"
                          label={column.label}
                          disabled={
                            modal === CONSTANTS.VIEW ||
                            column.name === "equipment_code"
                              ? true
                              : false
                          }
                          fullWidth
                          autoOk
                        />
                      </FormControl>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              );
            }
            if (column.name === "notes") {
              return (
                <Grid item xs={12} sm={12} key={i}>
                  <TextField
                    fullWidth
                    name={column.name}
                    label={column.label}
                    variant="outlined"
                    onChange={formik.handleChange}
                    multiline
                    type={column.type}
                    value={formik.values[column.name]}
                    minRows={2}
                  />
                </Grid>
              );
            }
            return "";
          })}

          <br />
        </Grid>

        {modal !== CONSTANTS.VIEW && (
          <Grid
            style={{
              width: "100%",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
            >
              {modal === CONSTANTS.ADD ? "Add " : "Edit"}
            </Button>
          </Grid>
        )}
      </form>
      <ToastContainer />
    </>
  );
}

export default ModalForm;
