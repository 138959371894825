import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import SaveIcon from "@mui/icons-material/Save";
import { ToastContainer, toast } from "react-toastify";
import API from "../../services/API";
import { useParams } from "react-router-dom";
import ReturnTabs from "./components/ReturnTabs";

const productList = [
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Grey",
    value: "grey",
  },
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Yellow",
    value: "yellow",
  },
];

function ReturnEdit() {
  let { returnId } = useParams();
  // Create a ref
  const orderTabRef = useRef();
  const [data, setData] = useState({});
  const [orders, setOrders] = useState([]);

  const handleSubmit = async () => {
    orderTabRef.current.handleSubmit();
  };

  let fetchAllOrder = async () => {
    await API.get("/order/all")
      .then((response) => {
        setOrders(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchReturn = async (returnId) => {
    await API.get(`/return/${returnId}`)
      .then((response) => {
        if (response.data.code === 200) {
          let res = response.data.data;
          setData({ ...res });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleUpdate = async (values) => {
    await API.patch(`/return/${values._id}`, values)
      .then((response) => {
        if (response.data.code === 200) {
          toast.success("Updated Successfully");
          fetchReturn(returnId);
        } else {
          toast.worning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  useEffect(() => {
    fetchAllOrder();
    fetchReturn(returnId);
  }, [returnId]);

  return (
    <>
      <ToastContainer />
      <PageTitle
        title="Edit Return"
        button={
          <>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </>
        }
      />

      <ReturnTabs
        ref={orderTabRef}
        returnData={data}
        orders={orders}
        handleUpdate={handleUpdate}
        productList={productList}
      />
    </>
  );
}

export default ReturnEdit;
