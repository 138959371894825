import React, { useState, useEffect } from "react";
import API from "../../services/API";
import { Button } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import MultiSelect from "./components/MultiSelect";
import { ToastContainer, toast } from "react-toastify";

export default function EditDashboard(props) {
  let [equipments, setEquipments] = useState([]);
  let [assembledUnits, setAssembledUnits] = useState([]);
  const [equip, setEquip] = useState([]);
  const [assemble, setAssemble] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    assemble_cards:[],
    equipment_cards:[],
  });

  const featchEquipment = () => {
    API.get("/equipment/all")
      .then((response) => {
        let data = response.data.equipment.map((item) => ({
          value: item._id,
          label: item.equipment_name,
        }));
        setEquipments([...data]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const featchAssembledUnits = () => {
    API.get("/assemble/all")
      .then((response) => {
        let data = response.data.assemble.map((item) => ({
          value: item._id,
          label: item.assemble_name,
        }));
        setAssembledUnits([...data]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const featchDashboard = () => {
    API.get("/dashboard/all")
      .then((response) => {
        setDashboardData([...response.data.data]);
        setEquip(dashboardData.equip)
        setAssemble(dashboardData.equip)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const addDashboardCards = () => {
    API.post("/dashboard/add", dashboardData)
      .then((response) => {
        console.log(response);
        toast.success("Updated Successfully")
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    featchEquipment();
    featchAssembledUnits();
    featchDashboard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDashboardData({
      assemble_cards:assemble,
      equipment_cards:equip,
    })
  }, [assemble,equip]);

  const updateConfig = () => {
    addDashboardCards();
  };

  return (
    <>
      <ToastContainer />
      <PageTitle title="Edit Dashboard" />
      <br></br>
      <h4>Select Equipments</h4>
      <MultiSelect
        options={equipments}
        setSelectedOptions={setEquip}
        selectedOptions={equip}
      />
      <br></br>
      <h4>Select Assembled Units</h4>
      <MultiSelect
        options={assembledUnits}
        setSelectedOptions={setAssemble}
        selectedOptions={assemble}
      />
      <br></br>
      <Button 
       style={{
        backgroundColor: "#FF5C93",
        color: "#ffffff",
        alignItems:'center'
      }}
      onClick={updateConfig}
      >Update Config</Button>
    </>
  );
}
