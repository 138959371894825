import { Divider, Grid, makeStyles, Paper, Table, TableCell, TableContainer, TableRow, Typography, styled, Button } from '@material-ui/core'
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import React, { useRef, useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom';
import logo from '../../../images/logo.png'
import Classes from './invoice.module.css'
import ReactToPrint from "react-to-print";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useLocation } from "react-router-dom";
import numberToWords from 'number-to-words'
import QRCode from '../../../images/QRcode.jpg'


// const TAX_RATE = 0.07;
const ROWS =[0,1,2,3]

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    TableCell: {
        padding:'5px'
    },
   
    tableHead: {
        backgroundColor: '#00B5F0',
        color: '#ffffff',
        maxHeight: '40px',
        padding:'10px'

    },
    tablRow: {
    },
    

});



 

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
}));




function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}

// function subTotalQty(fields) {
//     return fields.order_items.map(({ qty }) => qty).reduce((sum, i) => sum + i, 0);
// }

// function priceRow(qty, unit) {
//     return qty * unit;
// }

// function createRow(id, item, hsn_sac, qty, unit, price_by_unit) {
//     const price = priceRow(qty, price_by_unit);
//     return { id, item, hsn_sac, qty, unit, price_by_unit, price };
// }

function subtotal(items) {
    return items?.map(({ price,quantity }) => price*quantity).reduce((sum, i) => sum + i, 0);
}
function subtotalMrp(items) {
    return items?.map(({ product, quantity }) => product.product_mrp * quantity).reduce((sum, i) => sum + i, 0);
}
function totalQty(items) {
    let result = items?.map(({ quantity }) => quantity).reduce((sum, i) => sum + i, 0);
    console.log(result)
    return result;

}



function Invoice() {
    const classes = useStyles();
    const printItem = useRef();
    const location = useLocation();
    const history= useHistory()
    const [fields, setFields] = useState({});

    let invoiceTotalQty = (totalQty(location?.state?.data?.order_items) || 0)
    const invoiceSubtotalMrp = (subtotalMrp(location?.state?.data?.order_items) || 0);
    const invoiceSubtotal = (subtotal(location?.state?.data?.order_items) || 0);
    const discountPrice = invoiceSubtotalMrp - invoiceSubtotal;
    const discount = 100 * (discountPrice) / invoiceSubtotalMrp



    useEffect(() => {
        if(!location.state){
            console.log(location.state); // result: 'some_value'
            history.push({ pathname: '/app/orders'})
        }else{
            console.log(location.state); // result: 'some_value'

            setFields({ ...location.state.data });
        } 
        
    }, [history, location]);
    return (
        <>
         

            <PageTitle title="Orders" button={(<ReactToPrint
                trigger={() => (
                    <Button variant="contained" color="secondary">
                        Print
                    </Button>
                )}
                content={() => printItem.current}
                
            />)} />

            <div ref={printItem} style={{ padding: '20px 10px',maxWidth:'800px' }}>

                <Grid container spacing={3} justifyContent="space-between" alighItems='center'>
                    <Grid item xs={6}>
                        <div className={Classes.logoWrapper}>
                            <img className={Classes.logo} src={logo} alt="" />
                        </div>
                    </Grid>
                    <Grid item xs={6} className={Classes.Addresstext} >
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} >Fifth Ventricle Pvt Ltd.  </Typography>
                        <Typography  >202, Ananta Trendz, Gotri Laxmipura Road, Vadodara, 390021  </Typography>
                        <Typography >Phone no: 9875275144   </Typography>
                        <Typography style={{marginBottom:'10px'}} >Email: hello@fifthventricle.in  </Typography>
                    </Grid>
                </Grid>
                <Divider className={Classes.Divider} />
                <Typography variant="h3" style={{ fontWeight: 'bold', textAlign: 'center', color: ' #00B5F0', margin: '10px 0' }} >Tax Invoice  </Typography>
                <Divider className={Classes.Divider} />

                <Grid container spacing={3} justifyContent="space-between" alighItems='center' style={{ marginTop: '10px' , marginBottom:'10px' }}>
                    <Grid item xs={6}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }} >Bill To</Typography>
                        <Typography  >{fields?.shippingInfo?.billing_customer_name}</Typography>
                        <Typography >{fields?.shippingInfo?.billing_address},{fields?.shippingInfo?.city},{fields?.shippingInfo?.billing_state},{fields?.shippingInfo?.billing_pincode} </Typography>
                    </Grid>
                    <Grid item xs={6} className={Classes.Addresstext} >
                        <Typography variant="h6"  > {fields?.invoiceNumber ? `Invoice No : ${fields.invoiceNumber}` : null} </Typography>
                        <Typography variant="h6"  >Date: {new Date(fields.createdAt).toDateString()} </Typography>
                        <Typography variant="h6"  >Time: {new Date(fields.createdAt).toLocaleTimeString()} </Typography>
                    </Grid>
                </Grid>
                <Grid>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="spanning table">
                            <TableHead>
                                <TableRow className={classes.tableHead}>
                                    <TableCell size='small' className={Classes.whiteText}  align="center" style={{minWidth:'200px'}}>Item Name</TableCell>
                                    <TableCell size='small' className={Classes.whiteText} align="center">HSN</TableCell>
                                    <TableCell size='small' className={Classes.whiteText} align="center">Quantity</TableCell>
                                    <TableCell size='small' className={Classes.whiteText} align="center">MRP</TableCell>
                                    <TableCell size='small' className={Classes.whiteText} align="center">Price/ Unit</TableCell>
                                    <TableCell size='small' className={Classes.whiteText} align="center">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                {(fields?.order_items?.length &&  ROWS.length)?ROWS.map((row, index) => {
                                    console.log(index)
                                        if(fields?.order_items[index]?._id){
                                            return(
                                                <TableRow key={index} className={classes.tablRow}>
                                                    <TableCell align="left" size='small'  className={{root: classes.TableCell}}>{ fields?.order_items[index]?.product.product_name}</TableCell>
                                                    <TableCell align="center" size='small' >90189012</TableCell>
                                                    <TableCell align="center" size='small' >{fields?.order_items[index]?.quantity}</TableCell>
                                                    <TableCell align="center" size='small' >{fields?.order_items[index]?.product.product_mrp}</TableCell>
                                                    <TableCell align="center" size='small' >{fields?.order_items[index]?.price ? ccyFormat(fields?.order_items[index].price):null}</TableCell>
                                                    <TableCell align="center" size='small' >{fields?.order_items[index]?.price ? ccyFormat(fields?.order_items[index].price * fields?.order_items[index].quantity):null  }</TableCell>
                                                </TableRow>
                                            )
                                        }else{
                                            return(
                                                <TableRow key={index} className={classes.tablRow}>

                                                    <TableCell align="center" size='small' ></TableCell>
                                                    <TableCell align="center" size='small' ></TableCell>
                                                    <TableCell align="center" size='small' ></TableCell>
                                                    <TableCell align="center" size='small' ></TableCell>
                                                    <TableCell align="center" size='small' ></TableCell>
                                                    <TableCell align="center" size='small' ></TableCell>

                                                </TableRow>
                                            )
                                        }
                                }):null}


                                <StyledTableRow style={{ fontWeight: "bold" }} className={classes.tablRow}>
                                    <TableCell colSpan={2} >Subtotal</TableCell>
                                    <TableCell  align="center" >{invoiceTotalQty}</TableCell>
                                    <TableCell align="left">{ccyFormat(invoiceSubtotalMrp)}</TableCell>
                                    <TableCell colSpan={2}  align="right" >{ccyFormat(invoiceSubtotal)}</TableCell>
                                </StyledTableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        {/* <Typography variant="h5" style={{ fontWeight: 'bold', backgroundColor: '#00B5F0', color: "#ffffff", padding: '5px' }} >Invoice Amount In Words:</Typography>
                        <Typography variant="body1" style={{ padding: '5px' }} >{numberToWords.toWords(invoiceSubtotal)} only</Typography>
                        <Typography variant="h5" style={{ fontWeight: 'bold', backgroundColor: '#00B5F0', color: "#ffffff", padding: '5px' }} >Terms and conditions:</Typography>
                        <Typography variant="body1" style={{ padding: '5px' }}  >Thanks for doing business with us!</Typography> */}
                        <Typography variant="h5" style={{ fontWeight: 'bold', backgroundColor: '#00B5F0', color: "#ffffff", padding: '5px' }} >Bank details:</Typography>
                        <Typography variant="body1" style={{ padding: '3px' }}  >Bank Name: HDFC BANK, GOTRI II</Typography>
                        <Typography variant="body1" style={{ padding: '3px' }}  >Bank Account No.: 50200061167127</Typography>
                        <Typography variant="body1" style={{ padding: '3px' }}  >Bank IFSC code: HDFC0003789</Typography>
                        <Typography variant="body1" style={{ padding: '3px' }}  >Account Holder Name: Fifth Ventricle Private Limited</Typography>
                        <Grid style={{marginTop:'20px'}}>
                            <img src={QRCode} alt="" style={{width:'180px',}} />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <TableContainer component={Paper}>
                            <Table aria-label="spanning table">
                                <TableHead className={classes.tableHead} >
                                    <TableRow >
                                        <TableCell align="left" colSpan={3} className={Classes.whiteText} >
                                            Amounts:
                                        </TableCell>

                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    <StyledTableRow style={{ fontWeight: "bold" }} className={classes.tablRow}>
                                        <TableCell >Subtotal</TableCell>
                                        <TableCell align="right" > {invoiceTotalQty}</TableCell>
                                        <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
                                    </StyledTableRow>
                                    <TableRow className={classes.tablRow}>

                                        <TableCell>Discount</TableCell>
                                        <TableCell align="right">{`${ccyFormat(discount)} %`}</TableCell>
                                        <TableCell align="right">{ccyFormat(discountPrice)}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tablRow}>

                                        <TableCell>Advance Amount</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">{ccyFormat((fields.advance_payment ||0))}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tablRow}>
                                        <TableCell colSpan={2} >Bill to pay</TableCell>
                                        <TableCell align="right">{ccyFormat(invoiceSubtotal - (fields.advance_payment ||0))}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                            <Grid  style={{ marginTop: '10px' }}>
                                <Typography variant="h5" style={{ fontWeight: 'bold', backgroundColor: '#00B5F0', color: "#ffffff", padding: '5px' }} >Invoice Amount In Words:</Typography>
                                <Typography variant="body1" style={{ padding: '5px' }} >{numberToWords.toWords(invoiceSubtotal)} only</Typography>
                                <Typography variant="h5" style={{ fontWeight: 'bold', backgroundColor: '#00B5F0', color: "#ffffff", padding: '5px' }} >Terms and conditions:</Typography>
                                <Typography variant="body1" style={{ padding: '5px' }}  >Thanks for doing business with us!</Typography>
                           
                            </Grid>
                    </Grid>
                </Grid>


            </div>

        </>
    )
}

export default Invoice