import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  StoreMallDirectory as StoreMallDirectoryIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Build as BuildIcon,
  AccountCircle as AccountCircleIcon,
} from "@material-ui/icons";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Vendor",
    link: "/app/vendor",
    icon: <SupervisedUserCircleIcon />,
  },
  {
    id: 2,
    label: "Inventory",
    link: "/app/equipment",
    icon: <BuildIcon />,
    children: [
      { label: "Category", link: "/app/equipment/category" },
      { label: "Equipment", link: "/app/equipment/equipments" },
      { label: "Assemble", link: "/app/assemble/assemble" },
    ],
  },
  {
    id: 3,
    label: "Transactions",
    link: "/app/assemble/AssembleRequest",
    icon: <StoreMallDirectoryIcon />,
    children: [
      { label: "Purchase", link: "/app/inventory/purchase" },
      { label: "Assemble Requests", link: "/app/assemble/AssembleRequest" },
      { label: "Rejected", link: "/app/rejected" },
    ],
  },
  {
    id: 4,
    label: "Orders",
    link: "/app/orders",
    icon: <ShoppingCartCheckoutIcon />,
  },
  {
    id: 5,
    label: "Returns",
    link: "/app/returns",
    icon: <AssignmentReturnIcon />,
  },
  { id: 6, label: "Lead managment", link: "/app/lead", icon: <RecordVoiceOverIcon /> },
  {
    id: 7,
    label: "Permissions",
    link: "/app/Permissions",
    icon: <AccountCircleIcon />,
    children: [
      { label: "Users", link: "/app/permissions/user" },
      { label: "Roles", link: "/app/permissions/roles" },
    ],
  },
  { id: 8, label: "Tickets", link: "/app/tickets", icon: <FAQIcon /> },
  { id: 9, label: "Demos", link: "/app/demo", icon: <DesktopMacIcon /> },
  // { id: 7, label: "Shiprocket Orders", link: "/app/shiprocketorders", icon: <TableIcon /> },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
