import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import EditDashboard from "../../pages/dashboard/EditDashboard";
import Vendor from "../../pages/vendor/Vendor";
import Purchase from "../../pages/inventory/Purchase/Purchase";
import Inventory from "../../pages/inventory/Inventory/Inventory";
import Products from "../../pages/Products/Products";
import Equipment from "../../pages/Equipment/Equipments/Equipment";
import EquipmentCategory from "../../pages/Equipment/Categories/Categories";

import Assemble from "../../pages/Assemble/Assemble";
import AssembleRequest from "../../pages/Assemble/AssembleRequest/AssembleRequest";
import MakeARequest from "../../pages/Assemble/AssembleRequest/MakeARequest";

import User from "../../pages/permissions/User/User";
import Roles from "../../pages/permissions/Roles/Roles";

import Orders from "../../pages/orders/Orders";
import Returns from "../../pages/returns/Returns";
import ReturnEdit from "../../pages/returns/ReturnEdit";

import WebOrders from "../../pages/orders/Weborders";
import Testorders from "../../pages/TestOrder/TestOrder";
import OrderEdit from "../../pages/orders/OrderEdit";

import Invoice from "../../pages/orders/components/Invoice";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";
import LeadManagepage from "../../pages/LeadManagment/LeadManagepage";

import Ticket from "../../pages/Tickets/Ticket";
import TicketView from "../../pages/Tickets/TicketView";
import Demo from "../../pages/Demo/Demo";
import AddDemo from "../../pages/Demo/components/ModalForm";
import EditDemo from "../../pages/Demo/DemoView";

// context
import { useUserState } from "../../context/UserContext";

// pages
// import Error from "../../pages/error";
import Login from "../../pages/login";
import TicketPublicForm from "../../pages/Tickets/TicketPublicForm";
import DemoPublicForm from "../../pages/Demo/CreateDemo";
import ViewDemoForm from "../../pages/Demo/ViewDemoPhone";
import FeedbackOrderView from "../../pages/orders/FeedbackOrderView";
import PubTicketView from "../../pages/Tickets/ViewTicketPhone";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var { isAuthenticated } = useUserState();

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <>
          {isAuthenticated && <Header history={props.history} />}
          {isAuthenticated && <Sidebar />}
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            {isAuthenticated && <div className={classes.fakeToolbar} />}
            <Switch>
              <PublicRoute
                path="/pub/add-ticket"
                component={TicketPublicForm}
              ></PublicRoute>
              <PublicRoute
                path="/pub/view-ticket/:phone"
                component={PubTicketView}
              ></PublicRoute>

              <PublicRoute
                path="/pub/view-demo/:phone"
                component={ViewDemoForm}
              ></PublicRoute>
               <PublicRoute
                path="/pub/add-demo"
                component={DemoPublicForm}
              ></PublicRoute>

              <Route
                exact
                path="/"
                render={() => <Redirect to="/app/dashboard" />}
              />
              <Route
                exact
                path="/app"
                render={() => <Redirect to="/app/dashboard" />}
              />

              <PublicRoute path="/login" component={Login} />

              <PrivateRoute path="/app/dashboard" component={Dashboard} />
              <PrivateRoute
                path="/app/edit-dashboard"
                component={EditDashboard}
              />
              <PrivateRoute path="/app/vendor" component={Vendor} />
              <PrivateRoute
                path="/app/inventory/"
                exact
                render={() => <Redirect to="/app/inventory/inventory" />}
              />
              <PrivateRoute
                path="/app/inventory/purchase"
                component={Purchase}
              />
              <PrivateRoute
                path="/app/inventory/inventory"
                component={Inventory}
              />
              <PrivateRoute
                path="/app/equipment"
                exact
                render={() => <Redirect to="/app/equipment/equipments" />}
              />
              <PrivateRoute
                path="/app/equipment/equipments"
                exact
                component={Equipment}
              />
              <PrivateRoute
                path="/app/equipment/category"
                exact
                component={EquipmentCategory}
              />
              <PrivateRoute
                path="/app/assemble"
                exact
                render={() => <Redirect to="/app/assemble/assemble" />}
              />
              <PrivateRoute
                path="/app/assemble/assemble"
                exact
                component={Assemble}
              />
              <PrivateRoute
                path="/app/assemble/AssembleRequest"
                exact
                component={AssembleRequest}
              />
              <PrivateRoute
                path="/app/assemble/new-request"
                exact
                component={MakeARequest}
              />
              <PrivateRoute path="/app/products" exact component={Products} />
              <PrivateRoute
                path="/app/permissions"
                exact
                render={() => <Redirect to="/app/permissions/user" />}
              />
              <PrivateRoute
                path="/app/permissions/user"
                exact
                component={User}
              />
              <PrivateRoute
                path="/app/permissions/roles"
                exact
                component={Roles}
              />
              <PrivateRoute path="/app/orders" exact component={Orders} />
            
              <PrivateRoute path="/app/returns" exact component={Returns} />
              <PrivateRoute
                path="/app/return/:returnId"
                component={ReturnEdit}
              />
              <PrivateRoute
                path="/app/orders/web"
                exact
                component={WebOrders}
              />
               
               <PrivateRoute
                path="/app/orders/:orderId"
                component={OrderEdit}
              />
              {/* Public Route For Feedback update for order */}
              <PublicRoute
                path="/pub/view-order/:phone"
                component={FeedbackOrderView}
              ></PublicRoute>
              <PrivateRoute
                path="/app/testorder"
                exact
                component={Testorders}
              />
              <PrivateRoute
                path="/app/orders/invoice"
                exact
                component={Invoice}
              />
              <PrivateRoute path="/app/typography" component={Typography} />
              <PrivateRoute path="/app/tables" component={Tables} />
              <PrivateRoute
                path="/app/notifications"
                component={Notifications}
              />
              <PrivateRoute path="/app/lead" component={LeadManagepage} />
              <PrivateRoute path="/app/demo" component={Demo} />
              <PrivateRoute path="/app/demo-add" component={AddDemo} />
              <PrivateRoute
                path="/app/demo-edit/:demoId"
                component={EditDemo}
              />
              <PrivateRoute
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <PrivateRoute path="/app/ui/maps" component={Maps} />
              <PrivateRoute path="/app/ui/icons" component={Icons} />
              <PrivateRoute path="/app/ui/charts" component={Charts} />

              {/* Ticket Module */}
              <PrivateRoute path="/app/tickets" component={Ticket} />
              <PrivateRoute
                path="/app/ticket/:ticketId"
                component={TicketView}
              />
            </Switch>
          </div>
        </>
      </div>
    </BrowserRouter>
  );

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) => (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

export default Layout;
