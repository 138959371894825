import { IconButton, Button, makeStyles, Modal, MuiThemeProvider } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import { toast } from "react-toastify";
// import ReceiptIcon from '@material-ui/icons/Receipt';
import MUIDataTable from 'mui-datatables';

// import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import PageTitle from '../../components/PageTitle/PageTitle'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventIcon from '@material-ui/icons/Event';
import ImporMonthtModal from "./components/ImportMonthModal"
import ImporFromTodateModal from "./components/ImportFromToDateModal"
import ModalForm from './components/ModalForm';
import ShipRocketOrdermodel from './components/OrderViaShiprocket';
import axios from "axios";
import API from '../../services/API'

let shipRocktAuthToken = localStorage.getItem("shiprocketToken");

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        minWidth: 700,
        maxWidth: 900,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        height: "430px",
        overflow: "scroll"
    },
    heightModel: {
        height: "96%",
    },
    importModal: {
        position: "absolute",
        minWidth: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",

    },
}));


const columns = [

    {
        name: "date",
        label: "Date",
        type: "date"
    },
    {
        name: "customername",
        label: "Customer Name",
        type: "text",
    },
    {
        name: "chestoColor",
        label: "Chesto Color",
    },
    {
        name: "orderId",
        label: "order Id",
        type: "text",
    },
    {
        name: "paymentId",
        label: "Payment Id",
        type: "text",
    },
    {
        name: "payableAmount",
        label: "Payable Amount",
        type: "number",
    },
    {
        name: "quantity",
        label: "Quantity",
        type: "number",
    },
    {
        name: "ship_rocket",
        label: "ship rocket ",
    },
    {
        name: "type",
        label: "Type",
        type: "text",
    },
];
const CONSTANTS = {
    ADD: 'ADD',
    VIEW: 'VIEW'
}

const INITIAL_FIELDS = {
    date: '',
    customername: '',
    orderId: '',
    paymentId: '',
    payableAmount: '',
    chestoColor: '',
    type: '',
    quantity: '',
}



function Orders() {
    // const history = useHistory()
    const classes = useStyles()

    const [data, setData] = useState([]);
    const [viewOpen, setViewOpen] = useState(false);
    const [shipRocketButtonDisble, setShipRocketButtonDisble] = useState(true);
    const [dateMonthModalOpen, setDateMonthModalOpen] = useState(false);
    const [shiprocketorderModelopen, setShiprocketOrderModelopen] = useState(false);
    const [dateFromToModalOpen, setdateFromToModalOpen] = useState(false);
    const [mode, setMode] = useState(CONSTANTS.ADD)

    const [fields, setFields] = useState({ ...INITIAL_FIELDS })

    const handleMakeAnOrder = (fields, shipRocket) => {
        // setEmailSend(false)


        if (shipRocket) {

            var shipData = JSON.stringify(fields)

            var config = {
                method: 'POST',
                url: 'https://apiv2.shiprocket.in/v1/external/orders/create/adhoc',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${shipRocktAuthToken}`
                },
                // data: data
                data: shipData
            };

            axios(config)
                .then(function (response) {
                    API.post('/web/order/update', { orderId: fields.order_id, ship_rocket: shipRocket }).then(res => {

                        if (res.data.success) {
                            toast.dark("Order sent via shiprockt is successfully");
                            // sendEmailTouser(fields.shippingInfo.email, fields.shippingInfo.billing_phone, fields)

                            fetchAllOrder()
                            handleClose()
                        } else {
                            toast.warning(res.data.message);
                        }
                    });
                    // console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log("error--shiprocket  order");
                    console.log(error);
                });
        }


    }

    const options = {
        filterType: "checkbox",
        onRowsDelete: (e) => {
            handleDelete(e);
        },
        customToolbar: () => {
            return (
                <>
                    <IconButton onClick={handleMonthOpenImportModal}>
                        <CalendarTodayIcon />
                    </IconButton>
                    <IconButton onClick={handleFromToOpenImportModal}>
                        <EventIcon />
                    </IconButton>
                </>
            );
        }
    };
    const handleMonthOpenImportModal = () => {
        setDateMonthModalOpen(true);
    };

    //date From To Modal Open
    const handleFromToOpenImportModal = () => {
        setdateFromToModalOpen(true);
    };

    const handleDelete = (e) => {
        let array = [];
        e.data.map((item) => {
            array.push(data[item.index]._id);
            return item
        });
        API.post(`/web/order/deletemany`, { orderids: array })
            .then((response) => {
                if (response.data.success) {
                    toast.dark("Order deleted successfully");
                } else {
                    toast.warning(response.data.message);
                }
                fetchAllOrder()
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                console.log("finally");
            });
    };

    let handleOpen = () => {
        setShiprocketOrderModelopen(true)
    }

    const handleView = (i) => {
        setMode(CONSTANTS.VIEW);
        setFields(DataFilter(data[i]))
        setViewOpen(true)
    }


    const handleClose = () => {
        setDateMonthModalOpen(false);
        setShiprocketOrderModelopen(false);
        setdateFromToModalOpen(false);
        setViewOpen(false);
    }
    const DataFilter = (props) => {
        return {
            ...props,
            date: new Date(props.createdAt).toDateString(),
            customername: `${props.data[0]?.shippingData?.fname} ${props.data[0]?.shippingData?.lname}`,
            orderId: props.orderId,
            paymentId: props.paymentId,
            payableAmount: props.payableAmount,
            chestoColor: props.chestoColor,
            type: props.type,
            quantity: props.quantity,
            ship_rocket: props.ship_rocket,
        };
    }



    const getRows = () => {
        return data.map((row, i) => {
            let customerfullname = `${row.data[0] && row.data[0]?.shippingData?.fname} ${row.data[0] && row.data[0]?.shippingData?.lname}`
            
            return {
                ...row,
                date: new Date(row.createdAt).toDateString(),
                customername: customerfullname,
                orderId: row.orderId,
                paymentId: row.paymentId,
                payableAmount: row.payableAmount,
                chestoColor: row.chestoColor,
                type: row.type,
                quantity: row.quantity,
                ship_rocket: String(row.ship_rocket),
                action: (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => { handleView(i) }}
                            color="primary"
                            style={{ marginRight: "10px" }}
                        >
                            <Visibility />
                        </Button>
                    </>
                ),
            };
        });
    };
    let fetchAllOrder = () => {
        API.get('/web/order/all').then(response => {
            if (response.data.success) {
                let colletFalse = response.data.orders.filter((o) => o.ship_rocket === false)

                if (colletFalse.length > 0) {
                    setShipRocketButtonDisble(false)
                } else {
                    setShipRocketButtonDisble(true)
                }

                setData(response.data.orders)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    let UpdateMonthDate = (get_date, from_Date, to_date) => {

        let condition = get_date ? { onlyMonth: get_date } : { fromDate: from_Date, toDate: to_date }
        
        API.post('/web/order/filter', condition).then(response => {
            
            if (response.data.success) {
                setData(response.data.orders)
            }
        }).catch(error => {

            console.log(error)
        })
    }



    useEffect(() => {
        fetchAllOrder()
    }, [])


    return (
        <>

            <Modal
                open={viewOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <h2 id="simple-modal-title">{mode}</h2>
                    <ModalForm
                        columns={columns}
                        CONSTANTS={CONSTANTS}
                        mode={mode}
                        fields={fields}
                    />
                </div>
            </Modal>
            <Modal
                open={dateMonthModalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.importModal}>
                    <h2 id="simple-modal-title">Filter By Month </h2>
                    <ImporMonthtModal
                        handleClose={handleClose}
                        UpdateMonthDate={UpdateMonthDate}
                    />

                </div>
            </Modal>
            <Modal
                open={dateFromToModalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.importModal}>
                    <h2 id="simple-modal-title">Filter  </h2>
                    <ImporFromTodateModal
                        handleClose={handleClose}
                        UpdateMonthDate={UpdateMonthDate}
                        mode={mode}
                        fields={fields}
                    />

                </div>
            </Modal>
            <PageTitle title="Orders" button={(<Button
                variant="contained"
                disabled={shipRocketButtonDisble}
                onClick={() => { handleOpen() }}
                color="secondary"
                style={{ marginRight: "10px" }}
            >
                Ship Rocket
            </Button>)} />

             {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
                <MUIDataTable
                    title="Web Order"
                    data={getRows()}
                    columns={[
                        ...columns,
                        {
                            name: "action",
                            label: "Action",
                            options: {
                                filter: true,
                                sort: false,
                            },
                        },
                    ]}
                    options={options}
                />
            </MuiThemeProvider>
            <Modal
                open={shiprocketorderModelopen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <h2 id="simple-modal-title">Make An Order</h2>
                    <ShipRocketOrdermodel fields={fields}
                        orders={data}
                        handleMakeAnOrder={handleMakeAnOrder}

                    />
                </div>
            </Modal>

        </>
    )
}

export default Orders