import React from "react";
import API from "../services/API";

import Cookies from 'js-cookie'
import { ToastContainer, toast } from "react-toastify";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();


function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("jwtToken"),
    // isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        <ToastContainer />

        {children}
        <ToastContainer />
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, sendOtpUser, sendotpVerify, resetUserPassword };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {

  setIsLoading(true);

  API.post("/user/login", { email: login, password }).then((data) => {
    if (data.data.success) {
      toast.success("Login Success!");
      setTimeout(() => {
        localStorage.setItem('jwtToken', data.data.token)
        setError(false);
        setIsLoading(false)
        dispatch({ type: 'LOGIN_SUCCESS' })
        history.push('/app/dashboard')
      }, 1000);
      Cookies.set('token', data.data.token)
    } else {
      setError(true);
      setIsLoading(false)
      toast.warning(data.data.message);
    }
  }).catch((err) => {
    setError(true);
    toast.warning(err.response.data.message);

    setIsLoading(false);
  })

  // if (!!login && !!password) {
  //   setTimeout(() => {
  //     localStorage.setItem('id_token', 1)
  //     setError(null)
  //     setIsLoading(false)
  //     dispatch({ type: 'LOGIN_SUCCESS' })

  //     history.push('/app/dashboard')
  //   }, 2000);
  // } else {
  //   dispatch({ type: "LOGIN_FAILURE" });
  //   setError(true);
  //   setIsLoading(false);
  // }
}
function sendOtpUser(login, setIsLoading, setError) {
  setIsLoading(true);
  API.post("/user/forgotrequest", { email: login }).then((res) => {
    if (res.data.success) {
      toast.success("Otp send to Email Success!");
      setTimeout(() => {

        setError(false);
        setIsLoading(false)
      }, 1000);

    } else {
      setError(true);
      setIsLoading(false)
      toast.warning(res.data.message);
    }
  }).catch((err) => {
    setError(true);
    toast.warning(err.response.data.message);
    setIsLoading(false);
  })
}
function sendotpVerify(login, otpvalue, setIsLoading, setError) {

  setIsLoading(true);
  if (login && otpvalue) {

    API.post("/user/email/otp/verify", { email: login, otp_code: otpvalue }).then((res) => {
      console.log(res.data)
      if (res.data.success) {
        toast.success("Your Otp Verify!");
        setTimeout(() => {
          setError(false)
          setIsLoading(false)
        }, 1000);

      } else {
        setIsLoading(false)
        toast.warning(res.data.message);
      }
    }).catch((err) => {
      setError(true)
      setIsLoading(false);
      toast.warning(err.response.data.message);
    })
  } else {
    setError(true)
    setIsLoading(false);
    toast.warning("All flelds are required.");
  }
}
function resetUserPassword(login, newpassword, confirmpassword, setIsLoading, setError) {
  setIsLoading(true);
  if (login && newpassword && confirmpassword) {
    API.post("/user/forgotpassword", { email: login, newpassword, confirmpassword }).then((res) => {
      if (res.data.success) {
        toast.success("Your Password succesfully reset.");
        setTimeout(() => {
          setError(false);
          setIsLoading(false)
        }, 1200);

      } else {
        setError(true);
        toast.warning(res.data.message);
        setIsLoading(false)
      }
    }).catch((err) => {
      setError(true);
      toast.warning(err.response.data.message);
      setIsLoading(false);
    })
  } else {
    setError(true);
    toast.warning("All flelds are required.");
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("jwtToken");
  // localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  toast.success(" Logout Success");
  history.push("/login");
}
