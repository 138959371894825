import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Grid,
  Tabs,
  Tab,
  AppBar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Button,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import { Delete, Add } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import formateDate from "../../../utils/GlobalUtil";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  themeBg: {
    backgroundColor: theme.palette.background.light,
  },
  appBar: {
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.light,
  },
  gridItem: {
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  formContainer: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.light,
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  tab: {
    minWidth: 0,
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "1rem",
    color: theme.palette.text.primary,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  },
}));

const UserInfoSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Customer Name must be 3 characters at minimum")
    .required("customer Name is required"),

  phone: Yup.number()
    .min(10, "Minmum 10 number are required.")
    .required("customer Phone 10 number is required"),

  email: Yup.string().required("Email is required"),

  address: Yup.string().required("Address line 1 is required"),

  city: Yup.string().required("City is required"),

  pincode: Yup.string()
    .min(6, "Only 6 number are required.")
    .required("Pin Code is required At least 6 "),
  state: Yup.string().required("State is required"),
});

const productSchema = Yup.object().shape({
  products: Yup.array()
    .of(
      Yup.object().shape({
        color: Yup.string().required("Name is required"),
        quantity: Yup.number().required("Quantity is required"),
        price: Yup.number().required("Price is required"),
      }),
    )
    .min(1, "You need at least one product"),
});

const paymentSchema = Yup.object().shape({
  payment_method: Yup.string().required("Payment Method is required"),
  courier_company: Yup.string().required("Courier Company is required"),
  note: Yup.string().required("Note is required"),
  amount_paid: Yup.number().required("Amount Paid is required"),
});

const OrderTabs = forwardRef((props, ref) => {
  const {
    handleUpdate,
    orderObject,
    productList,
    CustomerInfoColumns,
    ShippingInfoColumns,
    StatusInfoColumns,
  } = props;
  const [value, setValue] = React.useState("one");
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Form related data
  let productForm = useFormik({
    validationSchema: productSchema,
    initialValues: {
      products: orderObject?.order_items ?? [],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      alert("test");
    },
  });

  let userInfoForm = useFormik({
    // initialValues: initialUserInfo,
    initialValues: orderObject.user_info,
    validationSchema: UserInfoSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      alert("test");
    },
  });

  const initialPaymentInfo = {
    pickup_date: orderObject?.shipping_info?.pickup_date,
    delivered_date: orderObject?.shipping_info?.delivered_date,
    doc_no: orderObject?.shipping_info?.doc_no,
    payment_method: orderObject?.shipping_info?.payment_method,
    courier_company: orderObject?.shipping_info?.courier_company,
    note: orderObject.note,
    amount_paid: orderObject.amount_paid,
    advance_payment: orderObject.advance_payment,
    status: orderObject?.status,
    replacement_date: orderObject?.replacement_date,
    refund_date: orderObject?.refund_date,
    order_date: orderObject?.order_date,
  };

  const [dates, setDates] = useState({
    pickup_date: orderObject?.shipping_info?.pickup_date,
    delivered_date: orderObject?.shipping_info?.delivered_date,
    replacement_date: orderObject?.replacement_date,
    refund_date: orderObject?.refund_date,
    order_date: orderObject?.order_date,
  });

  useEffect(() => {
    setDates({
      pickup_date: orderObject?.shipping_info?.pickup_date,
      delivered_date: orderObject?.shipping_info?.delivered_date,
      replacement_date: orderObject?.replacement_date,
      refund_date: orderObject?.refund_date,
      order_date: orderObject?.order_date,
    });
    setNotes(orderObject.feedback_notes ?? []);
  }, [orderObject]);

  let shippingInfoForm = useFormik({
    initialValues: initialPaymentInfo,
    validationSchema: paymentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      alert("test");
    },
  });

  let StatusInfoForm = useFormik({
    initialValues: initialPaymentInfo,
   // validationSchema: paymentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      createOrder(values);
    },
  });

  const dateHandler = (value, name) => {
    setDates({ ...dates, [name]: value });
  };

  const [notes, setNotes] = useState([]);

  const removeNote = (noteIndex) => {
    let allNotes = notes;
    allNotes = allNotes.filter((value, index) => index !== noteIndex);
    setNotes([...allNotes]);
  };

  const addNote = () => {
    let note = document.getElementById("new_note_value").value;
    setNotes([...notes, { note: note }]);
    document.getElementById("new_note_value").value = "";
  };

  async function createOrder(values) {
    values = { ...values, ...shippingInfoForm.values };
    let data = {
      ...orderObject,

      user_info: {
        ...userInfoForm.values,
      },
      order_items: productForm.values.products,
      shipping_info: {
        payment_method: values.payment_method,
        courier_company: values.courier_company,
        doc_no: values.doc_no,
        pickup_date: dates.pickup_date,
        delivered_date: dates.delivered_date,
      },
      // totalPrice: findTotalPrice(productForm.values.products),
      note: values.note,
      status: values.status,
      amount_paid: values.amount_paid,
      order_date: dates.order_date,
      replacement_date: dates.replacement_date,
      refund_date: dates.refund_date,
      advance_payment: values.advance_payment,
      feedback_notes: notes,
    };

    handleUpdate(data);
  }

  const handleSubmit = () => {
    StatusInfoForm.handleSubmit();
  };
  // Expose child function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit,
  }));
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            // indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="one" label="Products" classes={{ root: classes.tab }} />
            <Tab
              value="two"
              label="Customer "
              classes={{ root: classes.tab }}
            />
            <Tab
              value="three"
              label="Shipping "
              classes={{ root: classes.tab }}
            />
            <Tab value="four" label="Status " classes={{ root: classes.tab }} />
            <Tab
              value="five"
              label="Feedback "
              classes={{ root: classes.tab }}
            />
          </Tabs>
        </AppBar>
        <Grid container className={classes.formContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            md={12}
            className={classes.gridItem}
          >
            {value === "one" && (
              <FormikProvider value={productForm}>
                <FieldArray
                  name="products"
                  render={(arrayHelpers) => {
                    const products = productForm.values.products;
                    return (
                      <div>
                        {products.length &&
                          products?.map((product, index) => (
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={4}>
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="disable-clearable"
                                  options={productList}
                                  name="color"
                                  label="Product Color"
                                  value={{label:product.color, value: product.color}}
                                  getOptionLabel={(option) => option.label}
                                  onChange={(e, v) => {
                                    productForm.setFieldValue(
                                      `products.${index}.color`,
                                      v.value,
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Product Color"
                                      inputProps={{
                                        ...params.inputProps,
                                      }}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                  error={
                                    productForm.touched?.products &&
                                    productForm.errors?.products &&
                                    productForm.touched?.products[index]
                                      ?.color &&
                                    Boolean(
                                      productForm.errors?.products[index]
                                        ?.color,
                                    )
                                  }
                                  helpertext={
                                    productForm.touched?.products &&
                                    productForm.errors?.products &&
                                    productForm.touched?.products[index]
                                      ?.color &&
                                    Boolean(
                                      productForm.errors?.products[index]
                                        ?.color,
                                    )
                                  }
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <TextField
                                  fullWidth
                                  name={`products.${index}.quantity`}
                                  label="Quantity"
                                  variant="outlined"
                                  type="number"
                                  value={products[index].quantity}
                                  InputProps={{
                                    inputProps: { min: 1, max: 100 },
                                  }}
                                  onChange={productForm.handleChange}
                                  error={
                                    productForm.touched?.products &&
                                    productForm.errors?.products &&
                                    productForm.touched?.products[index]?.qty &&
                                    Boolean(
                                      productForm.errors?.products[index]?.qty,
                                    )
                                  }
                                  helpertext={
                                    productForm.touched?.products &&
                                    productForm.errors?.products &&
                                    productForm.touched?.products[index]?.qty &&
                                    Boolean(
                                      productForm.errors?.products[index]?.qty,
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  fullWidth
                                  name={`products.${index}.price`}
                                  label="Price/unit"
                                  variant="outlined"
                                  type="number"
                                  value={products[index].price}
                                  InputProps={{
                                    inputProps: { min: 0, max: 100000 },
                                  }}
                                  onChange={productForm.handleChange}
                                  error={
                                    productForm.touched?.products &&
                                    productForm.errors?.products &&
                                    productForm.touched?.products[index]
                                      ?.price &&
                                    Boolean(
                                      productForm.errors?.products[index]
                                        ?.price,
                                    )
                                  }
                                  helpertext={
                                    productForm.touched?.products &&
                                    productForm.errors?.products &&
                                    productForm.touched?.products[index]
                                      ?.price &&
                                    Boolean(
                                      productForm.errors?.products[index]
                                        ?.price,
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={1}>
                                {products.length - 1 !== index ? (
                                  <IconButton
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    {" "}
                                    <Delete />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      arrayHelpers.push({
                                        product_name: "",
                                        quantity: "",
                                        price: "",
                                      })
                                    }
                                  >
                                    <Add />{" "}
                                  </IconButton>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                      </div>
                    );
                  }}
                />
              </FormikProvider>
            )}

            {value === "two" && (
              <form onSubmit={userInfoForm.handleSubmit}>
                <Grid container spacing={2}>
                  {CustomerInfoColumns.map((column, i) => {
                    if (
                      column.type === "text" ||
                      column.type === "number" ||
                      column.type === "textarea"
                    ) {
                      return (
                        <Grid item xs={12} sm={column.sm} key={i + 2}>
                          <TextField
                            fullWidth
                            name={column.name}
                            label={column.label}
                            variant="outlined"
                            type={column.type}
                            value={userInfoForm.values[column.name]}
                            onChange={userInfoForm.handleChange}
                            multiline={
                              column.type === "textarea" ? true : false
                            }
                            minRows={column.type === "textarea" ? 3 : 1}
                            error={
                              userInfoForm.touched[column.name] &&
                              Boolean(userInfoForm.errors[column.name])
                            }
                            helpertext={
                              userInfoForm.touched[column.name] &&
                              userInfoForm.errors[column.name]
                            }
                          />
                        </Grid>
                      );
                    }
                    if (column.type === "select") {
                      return (
                        <Grid item xs={12} sm={column.sm} key={i + 2}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-simple-select-label">
                              {column.label}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={column.label}
                              value={userInfoForm.values[column.name]}
                              onChange={userInfoForm.handleChange(column.name)}
                              type={column.type}
                              error={
                                userInfoForm.touched[column.name] &&
                                Boolean(userInfoForm.errors[column.name])
                              }
                              helpertext={
                                userInfoForm.touched[column.name] &&
                                userInfoForm.errors[column.name]
                              }
                            >
                              {column.options.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      );
                    }
                    return "";
                  })}
                </Grid>
              </form>
            )}

            {value === "three" && (
              <form onSubmit={shippingInfoForm.handleSubmit}>
                <Grid container spacing={2}>
                  {ShippingInfoColumns.map((column, i) => {
                    if (
                      column.type === "text" ||
                      column.type === "number" ||
                      column.type === "textarea"
                    ) {
                      return (
                        <Grid item xs={12} sm={column.sm} key={i + "text_tab3"}>
                          <TextField
                            fullWidth
                            name={column.name}
                            label={column.label}
                            variant="outlined"
                            type={column.type}
                            value={shippingInfoForm.values[column.name]}
                            onChange={shippingInfoForm.handleChange}
                            multiline={
                              column.type === "textarea" ? true : false
                            }
                            minRows={column.type === "textarea" ? 3 : 1}
                            error={
                              shippingInfoForm.touched[column.name] &&
                              Boolean(shippingInfoForm.errors[column.name])
                            }
                            helpertext={
                              shippingInfoForm.touched[column.name] &&
                              shippingInfoForm.errors[column.name]
                            }
                          />
                        </Grid>
                      );
                    }
                    if (column.type === "select") {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={column.sm}
                          key={i + "select_tab3"}
                        >
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-simple-select-label">
                              {column.label}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={column.label}
                              value={shippingInfoForm.values[column.name]}
                              onChange={shippingInfoForm.handleChange(
                                column.name,
                              )}
                              type={column.type}
                              error={
                                shippingInfoForm.touched[column.name] &&
                                Boolean(shippingInfoForm.errors[column.name])
                              }
                              helpertext={
                                shippingInfoForm.touched[column.name] &&
                                shippingInfoForm.errors[column.name]
                              }
                            >
                              {column.options.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      );
                    }
                    if (column.type === "date") {
                      return (
                        <Grid item xs={12} sm={6} key={i + "date_tab3"}>
                          <FormControl fullWidth variant="outlined">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <FormControl fullWidth>
                                <DatePicker
                                  value={dates[column.name]}
                                  onChange={(v) => dateHandler(v, column.name)}
                                  variant="outlined"
                                  label={column.label}
                                  fullWidth
                                  autoOk
                                />
                              </FormControl>
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </Grid>
                      );
                    }
                    return "";
                  })}
                </Grid>
              </form>
            )}

            {value === "four" && (
              <form onSubmit={StatusInfoForm.handleSubmit}>
                <Grid container spacing={2}>
                  {StatusInfoColumns.map((column, i) => {
                    if (
                      column.type === "text" ||
                      column.type === "number" ||
                      column.type === "textarea"
                    ) {
                      return (
                        <Grid item xs={12} sm={column.sm} key={i + "text_tab3"}>
                          <TextField
                            fullWidth
                            name={column.name}
                            label={column.label}
                            variant="outlined"
                            type={column.type}
                            value={shippingInfoForm.values[column.name]}
                            onChange={shippingInfoForm.handleChange}
                            multiline={
                              column.type === "textarea" ? true : false
                            }
                            minRows={column.type === "textarea" ? 3 : 1}
                            error={
                              shippingInfoForm.touched[column.name] &&
                              Boolean(shippingInfoForm.errors[column.name])
                            }
                            helpertext={
                              shippingInfoForm.touched[column.name] &&
                              shippingInfoForm.errors[column.name]
                            }
                          />
                        </Grid>
                      );
                    }
                    if (column.type === "select") {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={column.sm}
                          key={i + "select_tab3"}
                        >
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-simple-select-label">
                              {column.label}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={column.label}
                              value={shippingInfoForm.values[column.name]}
                              onChange={shippingInfoForm.handleChange(
                                column.name,
                              )}
                              type={column.type}
                              error={
                                shippingInfoForm.touched[column.name] &&
                                Boolean(shippingInfoForm.errors[column.name])
                              }
                              helpertext={
                                shippingInfoForm.touched[column.name] &&
                                shippingInfoForm.errors[column.name]
                              }
                            >
                              {column.options.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      );
                    }
                    if (column.type === "date") {
                      return (
                        <Grid item xs={12} sm={6} key={i + "date_tab3"}>
                          <FormControl fullWidth variant="outlined">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <FormControl fullWidth>
                                <DatePicker
                                  value={dates[column.name]}
                                  onChange={(v) => dateHandler(v, column.name)}
                                  variant="outlined"
                                  label={column.label}
                                  fullWidth
                                  autoOk
                                />
                              </FormControl>
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </Grid>
                      );
                    }
                    return "";
                  })}
                </Grid>
              </form>
            )}

            {value === "five" && (
              <>
                <Grid container spacing={2} key={"feedback_notes_sddd"}>
                  <Grid item xs={10} sm={10}>
                    <TextField
                      fullWidth
                      name="feedback_notes"
                      label="Add New Feedback Notes"
                      variant="outlined"
                      type="textarea"
                      multiline
                      minRows={2}
                      id="new_note_value"
                      style={{ marginBottom: "10px" }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} key={"add_button_feedback_notes"}>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={() => {
                        addNote();
                      }}
                      color="secondary"
                      style={{ marginTop: "30px" }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                {notes
                  .slice()
                  .reverse()
                  .map((nodeObj, nodeIndex) => {
                    return (
                      <>
                        <Grid
                          container
                          spacing={2}
                          key={nodeIndex + "feedback_notes"}
                        >
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            key={nodeIndex + "feedback_notes1"}
                          >
                            <TextField
                              fullWidth
                              name="feedback_notes"
                              label={
                                "Feedback Notes ( " +
                                (formateDate(nodeObj?.created_at) ?? "") +
                                " )"
                              }
                              variant="outlined"
                              type="textarea"
                              value={nodeObj.note}
                              multiline
                              minRows={2}
                              style={{ marginBottom: "10px" }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            key={nodeIndex + "feedback_notes_button"}
                          >
                            <IconButton
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                removeNote(nodeIndex);
                              }}
                              color="secondary"
                              style={{
                                backgroundColor: "#f44336",
                                color: "#fff",
                                marginTop: "30px",
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
export default OrderTabs;
