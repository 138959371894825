import tinycolor from "tinycolor2";

// const primary = "#536DFE";
// const secondary = "#FF5C93";
// const warning = "#FFC260";
// const success = "#3CD4A0";
// const info = "#9013FE";
const white = "#ffffff";
const primary = "#ffe200";
const primaryDark = "#ecd103";
const primaryTranparent = "#ffe20075";
const secondary = "#50C3D7";

const warning = "#FF9001";
const success = "#78c360";
const info = "#9013FE";
const error = "#fe6854";

const bg = "#212832";
const bgDark = "#1b212a";
const bgLight = "#343A44";

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  typography: {
    fontFamily: `"Hind Vadodara", "Helvetica", "Arial", sans-serif`,
    // "fontSize": 14,
    // "fontWeightLight": 300,
    // "fontWeightRegular": 400,
    // "fontWeightMedium": 500
  },
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: primaryDark,
      warning: warning,
      primaryTranparent: primaryTranparent,
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    error: {
      main: error,
      light: tinycolor(error).lighten(lightenRate).toHexString(),
      dark: tinycolor(error).darken(darkenRate).toHexString(),
    },
    text: {
      primary: white,
      secondary: "#f2f2f2",
      hint: "#B9B9B9",
      // primary: "#4A4A4A",
      // secondary: "#6E6E6E",
      // hint: "#B9B9B9",
    },
    background: {
      main: bg,
      light: bgLight,
      dark: bgDark,
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: white,
    },
  },
  customShadows: {
    widget:
      // "0px 3px 11px 0px #212832, 0 3px 3px -2px #212832, 0 1px 8px 0 #212832",
      "5px 3px 16px 2px #1b212a, 0 3px 3px -2px #1b212a, 0 1px 8px 0 #1b212a",
    widgetDark:
      "0px 3px 18px 0px #1b212a, 0 3px 3px -2px #1b212a, 0 1px 8px 0 #1b212a",
    widgetWide:
      "0px 12px 33px 0px #343A44, 0 3px 3px -2px #343A44, 0 1px 8px 0 #343A44",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: bgDark,
        boxShadow:
          "5px 3px 16px 2px #1b212a, 0 3px 3px -2px #1b212a, 0 1px 8px 0 #1b212a",
      },
    },
    MuiSelect: {
      root: {
        color: white,
        backgroundColor: bgDark,
      },
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        backgroundColor: bgDark,
        "&$selected": {
          backgroundColor: primary+"!important",
          color: bg+"!important",
          "&:focus": {
            backgroundColor: primaryTranparent,
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: primaryTranparent,
        },
      },
    },
    MuiList: {
      root: {
        backgroundColor: bgDark,
        },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: bgLight,
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: bgLight,
        height: 56,
      },
      paper: {
        color: white,
        backgroundColor: bgLight,
      },
      head: {
        color: white,
        backgroundColor: bgLight,
      },
      body: {
        backgroundColor: bgLight,
        fontFamily: `"Hind Vadodara", "Helvetica", "Arial", sans-serif`,
      },
      toolbar: {
        backgroundColor: bgLight,
        fontFamily: `"Hind Vadodara", "Helvetica", "Arial", sans-serif`,
      },
    },
    MuiTableCell: {
      root: {
        backgroundColor: bgLight,
        borderBottom: "1px solid " + bg,
        paddingLeft: 24,
        "&:first-child": {
          backgroundColor: bgLight, // Apply background color to the first cell (top left cell)
        },
      },
      head: {
        backgroundColor: bgLight,
        fontSize: "0.95rem",
      },
      body: {
        backgroundColor: bgLight,
        fontSize: "0.95rem",
        fontFamily: `"Hind Vadodara", "Helvetica", "Arial", sans-serif`,
      },
      toolbar: {
        color: white,
        backgroundColor: bgLight,
        fontFamily: `"Hind Vadodara", "Helvetica", "Arial", sans-serif`,
      },
    },
    MuiTableSort: {
      root: {
        color: white, // Apply custom text color to the table headers (column names)
        backgroundColor: bgLight, // Apply background color to the toolbar
      },
    },
    MuiPaper: {
      root: {
        color: white, // Apply custom text color to the table headers (column names)
        backgroundColor: bgLight, // Apply background color to the toolbar
      },
    },
    MUIDataTableFilter: {
      root: {
        color: white,
        backgroundColor: bgLight, // Apply background color to the table headers (column names)
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        color: white,
        backgroundColor: bgLight, // Apply background color to the table headers (column names)
        fontWeight: "bold", // Apply custom font weight to the table headers (column names)
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        color: white,
        backgroundColor: bgLight, // Apply background color to the table headers (column names)
        fontWeight: "bold", // Apply custom font weight to the table headers (column names)
      },
    },
    MUIDataTableToolbar: {
      root: {
        color: white,
        backgroundColor: bgLight, // Apply background color to the toolbar
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
  },
};

export default defaultTheme;
