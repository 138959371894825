import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import { makeStyles } from "@material-ui/core/styles";
import EditModelForm from "./components/EditModalForm";
import { ToastContainer, toast } from "react-toastify";
import API from "../../services/API";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(2, 4, 3),
  },
  importModal: {
    position: "absolute",
    minWidth: 340,
    maxWidth: 600,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const columns = [
  {
    name: "customer_name",
    label: "Customer Name",
    type: "text",
  },
  {
    name: "phone",
    label: "Customer Phone",
    type: "text",
  },
  {
    name: "purchased_date",
    label: "Purchesed Date",
    type: "date",
  },
  {
    name: "complaint",
    label: "Complaint",
    type: "text",
  },
  {
    name: "complaint_date",
    label: "Complaint Date",
    type: "date",
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    options: ["open", "closed"],
    default: "open",
  },
  {
    name: "resolution",
    label: "Resolution",
    type: "select",
    options: ['pending', 'solved', 'replacement', 'return'],
    default: "pending",
  },
  // {
  //   name:"note",
  //   label:"Notes",
  //   type:"text",
  // }
];
const CONSTANTS = {
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
};

const INITIAL_FIELDS = {
  customer_name: "",
  purchased_date: "",
  complaint: "",
  complaint_date: "",
  status: "open", 
  resolution:"pending",
  note: "",
};

function TicketView() {
  const classes = useStyles();
  let { ticketId } = useParams();
  
  const [data, setData] = useState({...INITIAL_FIELDS});

   const fetchTicket = async (ticketId) => {
   await API.get(`/ticket/view/${ticketId}`)
      .then((response) => {
        if(response.data.success){
            let res = response.data.data
            setData({...res});
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleUpdate = (values) => {
    API.put(`/ticket/${values._id}`, values)
      .then((response) => {
        if (response.data.success) {
          toast.success("Updated Successfully");
          fetchTicket(ticketId);
           } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  useEffect(() => {
    fetchTicket(ticketId);
  }, [ticketId]);


  return (
    <>
      <ToastContainer />
      <PageTitle
        title="Edit Ticket"
      />
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Ticket Form</h2>
            </Grid>

          </Grid>
          <EditModelForm
            columns={columns}
            CONSTANTS={CONSTANTS}
            modal={CONSTANTS.EDIT}
            fields={data}
            handleUpdate={handleUpdate}
          />
        </div>
    </>
  );
}

export default TicketView;
