import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tabs,
  Tab,
  AppBar,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  themeBg: {
    backgroundColor: theme.palette.background.light,
  },
  appBar: {
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.light,
  },
  gridItem: {
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  formContainer: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.light,
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  tab: {
    minWidth: 0,
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "1rem",
    color: theme.palette.text.primary,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  },
}));

const allStatus = ["Pending", "Approved", "Completed"];

const ReturnTabs = forwardRef((props, ref) => {
  const { handleUpdate, returnData } = props;
  const [value, setValue] = useState("one");
  const [formData, setFormData] = useState({
    order: "",
    note: "",
  });
  const [note, setNote] = useState("");
  const [order, setOrder] = useState({});
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Function to calculate total quantity from the orders array
  const calculateTotalQuantity = () => {
    if (!order || !order.order_items || !order.order_items.length) {
      return 0;
    }
    return order.order_items.reduce((totalQuantity, product) => {
      return totalQuantity + product.quantity;
    }, 0);
  };
  const handleSubmit = async () => {
    let flag = true;
    if(formData.status.includes("Approved") || formData.status.includes("Completed")){
      if(formData.tested_at === "" || formData.tested_at === null){
        flag = false
        alert('Please ensure that the product item is tested and the date field is updated correctly before proceeding.')
      }
    }

    if(flag){
      handleUpdate({...formData});
    }
  };
  // Expose child function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit,
  }));

  useEffect(() => {
    setFormData(returnData);
    setOrder(returnData.order);
  }, [returnData]);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            // indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              value="one"
              label="Order Info"
              classes={{ root: classes.tab }}
            />
            <Tab value="two" label="Approval" classes={{ root: classes.tab }} />
          </Tabs>
        </AppBar>
        <Grid container className={classes.formContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            md={12}
            className={classes.gridItem}
          >
            {value === "one" && (
              <Grid container spacing={2}>
                {order && (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom></Typography>
                    <TableContainer component={Paper} variant="outlined">
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell>{order?._id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>User Name</TableCell>
                            <TableCell>{order?.user_info?.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>User Phone</TableCell>
                            <TableCell>{order?.user_info?.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>User Email</TableCell>
                            <TableCell>{order?.user_info?.email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Product Quantity</TableCell>
                            <TableCell>{calculateTotalQuantity()}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Order Status</TableCell>
                            <TableCell>{order?.status}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Amount Paid</TableCell>
                            <TableCell>{order?.amount_paid}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>City</TableCell>
                            <TableCell>{order?.user_info?.city}</TableCell>
                          </TableRow>
                          {/* Add more rows for other fields you want to display */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </Grid>
            )}

            {value === "two" && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      Return's Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Status"
                      value={formData.status}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          ...{ status: event.target.value },
                        });
                      }}
                      type="select"
                    >
                      {allStatus.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth variant="outlined">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <FormControl fullWidth>
                        <DatePicker
                          value={formData.tested_at}
                          onChange={(v) => {
                            setFormData({
                              ...formData,
                              ...{ tested_at: v },
                            });
                          }}
                          variant="outlined"
                          label="Product Tested At"
                          fullWidth
                          autoOk
                        />
                      </FormControl>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Note"
                    variant="outlined"
                    fullWidth
                    value={note}
                    multiline
                    minRows={4}
                    onChange={(event) => {
                      setNote(event.target.value);
                    }}
                    style={{ marginTop: "10px" }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
export default ReturnTabs;
