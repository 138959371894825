import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Add, Delete } from "@material-ui/icons";
import API from "../../../../services/API";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.light,
  },
}));

const AddAssempleSchema = Yup.object().shape({
  assemble_name: Yup.string()
    .min(3, " Name must be 3 characters at minimum")
    .required(" Name is required"),
  // assemble_cost: Yup.string()
  //   .required("Cost is required"),
  assemble_sku: Yup.string().required("SKU is required"),
  assemble_unit: Yup.string().required("Units is required"),
});
const COLUMNS = [
  {
    name: "assemble_name",
    label: "Name",
    type: "text",
  },
  {
    name: "assemble_sku",
    label: "SKU",
    type: "text",
  },
  {
    name: "assemble_unit",
    label: "Unit",
    type: "select",
    options: ["kg", "piece", "litre", "ml", "other"],
  },
  // {
  //   name: 'assemble_cost',
  //   label: 'Cost',
  //   type: 'number',
  // },
];

const INITIAL_FIELDS = {
  item_id: "",
  item_qty: "",
};

const INITIAL_VALUES = {
  assemble_name: "",
  assemble_sku: "",
  assemble_unit: "",
  // assemble_cost: "",
};

function AddAssemble(props) {
  const {
    handleSave,
    handleEdit,
    mode,
    setMode,
    values,
    CONSTANTS,
    assembleList,
  } = props;
  const classes = useStyles();
  const [fields, setFields] = useState({ ...INITIAL_VALUES });
  const [equipment, setEquipment] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([
    {
      item_id: "",
      item_qty: "",
    },
  ]);
  const [assembleItems, setAssembleItems] = useState([
    {
      item_id: "",
      item_qty: "",
    },
  ]);

  let formik = useFormik({
    initialValues: fields,
    enableReinitialize: true,
    validationSchema: AddAssempleSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const onSubmit = (values) => {
    if (mode === CONSTANTS.ADD) {
      handleSave({ ...values, raw_material: rawMaterials, assemble_items: assembleItems });
    }
    if (mode === CONSTANTS.EDIT) {
      handleEdit({ ...values, raw_material: [...rawMaterials] });
    }
    handleClear();
  };

  const handleRowAdd = (handleFor = "equipment") => {
    if (handleFor === "assemble") {
      if (assembleItems.at(-1).item_id && assembleItems.at(-1).item_qty) {
        assembleItems.push({ ...INITIAL_FIELDS });
        setAssembleItems([...assembleItems]);
      }
    } else {
      if (rawMaterials.at(-1).item_id && rawMaterials.at(-1).item_qty) {
        rawMaterials.push({ ...INITIAL_FIELDS });
        setRawMaterials([...rawMaterials]);
      }
    }
  };

  const handleRowRemove = (index, handleFor = "equipment") => {
    if (handleFor === "assemble") {
      assembleItems.splice(index, 1);
      setAssembleItems([...assembleItems]);
    } else {
      rawMaterials.splice(index, 1);
      setRawMaterials([...rawMaterials]);
    }
  };

  const handleRawChange = (value, index, handleFor = "equipment") => {
    if (handleFor === "assemble") {
      assembleItems[index] = { ...assembleItems[index], item_id: value };
      setAssembleItems([...assembleItems]);
    } else {
      rawMaterials[index] = { ...rawMaterials[index], item_id: value };
      setRawMaterials([...rawMaterials]);
    }
  };

  const handleQtyChange = (value, index, handleFor = "equipment") => {
    if (handleFor === "assemble") {
      assembleItems[index] = { ...assembleItems[index], item_qty: value };
      setAssembleItems([...assembleItems]);
    } else {
    rawMaterials[index] = { ...rawMaterials[index], item_qty: value };
    setRawMaterials([...rawMaterials]);
    }
  };

  const handleClear = () => {
    setMode(CONSTANTS.ADD);
    setFields({ ...INITIAL_VALUES });
    setRawMaterials([{ ...INITIAL_FIELDS }]);
    setAssembleItems([{ ...INITIAL_FIELDS }]);
    formik.resetForm();
  };

  useEffect(() => {
    API.get("/equipment/all")
      .then((response) => {
        setEquipment(response.data.equipment);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (mode !== CONSTANTS.ADD) {
      const { raw_material,assemble_items, ...rest } = values;

      console.log(values);

      let checkEquipment = equipment.map((equipment) => equipment._id);
      let filterRaw = raw_material.filter((raw) => {
        if (checkEquipment.includes(raw.item_id)) {
          return raw;
        } else {
          raw.item_id = "deleted_equipment";
          return raw;
        }
      });

      let checkAssemble = assembleList.map((assemble) => assemble._id);
      let filterAssemble = assemble_items.filter((raw) => {
        if (checkAssemble.includes(raw.item_id)) {
          return raw;
        } else {
          raw.item_id = "deleted_assemble";
          return raw;
        }
      });

      setFields({ ...rest });
      setRawMaterials([...filterRaw]);
      setAssembleItems([...filterAssemble]);
    } else {
      formik.resetForm();

      setRawMaterials([
        {
          item_id: "",
          item_qty: "",
        },
      ]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CONSTANTS.ADD, equipment, mode, values]);

  return (
    <>
      <Card  className={classes.paper}>
        <CardHeader title="Add Assemble" />
        <Divider />
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              {COLUMNS.map((column, index) => {
                if (column.type === "text" || column.type === "number") {
                  return (
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        name={column.name}
                        label={column.label}
                        variant="outlined"
                        type={column.type}
                        value={formik.values[column.name]}
                        onChange={formik.handleChange}
                        disabled={mode === CONSTANTS.VIEW ? true : false}
                        error={
                          formik.touched[column.name] &&
                          Boolean(formik.errors[column.name])
                        }
                        helperText={
                          formik.touched[column.name] &&
                          formik.errors[column.name]
                        }
                      />
                    </Grid>
                  );
                }
                if (column.type === "select") {
                  return (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          {column.label}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={column.label}
                          value={formik.values[column.name]}
                          onChange={formik.handleChange(column.name)}
                          type={column.type}
                          disabled={mode === CONSTANTS.VIEW ? true : false}
                          error={
                            formik.touched[column.name] &&
                            Boolean(formik.errors[column.name])
                          }
                          helperText={
                            formik.touched[column.name] &&
                            formik.errors[column.name]
                          }
                        >
                          {column?.options?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                }
                return "";
              })}
              <Divider />
              <Grid item xs={12}>
                {assembleItems.map((item, index) => (
                  <Grid container spacing={2} alignItems="center" key={index}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          Assembled Item
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Item"
                          onChange={(e, value) =>
                            handleRawChange(value.props.value, index,'assemble')
                          }
                          value={item.item_id}
                          disabled={mode === CONSTANTS.VIEW ? true : false}
                        >
                          {assembleList.map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              {item.assemble_name}
                            </MenuItem>
                          ))}
                          {mode !== CONSTANTS.ADD ? (
                            <MenuItem value="deleted_assemble" selected>
                              Deleted Assemble{" "}
                            </MenuItem>
                          ) : null}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={mode === CONSTANTS.VIEW ? 6 : 5}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={item.item_qty}
                        label="Quantity"
                        type="number"
                        onChange={(e) => {
                          handleQtyChange(e.target.value, index, 'assemble');
                        }}
                        disabled={mode === CONSTANTS.VIEW ? true : false}
                      />
                    </Grid>
                    {mode !== CONSTANTS.VIEW &&
                      (assembleItems.length - 1 === index ? (
                        <Grid item xs={1}>
                          <IconButton
                            disabled={mode === CONSTANTS.VIEW ? true : false}
                            style={{
                              backgroundColor: "#536DFE",
                              color: "#ffffff",
                            }}
                            size="small"
                            onClick={(e)=>{
                              handleRowAdd('assemble')}
                            }
                          >
                            <Add />
                          </IconButton>
                        </Grid>
                      ) : (
                        <Grid item xs={1}>
                          <IconButton
                            disabled={mode === CONSTANTS.VIEW ? true : false}
                            style={{
                              backgroundColor: "#FF5C93",
                              color: "#ffffff",
                            }}
                            size="small"
                            onClick={() => handleRowRemove(index,'assemble')}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      ))}
                  </Grid>
                ))}
              </Grid>
              <Divider />

              <Grid item xs={12}>
                {rawMaterials.map((item, index) => (
                  <Grid container spacing={2} alignItems="center" key={index}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          Equipment
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Item"
                          onChange={(e, value) =>
                            handleRawChange(value.props.value, index)
                          }
                          value={item.item_id}
                          disabled={mode === CONSTANTS.VIEW ? true : false}
                        >
                          {equipment.map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              {item.equipment_name}
                            </MenuItem>
                          ))}
                          {mode !== CONSTANTS.ADD ? (
                            <MenuItem value="deleted_equipment" selected>
                              Deleted Equipment{" "}
                            </MenuItem>
                          ) : null}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={mode === CONSTANTS.VIEW ? 6 : 5}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={item.item_qty}
                        label="Quantity"
                        type="number"
                        onChange={(e) => {
                          handleQtyChange(e.target.value, index);
                        }}
                        disabled={mode === CONSTANTS.VIEW ? true : false}
                      />
                    </Grid>
                    {mode !== CONSTANTS.VIEW &&
                      (rawMaterials.length - 1 === index ? (
                        <Grid item xs={1}>
                          <IconButton
                            disabled={mode === CONSTANTS.VIEW ? true : false}
                            style={{
                              backgroundColor: "#536DFE",
                              color: "#ffffff",
                            }}
                            size="small"
                            onClick={handleRowAdd}
                          >
                            <Add />
                          </IconButton>
                        </Grid>
                      ) : (
                        <Grid item xs={1}>
                          <IconButton
                            disabled={mode === CONSTANTS.VIEW ? true : false}
                            style={{
                              backgroundColor: "#FF5C93",
                              color: "#ffffff",
                            }}
                            size="small"
                            onClick={() => handleRowRemove(index)}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      ))}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                {mode !== CONSTANTS.VIEW && (
                  <Button
                    variant="contained"
                    type="submit"
                    size="medium"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    disabled={mode === CONSTANTS.VIEW ? true : false}
                  >
                    Submit Assemble
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleClear}
                  size="medium"
                  color="error"
            style={{backgroundColor: "#fe6854" }}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

export default AddAssemble;
