import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import API from "../../services/API";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "./styles";
import DailyTotalComp from "./components/Chart/DailyTotalComp";
// import EditIcon from "@material-ui/icons/Edit";
// import VisibilityIcon from "@material-ui/icons/Visibility";

export default function Dashboard(props) {
  var classes = useStyles();
  let [data, setData] = useState([]);
  let [assembledUnits, setAssembledUnits] = useState([]);
  const [dashboardData, setDashboardData] = useState();
  const [equipment_cards, setEquipment_cards] = useState([]);
  const [assemble_cards, setAssemble_cards] = useState([]);
  const featchEquipment = async () => {
    await API.get("/equipment/all")
      .then((response) => {
        console.log(response.data);
        let data = response.data.equipment.map((item) => ({
          ...item,
          equipment_category_name: item.equipment_category?.category_name,
          equipment_category: item.equipment_category?._id,
        }));
        setData([...data]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const featchAssembledUnits = async () => {
    await API.get("/assemble/all")
      .then((response) => {
        console.log(response.data);
        setAssembledUnits(response.data.assemble);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const featchDashboard = async () => {
   await API.get("/dashboard/all")
      .then((response) => {
        let res = response.data.data;
          console.log(res[0])
          setDashboardData(res[0])
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  const getEquptmentCount = (id) =>{
    let count = data.filter((item) => item._id === id);
    return count.length>0 ? count[0].equipment_qty : 0
  }
  const getAssembledUnitsCount = (id) =>{
    let count = assembledUnits.filter((item) => item._id === id);
    return count.length>0 ? count[0].assembled_unit : 0
  }

  const columns = [
    {
      name: "equipment_name",
      label: "Name",
      type: "text",
    },
    {
      name: "equipment_code",
      label: "Code",
      type: "text",
    },
    {
      name: "equipment_qty",
      label: "Quantitty",
      type: "number",
    },
    {
      name: "equipment_unit",
      label: "Unit",
      type: "select",
      options: ["kg", "piece", "litre", "ml", "other"],
    },
  ];
  const assembledUnitColumns = [
    {
      name: "assemble_name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "assemble_sku",
      label: "SKU",
      options: {
          filter: true,
          sort: false,
      },
  },
    {
      name: "assembled_unit",
      label: "Units",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

   useEffect( () => {
    featchDashboard();
  }, []);

   useEffect( () => {
     featchEquipment();
     featchAssembledUnits();
  }, []);

  useEffect( () => {
    console.log(dashboardData)
    setEquipment_cards(dashboardData?.equipment_cards??[])
    setAssemble_cards(dashboardData?.assemble_cards??[])
  }, [dashboardData]);

  return (
    <>
      <PageTitle title="Dashboard" />
      <h4>Equipments</h4>
      <Grid container spacing={2}>
        {equipment_cards.length>0 && equipment_cards.map((item, i) => {
          return (<DailyTotalComp
            key={i}

            comp_title={item.label+' Counts'}
            body_class={classes.fullHeightBody}
            class_card={classes.card}
            class_div={classes.visitsNumberContainer}
            text_val={getEquptmentCount(item.value)}
          />)
        })}
        
      </Grid>
      <br></br>
      <h4>Assembled Units</h4>
      <Grid container spacing={2}>
        {assemble_cards.length>0 && assemble_cards.map((item,i) => {
          return (<DailyTotalComp
          key={i}
            comp_title={item.label+' Counts'}
            body_class={classes.fullHeightBody}
            class_card={classes.card}
            class_div={classes.visitsNumberContainer}
            text_val={getAssembledUnitsCount(item.value)}
          />)
        })}
        
      </Grid>
      <br></br>
      <h4>Tables</h4>

        <Grid container >
          <div>
            <MuiThemeProvider >
              <MUIDataTable
                title={"Equipments"}
                data={data}
                columns={[
                  ...columns,
                  // {
                  //   name: "action",
                  //   label: "Action",
                  //   options: {
                  //     filter: true,
                  //     sort: false,
                  //   },
                  // },
                ]}
                options={{
                  rowsPerPageOptions: [5, 10, 15],
                  search:true,
                  download:true,
                  print:false,
                  filter:false,
                  selectableRows: false,
                }}
              />
            </MuiThemeProvider>
          </div>

          <div style={{marginLeft:'20px'}}>
            <MuiThemeProvider >
              <MUIDataTable
                title={"Assembled Units"}
                data={assembledUnits}
                columns={[...assembledUnitColumns]}
                options={{
                  rowsPerPageOptions: [5, 10, 15],
                  search:true,
                  download:true,
                  print:false,
                  filter:false,
                  selectableRows: false,
                }}
              />
            </MuiThemeProvider>
          </div>
        </Grid>
    </>
  );
}
