import React from "react";

// components
import Layout from "./Layout";

import "./style.css";

export default function App() {
  return <Layout />;
}
