import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@material-ui/core'
import { Edit, Visibility } from '@material-ui/icons'
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import { MuiThemeProvider } from "@material-ui/core/styles";

import PageTitle from '../../components/PageTitle/PageTitle'
import AddAssemble from './components/AddAssemble/AddAssemble'
import API from '../../services/API'




const columns = [
    {
        name: "assemble_name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
        },
    },
    // {
    //     name: "assemble_cost",
    //     label: "Cost",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     },
    // },
    {
        name: "assemble_sku",
        label: "SKU",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "assemble_code",
        label: "Item Code",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "assembled_unit",
        label: "Assembled Units",
        options: {
            filter: true,
            sort: false,
        },
    },


];

const CONSTANTS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
}



const INITIAL_VALUES = {
    assemble_name: '',
    assemble_sku: '',
    assemble_unit: "",
    // assemble_cost: "",
    assemble_category: '',
    rawMaterials: [
        {
            item_id: '',
            item_qty: ''
        }

    ]

}


function Assemble() {

    const [data, setData] = useState([])
    const [mode, setMode] = useState(CONSTANTS.ADD)
    const [values, setValues] = useState({ ...INITIAL_VALUES })


    const options = {
        filterType: "checkbox",
        onRowsDelete: (e) => {
            handleDelete(e)
        }
    };

    const handleSave = (value) => {

        API.post('/assemble/add', value).then(response => {

            if (response.data.success) {
                toast.success("Assemble  Added Successfully")
                setData([...response.data.assemble, ...data]);
                setMode(CONSTANTS.ADD)

            } else {
                toast.warning(response.data.message);
            }

        }).catch(error => {
            console.log(error)
        }).finally(() => {
            console.log('finally')
        })
    }

    const handleEdit = (value) => {

        let oldData = data.filter(item => item.assemble_id === value.assemble_id)
        const updatedData = { ...oldData[0], ...value }
        API.put(`/assemble/${value._id}`, updatedData).then(response => {
            if (response.data.success) {

                toast.success("Assemble Updated Successfully")
                const updateindex = data.findIndex((item) => item._id === response.data.assemble._id);
                data[updateindex] = response.data.assemble;
                setData([...data]);
            } else {
                toast.warning(response.data.message);
            }

        }).catch(error => {
            console.log(error)
        }).finally(() => {
            console.log('finally')
        })

        setMode(CONSTANTS.ADD)
        const updateindex = data.findIndex((item) => item.id === value.id);
        data[updateindex] = value;
        setData([...data]);
    }

    const handleDelete = (e) => {
        const array = e.data.map(item => data[item.index]._id);

        API.post(`/assemble/deletemany`, { assembleIds: array }).then(response => {
            if (response.data.success) {
                toast.dark("Assemble Deleted Successfully");
            } else {
                toast.warning(response.data.message);
            }
            featchAssemble()
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            console.log('finally')
        })
    }

    const setView = (index) => {
        let result = data[index]
        setValues({ ...result })
        setMode(CONSTANTS.VIEW)
    }

    const setEdit = (index) => {
        let result = data[index]
        setValues({ ...result })
        setMode(CONSTANTS.EDIT)
    }

    const featchAssemble = () => {
        API.get('/assemble/all').then(response => {
            console.log(response.data.assemble)
            setData([...response.data.assemble])
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            console.log('finally')
        })
    }

    const getRows = () => {
        return data.map((row, i) => {
            return {
                ...row,
                action: (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => { setView(i) }}
                            color="primary"
                            style={{ margin: "5px" }}
                        >
                            <Visibility />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => { setEdit(i) }}
                            color="secondary"
                            style={{ margin: "5px", backgroundColor: "#FF9001" }}
                        >
                            <Edit />
                        </Button>
                    </>
                ),
            };
        });
    };

    useEffect(() => {

        featchAssemble()

    }, [])
    return (
        <>
            <PageTitle
                title="Assemble"
            />
            <ToastContainer />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                     {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
                        <MUIDataTable
                            title={"Assemble List"}
                            data={getRows()}
                            columns={[
                                ...columns,
                                {
                                    name: "action",
                                    label: "Action",
                                    options: {
                                        filter: true,
                                        sort: false,
                                    },
                                },
                            ]}
                            options={options}
                        />
                    </MuiThemeProvider>

                </Grid>
                <Grid item md={6}>
                    <AddAssemble
                        handleSave={handleSave}
                        handleEdit={handleEdit}
                        mode={mode}
                        setMode={setMode}
                        values={values}
                        CONSTANTS={CONSTANTS}
                        assembleList={data}
                    />

                </Grid>
            </Grid>




        </>
    )
}

export default Assemble;




