import {
  Button,
  IconButton,
  Grid,
  makeStyles,
  Modal,
  MuiThemeProvider,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import MakeAnOrder from "./components/MakeAnOrder";
import EventIcon from "@material-ui/icons/Event";
import CloseIcon from "@material-ui/icons/Close";
import API from "../../services/API";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 700,
    maxWidth: 900,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    height: "96%",
  },
  importModal: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const CONSTANTS = {
  ADD: "ADD",
  VIEW: "VIEW",
};

const columns = [
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "phone",
    label: "Phone",
    type: "text",
  },
  {
    name: "qty",
    label: "Quantity",
    type: "number",
  },
  {
    name: "type",
    label: "Return Type",
    type: "text",
  },
  {
    name: "status",
    label: "Status",
    type: "text",
  },
  {
    name: "createdAt",
    label: "CreatedAt",
    type: "text",
  },
];

function Returns() {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(CONSTANTS.ADD);
  const [dateMonthModalOpen, setDateMonthModalOpen] = useState(false);
  const [dateFromToModalOpen, setdateFromToModalOpen] = useState(false);

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      handleDelete(e);
    },
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={handleMonthOpenImportModal}>
            <CalendarTodayIcon />
          </IconButton>
          <IconButton onClick={handleMonthOpenImportModal}>
            <CalendarTodayIcon />
          </IconButton>
          <IconButton onClick={handleFromToOpenImportModal}>
            <EventIcon />
          </IconButton>
        </>
      );
    },
  };
  const handleDelete = (e) => {
    let array = [];
    e.data.map((item) => {
      array.push(data[item.index]._id);
      return item;
    });
    API.post(`/return/deletemany`, { returnIds: array })
      .then((response) => {
        if (response.data.code === 200) {
          toast.dark("Return deleted successfully");
        } else {
          toast.warning(response.data.message);
        }
        fetchAllReturns();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleClose = () => {
    setMode(CONSTANTS.ADD);
    setOpen(false);
    setDateMonthModalOpen(false);
    setdateFromToModalOpen(false);
  };

  const handleOpen = (isEdit = false) => {
      setOpen(true);
  };

  const handleMakeAnOrder = async (fields) => {
    try {
      const response = await API.post("/return/create", { ...fields });
      if (response.data.code === 200) {
        toast.success(response.data.message);
        fetchAllReturns();
        handleClose();
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        // Access the error data object: error.response.data
        toast.error(errorMessage);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleMonthOpenImportModal = () => {
    setDateMonthModalOpen(true);
  };
  const handleFromToOpenImportModal = () => {
    setdateFromToModalOpen(true);
  };
  const handleMonthCloseImportModal = () => {
    setDateMonthModalOpen(false);
  };

  const getRows = () => {
    return data.map((row, i) => {
      return {
        ...row?.order?.user_info,
        ...row,
        createdAt: new Date(row.createdAt).toDateString(),
        qty: row?.order?.order_items[0]?.quantity,
        action: (
          <>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "5px", backgroundColor: "#FF9001" }}
              onClick={() => {
                history.push({
                  pathname: "/app/return/" + row._id,
                });
              }}
            >
              <EditIcon />
            </Button>
          </>
        ),
      };
    });
  };

  let fetchAllOrder = async () => {
    await API.get("/order/all")
      .then((response) => {
        setOrderData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let fetchAllReturns = async () => {
    await API.get("/return/all")
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAllReturns();
    fetchAllOrder();
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        open={dateMonthModalOpen}
        // onClose={handleMonthCloseImportModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.importModal}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">Filter By Month </h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleMonthCloseImportModal} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

        </div>
      </Modal>
      <Modal
        open={dateFromToModalOpen}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.importModal}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Filter</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

        </div>
      </Modal>
      <PageTitle
        title="Order Returns"
        button={
          <Button
            variant="contained"
            onClick={() => {
              handleOpen();
            }}
            color="primary"
            style={{ marginRight: "10px" }}
          >
            Add New
          </Button>
        }
      />
      {/* <MuiThemeProvider theme={getMuiTheme()}> */}
      <MuiThemeProvider>
        <MUIDataTable
          title="Manage Order Returns"
          data={getRows()}
          columns={[
            ...columns,
            {
              name: "action",
              label: "Action",
              options: {
                filter: true,
                sort: false,
              },
            },
          ]}
          options={options}
        />
      </MuiThemeProvider>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">Make An Order</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

          <MakeAnOrder
            orders={orderData}
            handleMakeAnOrder={handleMakeAnOrder}
            mode={mode}
          />
        </div>
      </Modal>
    </>
  );
}

export default Returns;
