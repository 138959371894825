import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import CurrencyComp from '../../../../components/CurrencyComp';
import Widget from '../../../../components/Widget/Widget';

export default function DailyTotalComp({ comp_title, body_class, class_card, class_div, text_val, payment }) {

    let paymentval = ['total', 'today']
    return (
        <>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Widget
                    // title={comp_title}
                    title={paymentval.includes(payment) ? <><CurrencyComp currency_val={text_val / 100} /></> : <>{text_val}</>}
                    upperTitle
                    bodyClass={body_class}
                    className={class_card}
                    disableWidgetMenu={true}
                >
                    <div className={class_div}>
                        <Grid container item alignItems={"center"}>
                            <Grid item xs={6}>
                                <Typography size="xl" weight="medium" noWrap>
                                    {comp_title}
                                    {/* {paymentval.includes(payment) ? <><CurrencyComp currency_val={text_val / 100} /></> : <>{text_val}</>} */}
                                </Typography>
                            </Grid>

                        </Grid>
                    </div>

                </Widget>
            </Grid>
        </>
    )
}
