import React, { useEffect } from "react";
import { Button, Grid, TextField } from "@material-ui/core";

import { useFormik } from "formik";

function ModalForm(props) {
  const { CONSTANTS, modal, fields, handleSave, handleUpdate, columns } = props;

  let formik = useFormik({
    initialValues: fields,

    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    if (modal === CONSTANTS.ADD) {
      handleSave({ ...values });
    } else if (modal === CONSTANTS.EDIT) {
      handleUpdate({ ...values });
    }
  };

  useEffect(() => {
    if (modal === CONSTANTS.ADD) {
      formik.resetForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {columns.map((column, i) => {
          return (
            <Grid item xs={12} sm={6} key={i}>
              <TextField
                fullWidth
                name={column.name}
                label={column.label}
                variant="outlined"
                type={column.type}
                value={formik.values[column.name]}
                disabled={modal === CONSTANTS.VIEW}
                onChange={formik.handleChange}
                error={
                  formik.touched[column.name] &&
                  Boolean(formik.errors[column.name])
                }
                helperText={
                  formik.touched[column.name] && formik.errors[column.name]
                }
              />
            </Grid>
          );
        })}
      </Grid>

      {modal !== CONSTANTS.VIEW && (
        <Grid
          style={{
            width: "100%",
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            size="medium"
            color="secondary"
          >
            {modal === CONSTANTS.ADD ? "Add " : "Edit"}
          </Button>
        </Grid>
      )}
    </form>
  );
}

export default ModalForm;
