import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  // createTheme,
  Grid,
  makeStyles,
  Modal,
} from "@material-ui/core";
// import {
//   // Receipt as ReceiptIcon,
//   Edit as EditIcon,
//   Publish as PublishIcon,
// } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import ImportModal from "./components/ImportFilemodel";
// import MUIDataTable from "mui-datatables";
import Editmodelcomp from "./components/Editmodelcomp";
import API from "../../services/API";
import CloseIcon from "@material-ui/icons/Close";
import { useLayoutEffect } from "react";
// import MaterialTable from './components/Materialuitablecfunc';
import MaterialNewTable from "./components/Table/Materialtable";
import PageTitle from "../../components/PageTitle/PageTitle";

// const getMuiTheme = () =>
//   createTheme({
//     overrides: {
//       MuiTableCell: {
//         head: {
//           fontWeight: "bold",
//         },
//       },
//     },
//   });

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 340,
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    maxHeight: "90vh",
    overflowY: "scroll",
  },
  importModal: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));
const CONSTANTS = {
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
};

export default function LeadManagepage() {
  const classes = useStyles();
  const [editModeOpen, setEditModeOpen] = React.useState(false);

  const [importModalOpen, setImportModalOpen] = useState(false);
  const [modal, setModal] = useState(CONSTANTS.EDIT);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [newcolumnDefs, setNewColumnDefs] = useState([]);
  const [fields, setFields] = useState({});
  const [newEditfields, setNewEditfields] = useState([]);

  // const options = {
  //   filterType: "checkbox",
  //   editable: true,
  //   onRowsDelete: (e) => {
  //     handleDelete(e);
  //   },
  //   customToolbar: () => {
  //     return (
  //       <>
  //         <IconButton onClick={handleOpenImportModal}>
  //           <PublishIcon />
  //         </IconButton>
  //       </>
  //     );
  //   },
  // };

  let fetchData = () => {
    API.get("/lead/all").then((res) => {
      console.log("res.data--callll");
      if (res.data.success) {
        setRowData(res.data.result);
        let setNewArray = [];
        let setColumnNewArray = [];
        let setColumnNewEditableArray = [];
        for (let [key, value] of Object.entries(res.data.result[0])) {
          console.log(typeof key);
          console.log(typeof value);

          if ("_id" !== key) {
            setNewArray.push({
              label: key.replace("_", " "),
              // label: key == "created_at" || key == "followup_date" ? "Date" : key.replace("_", " "),
              name: key,
              editable: true,
              type:
                key === "created_at" || key === "followup_date"
                  ? "date"
                  : key === "contact_number"
                  ? "number"
                  : "text",
            });
          }
          if ("_id" !== key) {
            setColumnNewArray.push({
              name: key.replace("_", " "),
              // label: key == "created_at" || key == "followup_date" ? "Date" : key.replace("_", " "),
              prop: key,

              minWidth: 270,
              type:
                key === "created_at" || key === "followup_date"
                  ? "date"
                  : key === "contact_number"
                  ? "number"
                  : "text",
            });
          }
          if ("_id" !== key) {
            setColumnNewEditableArray.push({
              title: key.replace("_", " "),
              // label: key == "created_at" || key == "followup_date" ? "Date" : key.replace("_", " "),
              field: key,

              type:
                key === "created_at" || key === "followup_date"
                  ? "date"
                  : key === "contact_number"
                  ? "numeric"
                  : "text",
            });
          }
        }
        console.log("setColumnNewEditableArray");
        console.log(setColumnNewEditableArray);
        setColumnDefs([...setNewArray]);
        setNewColumnDefs([...setColumnNewArray]);
        setNewEditfields([...setColumnNewEditableArray]);
      }
    });
  };
  useLayoutEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    // setData(rowData)
  }, []);

  // const getRows = () => {
  //   return rowData.map((row, i) => {
  //     return {
  //       ...row,
  //       created_at: new Date(row.created_at).toDateString(),
  //       followup_date: new Date(row.followup_date).toDateString(),

  //       lead: (
  //         <Chip
  //           label={row.lead === "yes" ? "Yes" : "No"}
  //           clickable
  //           color={row.lead === "yes" ? "primary" : "secondary"}
  //           onClick={() => {
  //             // return row.status === 'pending' ? handlestatusModalOpen(i) : null;
  //           }}
  //         />
  //       ),
  //       email: (
  //         <Chip
  //           label={row.email === "yes" ? "Yes" : "No"}
  //           clickable
  //           color={row.email === "yes" ? "primary" : "secondary"}
  //           onClick={() => {
  //             // return row.status === 'pending' ? handlestatusModalOpen(i) : null;
  //           }}
  //         />
  //       ),
  //       call: (
  //         <Chip
  //           label={row.call === "yes" ? "Yes" : "No"}
  //           clickable
  //           color={row.call === "yes" ? "primary" : "secondary"}
  //           onClick={() => {
  //             // return row.status === 'pending' ? handlestatusModalOpen(i) : null;
  //           }}
  //         />
  //       ),

  //       action: (
  //         <>
  //           <Button
  //             variant="contained"
  //             onClick={() => {
  //               handleEdit(i);
  //             }}
  //             color="secondary"
  //           >
  //             <EditIcon />
  //           </Button>
  //           <ToastContainer />
  //         </>
  //       ),
  //     };
  //   });
  // };
  // const handleEdit = (index) => {
  //   setFields(rowData[index]);
  //   setModal(CONSTANTS.EDIT);
  //   setEditModeOpen(true);
  // };

  let handleUpdate = (values) => {
    setEditModeOpen(false);
    API.patch(`/lead/update`, values)
      .then((response) => {
        if (response.data?.success) {
          toast.success("Lead Updated Successfully");
          fetchData();
        } else {
          toast.warning(response.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  let handleDelete = (values) => {
    // setEditModeOpen(false);
    console.log(values, "values--emial");
    if (values) {
      API.delete(`/lead/singlelead/${values}`)
        .then((response) => {
          if (response.data?.success) {
            toast.success("Lead Deleted Successfully");
            fetchData();
          } else {
            toast.warning(response.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          console.log("finally");
        });
    }
  };
  let handleSave = () => {};
  const handleOpenImportModal = () => {
    setImportModalOpen(true);
  };
  const handleCloseImportModal = () => {
    setImportModalOpen(false);
    setEditModeOpen(false);
  };

  const importExcel = (file) => {
    setImportModalOpen(false);
    console.log(file);
    let formData = new FormData();
    formData.append("leadexcel", file);
    API.post("/lead/import", formData)
      .then((response) => {
        if (response.data.success) {
          toast.success("lead Imported Successfully");
          fetchData();
        } else {
          toast.warning("Lead  validation failed");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  return (
    <>
      {/* <MaterialTable headers={newcolumnDefs} fields={fields} /> */}
       <ToastContainer />

      <PageTitle
        title="Lead Management"
        button={
          <Button
            variant="contained"
            onClick={() => {
              handleOpenImportModal();
            }}
            color="secondary"
            style={{ marginRight: "10px" }}
          >
            Import File
          </Button>
        }
      />

      <MaterialNewTable
        handleOpenImportModal={handleOpenImportModal}
        handleDelete={handleDelete}
        headers={newEditfields && newEditfields}
        handleUpdate={handleUpdate}
        rowdatas={rowData && rowData}
      />

      <Modal
        open={editModeOpen}
        // onClose={handleCloseImportModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2 id="simple-modal-title">Equipment</h2>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Purchase</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleCloseImportModal} variant="text">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Editmodelcomp
            columns={columnDefs}
            CONSTANTS={CONSTANTS}
            modal={modal}
            fields={fields}
            handleSave={handleSave}
            handleUpdate={handleUpdate}
          />
        </div>
      </Modal>

      <Modal
        open={importModalOpen}
        // onClose={handleCloseImportModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.importModal}>

        <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Import</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleCloseImportModal} variant="text">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>  
          <ImportModal importExcel={importExcel} />
        </div>
      </Modal>
      {/* <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title="Lead Management"
                    data={getRows()}
                    columns={[
                        ...columnDefs,
                        {
                            name: "action",
                            label: "Action",
                            options: {
                                filter: true,
                                sort: false,
                            },
                        },
                    ]}
                    options={options}
                />
            </MuiThemeProvider> */}
    </>
  );
}
