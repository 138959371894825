import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import API from "../../services/API";
import { ToastContainer, toast } from "react-toastify";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PageTitle from "../../components/PageTitle/PageTitle";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(2, 4, 3),
  },
}));

const columns = [
  {
    name: "customer_name",
    label: "Customer Name",
    type: "text",
  },
  {
    name: "phone",
    label: "Customer Phone",
    type: "number",
  },
  {
    name: "purchased_date",
    label: "Purchased Date",
    type: "date",
  },
  {
    name: "complaint_date",
    label: "Complaint Date",
    type: "date",
  },
  // {
  //   name: "status",
  //   label: "Status",
  //   type: "select",
  //   options: ["open", "closed"],
  //   default: "open",
  // },
  // {
  //   name: "resolution",
  //   label: "Resolution",
  //   type: "select",
  //   options: ["pending", "solved", "replacement", "return"],
  //   default: "pending",
  // },
  {
    name: "complaint",
    label: "Complaint",
    type: "text",
  },
  {
    name: "note",
    label: "Notes",
    type: "text",
  },
];

const EquipmentSchema = Yup.object().shape({
  customer_name: Yup.string().required("Customer Name is required"),
  phone: Yup.number().required("Customer Phone is required"),
  complaint: Yup.string().required("Complaint is required"),
});

const CONSTANTS = {
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
};

const modal = "Add";

const INITIAL_FIELDS = {
  customer_name: "",
  purchased_date: "",
  complaint: "",
  complaint_date: "",
  notes: [],
};

function TicketPublicForm() {
  const classes = useStyles();
  const [fields, setFields] = useState({ ...INITIAL_FIELDS });
  const [dates, setDates] = useState({
    purchased_date: new Date().toDateString(),
    complaint_date: new Date().toDateString(),
  });

  let formik = useFormik({
    initialValues: fields,
    validationSchema: EquipmentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit({ ...values, ...dates });
    },
  });

  const dateHandler = (value, name) => {
    setDates({ ...dates, [name]: value });
  };

  const handleSubmit = (values) => {
    handleSave({ ...values });
  };

  const handleSave = (values) => {
    API.post("/ticket/add", values)
      .then((response) => {
        // setOpen(false);
        if (response.data.success) {
          toast.success("Added Successfully");
        } else {
          toast.warning(response.data.message);
        }
        setFields({ ...INITIAL_FIELDS });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.paper}
      >
        <Grid item xs={12} sm={10} lg={8} md={8}>
          <PageTitle title="Create New Ticket" />

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className={classes.form}>
              {columns.map((column, i) => {
                if (
                  (column.type === "text" || column.type === "number") &&
                  column.name !== "complaint" &&
                  column.name !== "note"
                ) {
                  return (
                    <Grid item xs={12} sm={6} key={i}>
                      <TextField
                        fullWidth
                        name={column.name}
                        label={column.label}
                        variant="outlined"
                        type={column.type}
                        value={formik.values[column.name]}
                        disabled={modal === CONSTANTS.VIEW}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[column.name] &&
                          Boolean(formik.errors[column.name])
                        }
                        helperText={
                          formik.touched[column.name] &&
                          formik.errors[column.name]
                        }
                      />
                    </Grid>
                  );
                }
                if (column.type === "date") {
                  return (
                    <Grid item xs={12} sm={6} key={i}>
                      <FormControl fullWidth variant="outlined">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <FormControl fullWidth>
                            <DatePicker
                              value={dates[column.name]}
                              onChange={(v) => dateHandler(v, column.name)}
                              variant="outlined"
                              label={column.label}
                              disabled={
                                modal === CONSTANTS.VIEW ||
                                column.name === "equipment_code"
                                  ? true
                                  : false
                              }
                              fullWidth
                              autoOk
                            />
                          </FormControl>
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>
                  );
                }
                if (column.type === "select" && modal === CONSTANTS.EDIT) {
                  return (
                    <Grid item xs={6} sm={6} key={i}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          {column.label}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={column.label}
                          value={formik.values[column.name]}
                          onChange={formik.handleChange(column.name)}
                          type={column.type}
                          defaultValue={column.default}
                          disabled={
                            modal === CONSTANTS.VIEW ||
                            column.name === "equipment_code"
                              ? true
                              : false
                          }
                          error={
                            formik.touched[column.name] &&
                            Boolean(formik.errors[column.name])
                          }
                          helperText={
                            formik.touched[column.name] &&
                            formik.errors[column.name]
                          }
                        >
                          {column?.options?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched[column.name] &&
                          Boolean(formik.errors[column.name]) && (
                            <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                              {formik.errors[column.name]}
                            </p>
                          )}
                      </FormControl>
                    </Grid>
                  );
                }
                if (column.name === "complaint" || column.name === "notes") {
                  return (
                    <Grid item xs={12} sm={12} key={i}>
                      <TextField
                        fullWidth
                        name={column.name}
                        label={column.label}
                        variant="outlined"
                        type={column.type}
                        value={formik.values[column.name]}
                        disabled={modal === CONSTANTS.VIEW}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[column.name] &&
                          Boolean(formik.errors[column.name])
                        }
                        helperText={
                          formik.touched[column.name] &&
                          formik.errors[column.name]
                        }
                        multiline
                        minRows={4}
                      />
                    </Grid>
                  );
                }
                return "";
              })}
              <Grid
                style={{
                  width: "100%",
                  height: "50px",
                  marginTop: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
}

export default TicketPublicForm;
