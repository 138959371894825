import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import API from "../../../services/API";
import formateDate from "../../../utils/GlobalUtil";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(2, 4, 3),
  },
}));

const columns = [
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "timing",
    label: "Timing",
    type: "text",
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "number",
  },
  {
    name: "city",
    label: "City",
    type: "text",
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    options: ["pending", "done"],
  },
  {
    name: "notes",
    label: "Notes",
    type: "text",
  },
];

const EquipmentSchema = Yup.object().shape({
  name: Yup.string().required("Customer Name is required"),
  phone: Yup.number().required("Customer Phone is required"),
});

function EditModelForm(props) {
  const { handleUpdate } = props;
  let { demoId } = useParams();
  const classes = useStyles();

  const [dates, setDates] = useState({
    purchased_date: new Date().toDateString(),
    complaint_date: new Date().toDateString(),
  });

  const [notes, setNotes] = useState([]);
  const [data, setData] = useState({});

  const removeNote = (noteIndex) => {
    let allNotes = notes;
    allNotes = allNotes.filter((value, index) => index !== noteIndex);
    setNotes([...allNotes]);
  };

  const addNote = () => {
    let note = document.getElementById("new_note_value").value;
    setNotes([...notes, { note: note }]);
    document.getElementById("new_note_value").value = "";
  };


  const fetchDemo = async (demoId) => {
    await API.get(`/demo/view/${demoId}`)
       .then((response) => {
         if(response.data.success){
             let res = response.data.data
             setData({...res});
         }
       })
       .catch((error) => {
         console.log(error);
       })
       .finally(() => {
         console.log("finally");
       });
   };


  useEffect(() => {
    setNotes(data.notes);
  }, [data]);

  useEffect(() => {
    fetchDemo(demoId);
  }, [demoId]);


  let formik = useFormik({
    initialValues: { ...data },
    validationSchema: EquipmentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let val = values;
      val.notes = notes;
      handleSubmit({ ...val});
    },
  });

  const dateHandler = (value, name) => {
    setDates({ ...dates, [name]: value });
  };

  const handleSubmit = (values) => {
    handleUpdate({ ...values });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} className={classes.paper}>
          {columns.map((column, i) => {
            if (
              (column.type === "text" || column.type === "number") &&
              column.name !== "complaint" &&
              column.name !== "notes"
            ) {
              return (
                <Grid item xs={12} sm={6} key={i}>
                  <TextField
                    fullWidth
                    name={column.name}
                    label={column.label}
                    variant="outlined"
                    type={column.type}
                    value={formik.values[column.name]}
                    onChange={formik.handleChange}
                  />
                </Grid>
              );
            }
            if (column.type === "date") {
              return (
                <Grid item xs={12} sm={6} key={i}>
                  <FormControl fullWidth variant="outlined">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <FormControl fullWidth>
                        <DatePicker
                          value={dates[column.name]}
                          onChange={(v) => dateHandler(v, column.name)}
                          variant="outlined"
                          label={column.label}
                          fullWidth
                          autoOk
                          color="secondary"
                        />
                      </FormControl>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              );
            }
            if (column.type === "select") {
              return (
                <Grid item xs={6} sm={6} key={i}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      {column.label}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={column.label}
                      value={formik.values[column.name]}
                      onChange={formik.handleChange(column.name)}
                      type={column.type}
                      defaultValue={column.default}
                    >
                      {column?.options?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[column.name] &&
                      Boolean(formik.errors[column.name]) && (
                        <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                          {formik.errors[column.name]}
                        </p>
                      )}
                  </FormControl>
                </Grid>
              );
            }
            if (column.name === "complaint") {
              return (
                <Grid item xs={12} sm={12} key={i}>
                  <TextField
                    fullWidth
                    name={column.name}
                    label={column.label}
                    variant="outlined"
                    type={column.type}
                    value={formik.values[column.name]}
                    onChange={formik.handleChange}
                    multiline
                    minRows={4}
                  />
                </Grid>
              );
            }

            if (column.name === "notes") {
              return (
                <Grid item xs={12} sm={12} key={i}>
                  {notes?.map((nodeObj, nodeIndex) => {
                    return (
                      <>
                        <Grid container spacing={2} key={nodeIndex}>
                          <Grid item xs={10} sm={10} >
                            <TextField
                              fullWidth
                              name={column.name}
                              label={column.label + ' ( '+ ( formateDate(nodeObj?.created_at)??"" )+' )'}
                              variant="outlined"
                              type={column.type}
                              value={nodeObj.note}
                              onChange={formik.handleChange}
                              multiline
                              minRows={2}
                              style={{ marginBottom: "10px" }}
                            />
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <IconButton
                              variant="contained"
                              size="medium"
                              onClick={() => {
                                removeNote(nodeIndex);
                              }}
                              color="secondary"
                              style={{
                                backgroundColor: "#f44336",
                                color: "#fff",
                                marginTop: "30px",
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid container spacing={2}>
                    <Grid item xs={10} sm={10}>
                      <TextField
                        fullWidth
                        name={column.name}
                        label={"Add New Note"}
                        variant="outlined"
                        type={column.type}
                        onChange={formik.handleChange}
                        multiline
                        minRows={2}
                        id="new_note_value"
                        style={{ marginBottom: "10px" }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={() => {
                          addNote();
                        }}
                        color="secondary"
                        style={{ marginTop: "30px" }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
            return ""
          })}
        </Grid>

        <Grid
          style={{
            width: "100%",
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            size="medium"
            color="primary"
          >
            Update
          </Button>
        </Grid>
      </form>
      <ToastContainer />
    </>
  );
}

export default EditModelForm;
