import { Button, Card, CardContent, CardHeader, Divider, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Form from './components/Form'
import API from '../../../services/API'
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    backgroundColor: theme.palette.background.light,
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
}));



const COLUMNS = [
  {
    name: "category_name",
    label: "Categories Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  // {
  //   name: "category_code",
  //   label: "Categories Code",
  //   options: {
  //     filter: true,
  //     sort: true,
  //   },
  // }
]

const INITIAL_FIELDS = {
  category_name: "",
  // category_code: ""
}

const CONSTANTS = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
}


function Categories() {

  const classes = useStyles();
  const [fields, setFields] = React.useState({
    category_name: "",
    // category_code: ""
  });

  const [form, setForm] = React.useState(CONSTANTS.ADD);
  const [data, setData] = React.useState([])

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      console.log(e.data)
      handleDelete(e)
    }
  };

  const handleFormView = (id) => {
    findCategory(id)
    setForm(CONSTANTS.VIEW);
  }

  const handleFormEdit = (id) => {
    findCategory(id)
    setForm(CONSTANTS.EDIT);
  }

  const handleClearForm = () => {
    setFields(Object.create({ category_name: ""}));
    setForm(CONSTANTS.ADD);
  }


  const featchCategories =  () => {
    API.get('/equipment-categories/all').then(response => {
      console.log(response.data.Categories);
      setData(response.data.Categories)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      console.log('finally')
    })
  }

  const handleAdd = (values) => {

    API.post('/equipment-categories/add', { category_name: values.category_name }).then(response => {

      if (response.data.success) {
        toast.dark("Category added successfully");
        setData([response.data.equipmentCategory, ...data])
        setFields({ ...INITIAL_FIELDS });
      } else {
        toast.warning(response.data.message);
      }

    }).catch(error => {
      console.log(error)
    }).finally(() => {
      console.log('finally')
    })


  }

  const handleEdit = (values) => {


    API.put(`/equipment-categories/${values._id}`, values).then(response => {
      if (response.data.success) {
        toast.dark("Category Updated Successfully");
        const updateindex = data.findIndex((item) => item._id === values._id);
        data[updateindex] = values;
        setData([...data]);
      } else {
        toast.warning(response.data.message);
      }
      setFields(Object.create(INITIAL_FIELDS));
      setForm(CONSTANTS.ADD);
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      console.log('finally')
    })



  }

  const handleDelete = (e) => {
    const array = e.data.map(item => data[item.index]._id);
    API.post(`/equipment-categories/deletemany`, { equipmentCategoriesIds: array }).then(response => {
      if (response.data.success) {
        toast.dark("Category Deleted Successfully");
       
      } else {
        toast.warning(response.data.message);
      }

      featchCategories()
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      console.log('finally')
    })
  }

  const getRows = () => {
    return data.map((row, i) => {
      return {
        ...row,
        action: (
          <>
            <Button
              variant="contained"
              onClick={() => { handleFormView(row._id) }}
              color="primary"
              style={{ marginRight: "10px" }}
            >
              <VisibilityIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => { handleFormEdit(row._id) }}
             style={{ margin: "5px",backgroundColor: "#ff9001" }}

            >
              <EditIcon />

            </Button>
          </>
        ),
      };
    });
  };

  const findCategory = (id) => {
    API.get('/equipment-categories/find', { params: { category_id: id } }).then(response => {
      console.log(response.data);
      setFields(response.data.Category);
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      console.log('finally')
    })
  }

  useEffect(() => {

    featchCategories()

  }, [])

  return (
    <>
      <ToastContainer />
      <PageTitle
        title="Equipment Categories"
      />
      <Grid container spacing={3}>
        <Grid item sx={12} md={8}>

           {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
            <MUIDataTable
              title="Equipment Categories"
              data={getRows()}
              columns={[
                ...COLUMNS,
                {
                  name: "action",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                  },
                },
              ]}
              options={options}
            />
          </MuiThemeProvider>

        </Grid>
        <Grid item sx={12} md={4}>

          <Card className={classes.box}>
            <CardHeader title="Equipment Categories" />
            <Divider />
            <CardContent>
              <Form fields={fields}
                COLUMNS={COLUMNS}
                CONSTANTS={CONSTANTS}
                form={form}
                handleClearForm={handleClearForm}
                handleAdd={handleAdd}
                handleEdit={handleEdit}

              />


            </CardContent>
          </Card>

        </Grid>
      </Grid>



    </>
  )
}

export default Categories


