import {
  Button,
  IconButton,
  Grid,
  makeStyles,
  Modal,
  MuiThemeProvider,
} from "@material-ui/core";
// import Visibility from "@material-ui/icons/Visibility";
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
// import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from "react-router-dom";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import MakeAnOrder from "./components/MakeAnOrder";
import EventIcon from "@material-ui/icons/Event";
// import { DateRangePicker, DateRange, DateRangeDelimiter } from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import API from "../../services/API";
import EditIcon from "@material-ui/icons/Edit";
import PublicIcon from "@mui/icons-material/Public";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
// import axios from "axios";
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ImporMonthtModal from "./components/ImportMonthModal";
import ImporFromTodateModal from "./components/ImportFromToDateModal";
import OrderView from "./OrderView";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 700,
    maxWidth: 900,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    height: "96%",
    // overflow: "scroll"
  },
  importModal: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const columns = [
  {
    name: "date",
    label: "Date",
  },
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "phone",
    label: "Phone",
    type: "text",
  },
  {
    name: "qty",
    label: "Quantity",
    type: "number",
  },
  {
    name: "courier_company",
    label: "Courier Company",
    type: "text",
  },
  {
    name: "payment_method",
    label: "Payment Method",
    type: "text",
  },
  {
    name: "status",
    label: "Status",
    type: "text",
  },
];
//       "blue dart",
const TrackingURLs = [
  {
    company: "Delivery",
    url: "https://www.delhivery.com/track/package/",
  },
  {
    company: "Amazon surface",
    url: "https://parcelsapp.com/en/tracking/",
  },
  {
    company: "Amazon",
    url: "https://parcelsapp.com/en/tracking/",
  },
  {
    company: "Flipkart",
    url: "https://www.ship24.com/tracking?p=",
  },
  {
    company: "Ecome express",
    url: "https://ecomexpress.in/tracking/?track_id=",
  },
  {
    company: "DTDC",
    url: "https://www.dtdc.com/track",
    url_edit: false,
  },
  {
    company: "Kerry indev",
    url: "hhttps://kerryindevexpress.com/track.aspx",
    url_edit: false,
  },
  {
    company: "Xpress bees",
    url: "https://www.xpressbees.com/track/",
    url_edit: false,
  },
  {
    company: "Maruti",
    url: "http://trackcourier.io/track-and-trace/maruti-courier/",
  },
];
const CONSTANTS = {
  ADD: "ADD",
  VIEW: "VIEW",
};

const copyToClipboard = (text) => {
  if (typeof navigator.clipboard !== "undefined") {
    navigator.clipboard.writeText(text).then(function() {
      console.log("Copied!");
    }, function() {
      console.log("Error copying to clipboard");
    });
  } else {
    // Fallback for older browsers
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed";
    textarea.style.top = 0;
    textarea.style.left = 0;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }
};

const DataFilter = (props) => {
  return {
    ...props,
    date: new Date(props.createdAt).toDateString(),
    color: props.order_items[0]?.product._id,
    invoiceNumber: props.invoice_number,
    qty: props.order_items[0].quantity,
    sku: props.order_items[0].product_sku,
    units: props.order_items[0].product_unit,
    name: props.order_items[0].product_name,
    price: props.order_items[0].price,
    payment_method: props.paymentInfo.payment_method,
    billing_customer_name: props.shippingInfo.billing_customer_name || "user",
    phone: props.user_info.billing_phone,
    email: props.user_info.email,
    pincode: props.shippingInfo.pincode,
    address: props.shippingInfo.address,
  };
};

function Orders() {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState([]);
  // const [date, setDate] = useState(new Date().toDateString())
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  // const [emailSend, setEmailSend] = useState(false);
  const [mode, setMode] = useState(CONSTANTS.ADD);

  const [fields, setFields] = useState({});
  // const [value, setValue] = React.useState([null, null]);
  const [dateMonthModalOpen, setDateMonthModalOpen] = useState(false);
  const [dateFromToModalOpen, setdateFromToModalOpen] = useState(false);

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      handleDelete(e);
    },
    customToolbar: () => {
      return (
        <>
          <IconButton onClick={handleMonthOpenImportModal}>
            <CalendarTodayIcon />
          </IconButton>
          <IconButton onClick={handleMonthOpenImportModal}>
            <CalendarTodayIcon />
          </IconButton>
          <IconButton onClick={handleFromToOpenImportModal}>
            <EventIcon />
          </IconButton>
        </>
      );
    },
  };
  const handleDelete = (e) => {
    let array = [];
    e.data.map((item) => {
      array.push(data[item.index]._id);
      return item;
    });
    API.post(`/order/deletemany`, { orderids: array })
      .then((response) => {
        if (response.data.code === 200) {
          toast.dark("Order deleted successfully");
        } else {
          toast.warning(response.data.message);
        }
        fetchAllOrder();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleClose = () => {
    setMode(CONSTANTS.ADD);
    setOpen(false);
    setEdit(false);
    setDateMonthModalOpen(false);
    setdateFromToModalOpen(false);
  };

  const handleOpen = (isEdit = false) => {
    if (isEdit === true) {
      setEdit(true);
    } else {
      setOpen(true);
    }
  };

  const handleMakeAnOrder = async (fields) => {
    await API.post("/order/create", { ...fields }).then((res) => {
      if (res.data.code === 200) {
        toast.success(res.data.message);
        // setEmailSend(true)
        // sendEmailTouser(fields.shippingInfo.email, fields.shippingInfo.billing_phone, fields)
        setData([res.data.data, ...data]);
        handleClose();
      } else {
        toast.warning(res.data.message);
      }
    });
  };

  const handleUpdateAnOrder = async (fields) => {
    await API.patch("/order/" + fields._id, { ...fields }).then((res) => {
      if (res.data.code === 200) {
        toast.success(res.data.message);
        handleClose();
        fetchAllOrder();
      } else {
        toast.warning(res.data.message);
      }
    });
  };

  // const handleView = (i) => {
  //   setMode(CONSTANTS.VIEW);
  //   setFields(data[i]);
  //   handleOpen(true);
  // };
  const handleMonthOpenImportModal = () => {
    setDateMonthModalOpen(true);
  };
  const handleFromToOpenImportModal = () => {
    setdateFromToModalOpen(true);
  };
  const handleMonthCloseImportModal = () => {
    setDateMonthModalOpen(false);
  };

  const getRows = () => {
    return data.map((row, i) => {
      return {
        ...row.user_info,
        ...row.shipping_info,
        ...row,
        date: new Date(row.createdAt).toDateString(),

        qty: row.order_items[0].quantity,
        price: row.order_items[0].price,
        payment_method: row.shipping_info.payment_method,
        action: (
          <>
            {/* <Button
              variant="contained"
              onClick={() => {
                handleView(i);
              }}
              color="primary"
              style={{ marginRight: "10px", marginBottom: "10px" }}
            >
              <Visibility />
            </Button> */}
            {/* <a href={"/app/orders/" + row._id}> */}
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "5px", backgroundColor: "#FF9001" }}
              onClick={() => {
                history.push({
                  pathname: "/app/orders/" + row._id,
                });
              }}
            >
              <EditIcon />
            </Button>
            {/* </a> */}
            <Button
              variant="contained"
              onClick={() => {
                if (
                  row.shipping_info.doc_no === null ||
                  row.shipping_info.courier_company === null
                ) {
                  toast.warning("Doc Id Not Found");
                  return 0;
                }
                // Get the tracking URL for the company.
                const trackingUrl = TrackingURLs.find(
                  (url) => url.company === row.shipping_info.courier_company,
                );

                // If the tracking URL is not found, return.
                if (!trackingUrl) {
                  return;
                }

                // Redirect to the tracking URL with the tracking ID.
                if (trackingUrl.hasOwnProperty("url_edit")) {
                  copyToClipboard(row.shipping_info.doc_no);
                  toast.success("Tracking ID copied to clipboard. You can now paste it into the appropriate field");
                  setTimeout(() => {
                    toast.success("Redirecting you to the tracking URL..");
                  }, 1000);
                  setTimeout(() => {
                    window.open(trackingUrl.url, "_blank");
                  }, 3000);
                } else {
                  window.open(trackingUrl.url + row.shipping_info.doc_no, "_blank");
                }
              }}
              color="secondary"
            >
              <GpsFixedIcon />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push({
                  pathname: "/pub/view-order/" + row.user_info.phone,
                });
              }}
              style={{ margin: "5px", backgroundColor: "#c2ff01" }}
            >
              <PublicIcon />
            </Button>
          </>
        ),
      };
    });
  };

  let fetchAllOrder = async () => {
    await API.get("/order/all")
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let UpdateMonthDate = (get_date, from_Date, to_date) => {
    let condition = get_date
      ? { onlyMonth: get_date }
      : { fromDate: from_Date, toDate: to_date };

    API.post("/order/filter", condition)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.orders);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAllOrder();
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        open={dateMonthModalOpen}
        // onClose={handleMonthCloseImportModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.importModal}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">Filter By Month </h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleMonthCloseImportModal} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

          <ImporMonthtModal
            handleClose={handleClose}
            UpdateMonthDate={UpdateMonthDate}
          />
        </div>
      </Modal>
      <Modal
        open={dateFromToModalOpen}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.importModal}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Filter</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <ImporFromTodateModal
            handleClose={handleClose}
            UpdateMonthDate={UpdateMonthDate}
          />
        </div>
      </Modal>
      <PageTitle
        title="Orders"
        button={
          <Button
            variant="contained"
            onClick={() => {
              handleOpen();
            }}
            color="primary"
            style={{ marginRight: "10px" }}
          >
            Make An Order
          </Button>
        }
      />
      {/* <MuiThemeProvider theme={getMuiTheme()}> */}
      <MuiThemeProvider>
        <MUIDataTable
          title="Completed Product Inventory"
          data={getRows()}
          columns={[
            ...columns,
            {
              name: "action",
              label: "Action",
              options: {
                filter: true,
                sort: false,
              },
            },
          ]}
          options={options}
        />
      </MuiThemeProvider>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">Make An Order</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

          <MakeAnOrder
            fields={fields}
            handleMakeAnOrder={handleMakeAnOrder}
            mode={mode}
          />
        </div>
      </Modal>
      <Modal
        open={edit}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">Edit An Order</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

          <OrderView
            fields={fields}
            handleUpdateAnOrder={handleUpdateAnOrder}
            mode={mode}
          />
        </div>
      </Modal>
    </>
  );
}

export default Orders;
