import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'

const COLUMNS = [
    {
        name: 'return_qty',
        label: 'Return Quantity',
        type: 'number'
    },
    {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: ['completed', 'failed'],
    },
    {
        name: 'remarks',
        label: 'Remarks',
        type: 'textarea',
    },
]



function StatusModal(props) {

    const { updateRequest,statusFields } = props

    const [fields, setFields] = useState({ ...statusFields })
    const onChange = (e) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }


    return (
        <Grid container spacing={2} style={{ padding: '10px', maxWidth: "400px" }}  >
            {COLUMNS.map((column) => {
                if (column.type === 'text' || column.type === 'number' || column.type === 'textarea') {
                    return (
                        <Grid item xs={12} >
                            <TextField
                                fullWidth
                                name={column.name}
                                label={column.label}
                                variant="outlined"
                                type={column.type}
                                value={fields[column.name]}
                                disabled={column.disabled}
                                multiline={column.type === 'textarea' ? true : false}
                                minRows={column.type === 'textarea' ? 3 : 1}
                                onChange={onChange}

                            />
                        </Grid>
                    )
                }
                if (column.type === 'select') {
                    return (
                        <Grid item xs={12} >
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={column.label}
                                    name={column.name}
                                    value={fields[column.name]}
                                    onChange={onChange}
                                    type={column.type}
                                    disabled={column.disabled}

                                >
                                    {
                                        column.options.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                    )
                }

            })}

            <Grid item xs={12} >
                <Button
                    variant="contained"
                    onClick={() => updateRequest(fields)}
                    size="medium"
                    color="secondary" >
                    update
                </Button>
            </Grid>
        </Grid>
    )
}

export default StatusModal