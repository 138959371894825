import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import { useFormik } from "formik";

function ModalForm(props) {

    const { columns, CONSTANTS, mode, fields } = props


    let formik = useFormik({
        initialValues: fields,
    });

    return (
        <>
            <Grid container spacing={2}>
                {columns.map((column) => {
                    if (column.type === "text" || column.type === "number" || column.type === "date") {
                        console.log("formik.values[column.name]", formik.values[column.name])
                        return (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name={column.name}
                                    label={column.label}
                                    disabled={mode === CONSTANTS.VIEW ? true : false}
                                    variant="outlined"
                                    value={formik.values[column.name]}
                                    onChange={formik.handleChange}
                                    error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                    helperText={formik.touched[column.name] && formik.errors[column.name]}
                                />
                            </Grid>
                        )

                    }
                    if (column.type === "select") {
                        return (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={column.label}
                                        value={formik.values[column.name]}
                                        onChange={formik.handleChange(column.name)}
                                        type={column.type}
                                        disabled={mode === CONSTANTS.VIEW}
                                        error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                        helperText={formik.touched[column.name] && formik.errors[column.name]}


                                    >
                                        {
                                            column.select.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                                        }

                                    </Select>
                                    {
                                        (formik.touched[column.name] && Boolean(formik.errors[column.name])) && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{formik.errors[column.name]}</p>
                                    }

                                </FormControl>
                            </Grid>
                        )

                    }
                    return "";
                }


                )}
            </Grid>
        </>
    )
}

export default ModalForm