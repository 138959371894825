import React, { useLayoutEffect } from "react";
// import {
//     Chip,
// } from '@material-ui/core';
import API from "../../../../services/API";
// import MaterialTable from 'material-table'

export default function Editable({ handleUpdate, handleDelete, handleAdd }) {
  const { useState } = React;

  const [columns, setColumns] = useState([
    // { title: 'Name', field: 'na_me' },
    // { title: 'Surname', field: 'surname', initialEditValue: 'initial edit value' },
    // { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
    // {
    //     title: 'Birth Place',
    //     field: 'birthCity',
    //     lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
    // },
  ]);

  const [data, setData] = useState([]);
  console.log(typeof columns);
  console.log(typeof data);

  let fetchData = () => {
    API.get("/lead/all").then((res) => {
      console.log("res.data--callll");
      if (res.data.success) {
        let keyArryBool = ["call", "email", "lead"];
        // let keyArryText = ["call", "email", "lead"];
        // Not Added.

        // let yesColor = `${<Chip

        //     label={"key"}
        //     clickable
        //     color={"primary"}
        // />},`
        // let noColor = `${<Chip

        //     label={"No"}
        //     clickable
        //     color={"primary"}
        // />},`

        let setColumnNewEditableArray = [];
        for (let [key, value] of Object.entries(res.data.result[0])) {
          console.log(typeof key);
          console.log(typeof value);

          if ("_id" !== key) {
            setData([...res.data.result]);
          }

          if ("_id" !== key) {
            setColumnNewEditableArray.push({
              title: key.replace("_", " "),

              field: key,
              // render: row => <Chip

              //     label={key}
              //     clickable
              //     color={row.email === "yes" ? "primary" : "secondary"}
              // />,
              lookup: keyArryBool.includes(key) ? { yes: "Yes", no: "No" } : "",
              type:
                key === "created_at" || key === "followup_date"
                  ? "datetime"
                  : key === "contact_number"
                  ? "numeric"
                  : "text",
            });
          }
        }

        setColumns([...setColumnNewEditableArray]);
      }
    });
  };
  useLayoutEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* <MaterialTable
                title="Data Info"
                columns={columns}
                options={{
                    exportButton: true,
                    sorting: true,
                }}
                data={data}
                editable={{
                    // onRowAdd: newData =>
                    //     new Promise((resolve, reject) => {
                    //         setTimeout(() => {
                    //             setData([...data, newData]);
                    //             // handleAdd(newData);
                    //             resolve();
                    //         }, 1000)
                    //     }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;

                                dataUpdate[index] = newData;
                                handleUpdate(newData)
                                setData([...dataUpdate]);

                                resolve();
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;

                                handleDelete(dataDelete[index]?.email_id)
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);

                                resolve()
                            }, 1000)
                        }),
                }}
            /> */}
    </>
  );
}
