import {
  Grid,
  makeStyles,
  Modal,
  MuiThemeProvider,
  IconButton,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
// import { useHistory } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import OrderModal from "./components/OrderModal";
import API from "../../../services/API";
import { Button } from "@mui/material";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 340,
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

const COMPLETED_TABLE_HEADER = [
  {
    name: "product_name",
    label: "Equipment Name",
    type: "text",
  },
  {
    name: "product_code",
    label: "Equipment Code",
    type: "text",
  },
  {
    name: "product_sku",
    label: "SKU",
    type: "text",
  },
  {
    name: "product_qty",
    label: "Quantity",
    type: "number",
  },

  {
    name: "product_mrp",
    label: "MRP",
    type: "number",
  },
];

const ASSEMBLE_TABLE_HEADER = [
  {
    name: "assemble_name",
    label: "Equipment Name",
    type: "text",
  },
  {
    name: "assemble_cost",
    label: "Equipment Code",
    type: "text",
  },
  {
    name: "assemble_sku",
    label: "SKU",
    type: "text",
  },
  {
    name: "assemble_qty",
    label: "Quantity",
    type: "number",
  },

  {
    name: "assemble_unit",
    label: "Unit",
    type: "number",
  },
  {
    name: "assemble_cost",
    label: "Cost",
    type: "number",
  },
];

const columns = [
  {
    name: "equipment_name",
    label: "Equipment Name",
    type: "text",
  },
  {
    name: "equipment_code",
    label: "Equipment Code",
    type: "text",
  },
  {
    name: "equipment_sku",
    label: "SKU",
    type: "text",
  },
  // {
  //   name: "equipment_qty",
  //   label: "Quantity",
  //   type: "number",
  // },
  {
    name: "equipment_unit",
    label: "Unit",
    type: "select",
    options: ["kg", "piece", "litre", "ml", "other"],
  },
  {
    name: "equipment_cost",
    label: "Cost",
    type: "number",
  },
  {
    name: "equipment_category_name",
    label: "Categories",
    type: "select",
  },
];

const INITIAL_FIELDS = {
  product_name: "",
  qty: "",
  price: "",
  mrp: "",
  sku: "",
};

function Inventory() {
  const classes = useStyles();
  // const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [fields, setFields] = React.useState({ ...INITIAL_FIELDS });

  const [Products, setProducts] = React.useState([]);
  const [Assemble, setAssemble] = React.useState([]);
  const [rawMaterial, setRawMaterial] = React.useState([]);

  const handleOpen = (i) => {
    setFields({ ...INITIAL_FIELDS, qty: 1 });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    API.get("/assemble/all")
      .then((response) => {
        console.log(response.data.assemble);
        setAssemble(response.data.assemble);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const featch = () => {
    API.get("/product/all")
      .then((response) => {
        console.log(response?.data);
        setProducts(response?.data.Products);
      })
      .catch((err) => {
        console.log(err);
      });

    API.get("/equipment/all")
      .then((response) => {
        console.log(response.data);
        let result = response?.data?.equipment.map((item) => ({
          ...item,
          equipment_category_name:
            item.equipment_category?.category_name || "Deleted Category",
        }));
        setRawMaterial([...result]);
      })
      .catch((err) => {
        console.log(err);
      });

    API.get("/assemble/all")
      .then((response) => {
        console.log(response.data);
        setAssemble(response.data.assemble);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    featch();
  }, []);
  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">Make An Order</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>
          <OrderModal fields={fields} />
        </div>
      </Modal>
      <PageTitle
        title="Completed Product Inventory"
        button={
          <Button
            variant="contained"
            onClick={handleOpen}
            size="medium"
            color="primary"
            startIcon={<Add />}
          >
            Add Product
          </Button>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <MuiThemeProvider theme={getMuiTheme()}> */}
          <MuiThemeProvider>
            <MUIDataTable
              title="Completed Product Inventory"
              data={Products}
              columns={[
                ...COMPLETED_TABLE_HEADER,
                {
                  name: "action",
                  label: "Action",
                  options: {
                    filter: true,
                    sort: false,
                  },
                },
              ]}
              options={{ selectableRows: false }}
            />
          </MuiThemeProvider>
        </Grid>

        <Grid item xs={12}>
          <PageTitle title="Assemble Inventory" />
        </Grid>
        <Grid item xs={12}>
          {/* <MuiThemeProvider theme={getMuiTheme()}> */}
          <MuiThemeProvider>
            <MUIDataTable
              title="Assemble Inventory"
              data={Assemble}
              columns={[...ASSEMBLE_TABLE_HEADER]}
            />
          </MuiThemeProvider>
        </Grid>
        <Grid item xs={12}>
          <PageTitle title="Raw Material Inventory" />
        </Grid>
        <Grid item xs={12}>
          {/* <MuiThemeProvider theme={getMuiTheme()}> */}
          <MuiThemeProvider>
            <MUIDataTable
              title="Raw Material Inventory"
              data={rawMaterial}
              columns={[...columns]}
              options={{ selectableRows: false }}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}

export default Inventory;
