import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";



// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const vendorSchema = Yup.object().shape({
    vendor_name: Yup.string()
        .min(3, "Vendor Name must be 3 characters at minimum")
        .required("Vendor Name is required"),

    contact_number: Yup.string()
        // .matches(phoneRegExp, 'Phone Number is not valid')
        // .min(10, "Contact must be 10 characters at minimum")
        .required("Contact is required"),

    // alternative_contact_number: Yup.string()
    //     .matches(phoneRegExp, 'Alternative Phone Number is not valid')
    //     .min(10, "Alternative Contact must be 10 characters at minimum")
    //     .required("Alternative Contact is required"),

    vendor_type: Yup.string()
        .required("Vendor type is required"),

    address: Yup.string()
        .min(3, "Address Name must be 6 characters at minimum")
        .required("Address is required"),

    email: Yup.string()
        .email("Invalid email address format")
        .required("Email is required"),

    gst_number: Yup.string()
        .min(3, "GST Number must be 6 characters at minimum")
    //     .required("GST Number is required"),

});

function ModalForm(props) {

    const { columns, CONSTANTS, modal, fields, handleSave, handleUpdate } = props

    let formik = useFormik({
        initialValues: fields,
        validationSchema: vendorSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        },
    });


    const handleSubmit = (values) => {
        if (modal === CONSTANTS.ADD_VENDOR) {
            handleSave(values)
        } else if (modal === CONSTANTS.EDIT_VENDOR) {
            handleUpdate(values)
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                {columns.map((column) => {
                    if (column.type === "text") {
                        return (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name={column.name}
                                    label={column.label}
                                    disabled={modal === CONSTANTS.VIEW_VENDOR ? true : false}
                                    variant="outlined"
                                    value={formik.values[column.name]}
                                    onChange={formik.handleChange}
                                    error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                    helperText={formik.touched[column.name] && formik.errors[column.name]}
                                />
                            </Grid>
                        )

                    }
                    if (column.type === "select") {
                        return (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={column.label}
                                        value={formik.values[column.name]}
                                        onChange={formik.handleChange(column.name)}
                                        type={column.type}
                                        disabled={modal === CONSTANTS.VIEW_VENDOR}
                                        error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                        helperText={formik.touched[column.name] && formik.errors[column.name]}


                                    >
                                        {
                                            column.select.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                                        }

                                    </Select>
                                    {
                                        (formik.touched[column.name] && Boolean(formik.errors[column.name])) && <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{formik.errors[column.name]}</p>
                                    }
                                    
                                </FormControl>
                            </Grid>
                        )

                    }
                    return "";
                }


                )}
            </Grid>

            <Grid
                style={{
                    width: "100%",
                    marginTop: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {modal !== CONSTANTS.VIEW_VENDOR ? (
                    <Button
                        variant="contained"
                        type='submit'
                        size="medium"
                        color="primary"
                    >
                        {modal}
                    </Button>
                ) : null}
            </Grid>
        </form>
    )
}

export default ModalForm