import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import PageTitle from "../../components/PageTitle/PageTitle";
import API from "../../services/API";
import formatDate from "../../utils/GlobalUtil"
import { ToastContainer, toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(2, 4, 3),
    height: "100vh",
  },
}));

const EquipmentSchema = Yup.object().shape({
  name: Yup.string().required("Customer Name is required"),
});

function FeedbackOrderView(props) {
  const {
    CONSTANTS = {
      ADD: "Add",
      EDIT: "Edit",
      VIEW: "View",
    },
    modal = "Add",
  } = props;

  //   const [categories, setCategories] = useState([]);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { phone } = useParams();

  let formik = useFormik({
    initialValues: data,
    validationSchema: EquipmentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit({ ...values });
    },
  });
  const handleSubmit = (row) => {
    if (row) {
      var a = document.getElementById(row._id).value;

      row.feedback_notes = [...row.feedback_notes, { note: a }];
      handleUpdate(row);
      document.getElementById(row._id).value = "";
    }
  };
  const fetchOrder = () => {
    API.get(`/order/all?phone=${phone}`)
      .then((response) => {
        if (response.data.code === 200 && response.data.data.length > 0) {
          setData([...response.data.data]);
        } else {
          toast.warning("Data not Found.");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleUpdate = (values) => {
    API.patch(`/order/${values._id}`, values)
      .then((response) => {
        if (response.data.code===200) {
          toast.success("New Note Added Successfully");
          fetchOrder();
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  useEffect(() => {
    fetchOrder(phone);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  return (
    <Grid container justifyContent="center" className={classes.paper}>
      <Grid item xs={12} sm={10} lg={8} md={8}>
        <PageTitle title="View Order" />
        <form onSubmit={formik.handleSubmit}>
          <br />

          <div>
            {data?.map((row) => {
              return (
                <Accordion style={{ 
                  marginBottom: "20px",
                  backgroundColor: "#343A44",
                  color: "white",
                  padding: "10px"
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />} 
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={6}>
                        Name : {row.user_info.name}
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        Phone : {row.user_info.phone}
                      </Grid>
                      <Grid item xs={4} sm={6}>
                        Status : {row.status}
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        Delivered Date : {row.shipping_info.delivered_date}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        {row?.feedback_notes?.map((noteObj) => {
                          return (
                            <>
                              <hr />
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ paddingBottom: "20px" }}
                              >
                                Note : {noteObj.note}
                                <br />
                                Time : {formatDate(noteObj.created_at)}
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          placeholder="Enter New Note"
                          height="15px"
                          id={row._id}
                          fullWidth
                          multiline
                          minRows={5}
                        ></TextField>
                        <Grid
                          style={{
                            width: "100%",
                            marginTop: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => handleSubmit(row)}
                            variant="contained"
                            type="submit"
                            size="medium"
                            color="primary"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>

          {modal !== CONSTANTS.VIEW && (
            <Grid
              style={{
                width: "100%",
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Button
                variant="contained"
                type="submit"
                size="medium"
                color="primary"
              >
                {modal === CONSTANTS.ADD ? "Submit " : "Edit"}
              </Button> */}
            </Grid>
          )}
        </form>
        <ToastContainer />
      </Grid>
    </Grid>
  );
}

export default FeedbackOrderView;
