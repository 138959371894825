import React, { useState, useEffect } from "react";
import { Button, Modal, Grid, IconButton } from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import ModalForm from "./components/ModalForm";
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import API from "../../../services/API";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 340,
    maxWidth: 600,
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    maxHeight: "90vh",
    overflowY: "scroll",
  },
}));

const columns = [
  {
    name: "equipment_name",
    label: "Equipment Name",
    type: "text",
  },

  {
    name: "purchase_qty",
    label: "Quantity",
    type: "number",
  },
  {
    name: "purchase_unit",
    label: "Unit",
    type: "select",
    options: ["kg", "piece", "litre", "ml", "other"],
  },
  {
    name: "purchase_cost",
    label: "Cost",
    type: "number",
  },
  {
    name: "vendor_name",
    label: "Vendor",
    type: "select",
    options: ["vendor1", "vendor2", "vendor3"],
  },
  {
    name: "purchase_date",
    label: "Purchase Date",
    type: "date",
  },
];

const CONSTANTS = {
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
};

const INITIAL_FIELDS = {
  equipment_id: "",
  purchase_qty: "",
  purchase_unit: "",
  purchase_cost: "",
  vendor_id: "",
  purchase_remark: "",
  purchase_date: new Date().toDateString(),
};

function Purchase() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [modal, setModal] = useState(CONSTANTS.ADD);
  const [fields, setFields] = useState({
    equipment_id: "",
    purchase_qty: "",
    purchase_unit: "",
    purchase_cost: "",
    vendor_id: "",
    purchase_remark: "",
    purchase_date: new Date().toDateString(),
  });
  const [equipment, setEquipment] = useState([]);
  const [vendor, setVendor] = useState([]);

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      handleDelete(e);
    },
  };

  const handleClose = () => {
    setFields({ ...INITIAL_FIELDS, id: data.length, files: [] });
    setModal(CONSTANTS.ADD);
    setOpen(false);
  };

  const handleOpen = () => {
    setModal(CONSTANTS.ADD);
    setOpen(true);
  };

  const handleView = (index) => {
    setFields(data[index]);
    setModal(CONSTANTS.VIEW);
    setOpen(true);
  };

  const handleEdit = (index) => {
    setFields(data[index]);
    setModal(CONSTANTS.EDIT);
    setOpen(true);
  };
  const handleSave = (values) => {
    setOpen(false);
    console.log(values);
    const formData = new FormData();

    Object.keys(values).forEach(function (key) {
      if (key === "files") {
        values.files.forEach(function (file) {
          formData.append("files", file);
        });
      } else {
        formData.append(key, values[key]);
      }
    });

    API.post("/purchasc/add", formData)
      .then((response) => {
        if (response.data.success) {
          toast.success("Purchasc Added Successfully");
          let result = [response.data.Purchase].map((item) => ({
            ...item,
            equipment_name: item.equipment_id.equipment_name,
            equipment: item.equipment_id,
            equipment_id: item.equipment_id._id,
            vendor: item.vendor_id,
            vendor_name: item.vendor_id.vendor_name,
            vendor_id: item.vendor_id._id,
          }));

          setData([...result, ...data]);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setFields({ ...INITIAL_FIELDS });
      });

    setData([...data, values]);
    setFields({ ...INITIAL_FIELDS, id: data.length, files: [] });
  };

  const handleUpdate = (values) => {
    setOpen(false);
    console.log(values);

    API.put(`/purchasc/${values._id}`, values)
      .then((response) => {
        if (response.data.success) {
          toast.success("purchasc Updated Successfully");
          console.log(response.data.Purchase);
          let result = [response.data.Purchase].map((item) => ({
            ...item,
            equipment: item.equipment_id,
            equipment_id: item.equipment_id._id,
            equipment_name: item.equipment_id.equipment_name,
            vendor: item.vendor_id,
            vendor_name: item.vendor_id.vendor_name,
            vendor_id: item.vendor_id._id,
          }));
          const updateindex = data.findIndex((item) => item._id === values._id);
          data[updateindex] = result[0];
          setData([...data]);
        } else {
          toast.warning(response.data.message);
        }

        setFields({ ...INITIAL_FIELDS });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setFields({ ...INITIAL_FIELDS, id: data.length, files: [] });
        console.log("finally");
      });
  };

  const handleDelete = (e) => {
    let array = [];
    e.data.map((item) => {
      array.push(data[item.index]._id);
      return item
    });
    API.post(`/purchasc/deletemany`, { Purchascids: array })
      .then((response) => {
        if (response.data.success) {
          toast.success("purchasc Deleted Successfully");
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const getRows = () => {
    console.log(data);
    return data.map((row, i) => {
      return {
        ...row,
        vendor_name: row.vendor_name || "Deleted Vendor",
        purchase_date: new Date(row.purchase_date).toDateString(),
        action: (
          <>
            <Button
              variant="contained"
              onClick={() => {
                handleView(i);
              }}
              color="primary"
              style={{ marginRight: "10px" }}
            >
              <VisibilityIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleEdit(i);
              }}
              style={{ margin: "5px", backgroundColor: "#ff9001" }}
            >
              <EditIcon />
            </Button>
          </>
        ),
      };
    });
  };
  useEffect(() => {
    API.get("/purchasc/all")
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.Purchase);

          let result = response.data.Purchase.map((item) => ({
            ...item,
            equipment: item.equipment_id,
            equipment_id: item.equipment_id?._id,
            equipment_name:
              item.equipment_id?.equipment_name || "Deleted Equipment",
            vendor: item.vendor_id,
            vendor_name: item.vendor_id?.vendor_name,
            vendor_id: item.vendor_id?._id,
          }));
          setData(result);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });

    API.get("/equipment/all")
      .then((response) => {
        if (response.data.success) {
          setEquipment(response.data.equipment);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });

    API.get("/vendor/all")
      .then((response) => {
        if (response.data.success) {
          setVendor(response.data.vendors);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <ToastContainer />

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2>Purchase</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

          <ModalForm
            columns={columns}
            CONSTANTS={CONSTANTS}
            modal={modal}
            fields={fields}
            handleSave={handleSave}
            equipment={equipment}
            vendor={vendor}
            handleUpdate={handleUpdate}
          />
        </div>
      </Modal>

      <PageTitle
        title="Purchase"
        button={
          <Button
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            Add Purchase
          </Button>
        }
      />

      <MuiThemeProvider>
        <MUIDataTable
          title="Purchase List"
          data={getRows()}
          columns={[
            ...columns,
            {
              name: "action",
              label: "Action",
              options: {
                filter: true,
                sort: false,
              },
            },
          ]}
          options={options}
        />
      </MuiThemeProvider>
    </>
  );
}

export default Purchase;
