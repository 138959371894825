import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import { Delete, Add } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import API from "../../../services/API";
import Autocomplete from "@mui/material/Autocomplete";
const steps = ["Products", "Customer Info", "Payment / Shipping Info"];

const CustomerInfoColumns = [
  {
    name: "name",
    label: "Customer Name",
    type: "text",
    sm: 6,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    sm: 6,
  },
  {
    name: "phone",
    label: "Phone",
    type: "number",
    sm: 6,
  },
  {
    name: "city",
    label: "City",
    type: "text",
    sm: 6,
  },
  {
    name: "state",
    label: "State",
    type: "text",
    sm: 6,
  },
  {
    name: "pincode",
    label: "Pincode",
    type: "text",
    sm: 6,
  },
  {
    name: "address",
    label: "Address",
    type: "textarea",
    sm: 12,
  },
];

const PaymentInfoColumns = [
  {
    name: "courier_company",
    label: "Courier Company",
    type: "select",
    options: [
      "Ecome express",
      "Xpress bees",
      "Kerry indev",
      "DTDC",
      "Delivery",
      "Maruti",
      "blue dart",
      "Amazon surface",
      "Amazon",
      "Flipkart",
    ],
    sm: 6,
  },
  
  {
    name: "payment_method",
    label: "Payment Method",
    type: "select",
    options: ["cod", "prepaid"],
    sm: 6,
  },
  {
    name: "advance_payment",
    label: "Advance Payment",
    type: "number",
    sm: 6,
  },
  {
    name: "amount_paid",
    label: "Amount Paid",
    type: "number",
    sm: 6,
  },
  {
    name: "doc_no",
    label: "Doc No",
    type: "text",
    sm: 6,
  },
  {
    name: "pickup_date",
    label: "Pickup Date",
    type: "date",
    sm: 6,
  },
  {
    name: "delivered_date",
    label: "Delivered Date",
    type: "date",
    sm: 6,
  },
  {
    name: "status",
    label: "Order Status",
    type: "select",
    options: [
      "Processing",
      "Delivered",
      "RTO Delivered",
      "Replacement",
      "Refunded",
    ],
    sm: 6,
  },
  {
    name: "refund_date",
    label: "Refund Date",
    type: "date",
    sm: 6,
  },
  {
    name: "replacement_date",
    label: "Replacement Date",
    type: "date",
    sm: 6,
  },
  {
    name: "note",
    label: "Note",
    type: "textarea",
    sm: 12,
  },
];

const productSchema = Yup.object().shape({
  products: Yup.array()
    .of(
      Yup.object().shape({
        color: Yup.string().required("Name is required"),
        quantity: Yup.number().required("Quantity is required"),
        price: Yup.number().required("Price is required"),
      }),
    )
    .min(1, "You need at least one product"),
});

const paymentSchema = Yup.object().shape({
  payment_method: Yup.string().required("Payment Method is required"),
  courier_company: Yup.string().required("Courier Company is required"),
  note: Yup.string().required("Note is required"),
  amount_paid: Yup.number().required("Amount Paid is required"),
});

const productList = [
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Grey",
    value: "grey",
  },
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Yellow",
    value: "yellow",
  },
];

// const initialProducts = {
//   products: [
//     {
//       color: "",
//       quantity: "",
//       price: "",
//     },
//   ],
// };

// const initialUserInfo = {
//   name: "",
//   email: "",
//   phone: "",
//   state: "",
//   city: "",
//   pincode: "",
//   address: "",
// };

function OrderView(props) {
  const { handleUpdateAnOrder, fields } = props;

  const initialPaymentInfo = {
    pickup_date: fields.shipping_info.pickup_date,
    delivered_date: fields.shipping_info.delivered_date,
    doc_no: fields.shipping_info.doc_no,
    payment_method: fields.shipping_info.payment_method,
    courier_company: fields.shipping_info.courier_company,
    note: fields.note,
    amount_paid: fields.amount_paid,
    advance_payment: fields.advance_payment,
    status: fields.status,
    replacement_date: fields.shipping_info.replacement_date,
    refund_date: fields.shipping_info.refund_date,
  };

  // const findTotalPrice = (products) => {
  //   const findProduct = products
  //     .map((product) => product.quantity * product.price)
  //     .reduce((partialSum, a) => partialSum + a, 0);
  //   return Number(findProduct);
  // };

  let productForm = useFormik({
    validationSchema: productSchema,
    initialValues: {
      // ...initialProducts,
      products: fields.order_items,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      validatedAndSwitched();
    },
  });

  const UserInfoSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Customer Name must be 3 characters at minimum")
      .required("customer Name is required"),

    phone: Yup.number()
      .min(10, "Minmum 10 number are required.")
      .required("customer Phone 10 number is required"),

    email: Yup.string().required("Email is required"),

    address: Yup.string().required("Address line 1 is required"),

    city: Yup.string().required("City is required"),

    pincode: Yup.string()
      .min(6, "Only 6 number are required.")
      .required("Pin Code is required At least 6 "),
    state: Yup.string().required("State is required"),
  });

  let userInfoForm = useFormik({
    // initialValues: initialUserInfo,
    initialValues: fields.user_info,
    validationSchema: UserInfoSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      validatedAndSwitched();
    },
  });

  let PaymentInfoForm = useFormik({
    initialValues: initialPaymentInfo,
    validationSchema: paymentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      createOrder(values);
    },
  });

  const [activeStep, setActiveStep] = React.useState(0);
  // const [skipped, setSkipped] = React.useState(new Set());

  // const isStepSkipped = (step) => {
  //   return skipped.has(step);
  // };

  const handleNext = () => {
    if (activeStep === 0) {
      productForm.handleSubmit();
    }

    if (activeStep === 1) {
      userInfoForm.handleSubmit();
    }
    if (activeStep === 2) {
      PaymentInfoForm.handleSubmit();
    }
  };

  const validatedAndSwitched = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  async function createOrder(values) {
    let data = {
      ...fields,

      user_info: {
        ...userInfoForm.values,
      },
      order_items: productForm.values.products,
      shipping_info: {
        payment_method: values.payment_method,
        courier_company: values.courier_company,
        doc_no: values.doc_no,
        pickup_date: dates.pickup_date,
        delivered_date: dates.delivered_date,
        replacement_date: dates.replacement_date,
        refund_date: dates.refund_date,
      },
      // totalPrice: findTotalPrice(productForm.values.products),
      note: values.note,
      status: values.status,
      amount_paid: values.amount_paid,
      advance_payment: values.advance_payment,
    };

    handleUpdateAnOrder(data);
  }

  // const getDate = (days) => {
  //   const millisecondsInADay = 24 * 60 * 60 * 1000;
  //   const targetDate = new Date(
  //     new Date().getTime() + days * millisecondsInADay,
  //   );
  //   return targetDate.toDateString();
  // };
  const [dates, setDates] = useState({
    pickup_date: fields.shipping_info.pickup_date,
    delivered_date: fields.shipping_info.delivered_date,
    replacement_date: fields.shipping_info.replacement_date,
    refund_date: fields.shipping_info.refund_date,
  });

  const dateHandler = (value, name) => {
    setDates({ ...dates, [name]: value });
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //   );
            // }
            // if (isStepSkipped(index)) {
            //   stepProps.completed = false;
            // }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <>
                <Grid>
                  <Grid>
                    <FormikProvider value={productForm}>
                      <FieldArray
                        name="products"
                        render={(arrayHelpers) => {
                          const products = productForm.values.products;
                          return (
                            <div>
                              {products.length &&
                                products?.map((product, index) => (
                                  <Grid container spacing={2} key={index}>
                                    <Grid item xs={4}>
                                      <Autocomplete
                                        disablePortal
                                        disableClearable
                                        id="disable-clearable"
                                        options={productList}
                                        name="color"
                                        label="Product Color"
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        onChange={(e, v) => {
                                          productForm.setFieldValue(
                                            `products.${index}.color`,
                                            v.value,
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Product Color"
                                            inputProps={{
                                              ...params.inputProps,
                                            }}
                                            variant="outlined"
                                            fullWidth
                                          />
                                        )}
                                        error={
                                          productForm.touched?.products &&
                                          productForm.errors?.products &&
                                          productForm.touched?.products[index]
                                            ?.color &&
                                          Boolean(
                                            productForm.errors?.products[index]
                                              ?.color,
                                          )
                                        }
                                        helpertext={
                                          productForm.touched?.products &&
                                          productForm.errors?.products &&
                                          productForm.touched?.products[index]
                                            ?.color &&
                                          Boolean(
                                            productForm.errors?.products[index]
                                              ?.color,
                                          )
                                        }
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                        fullWidth
                                        name={`products.${index}.quantity`}
                                        label="Quantity"
                                        variant="outlined"
                                        type="number"
                                        value={products[index].quantity}
                                        InputProps={{
                                          inputProps: { min: 1, max: 100 },
                                        }}
                                        onChange={productForm.handleChange}
                                        error={
                                          productForm.touched?.products &&
                                          productForm.errors?.products &&
                                          productForm.touched?.products[index]
                                            ?.qty &&
                                          Boolean(
                                            productForm.errors?.products[index]
                                              ?.qty,
                                          )
                                        }
                                        helpertext={
                                          productForm.touched?.products &&
                                          productForm.errors?.products &&
                                          productForm.touched?.products[index]
                                            ?.qty &&
                                          Boolean(
                                            productForm.errors?.products[index]
                                              ?.qty,
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        fullWidth
                                        name={`products.${index}.price`}
                                        label="Price/unit"
                                        variant="outlined"
                                        type="number"
                                        value={products[index].price}
                                        InputProps={{
                                          inputProps: { min: 0, max: 100000 },
                                        }}
                                        onChange={productForm.handleChange}
                                        error={
                                          productForm.touched?.products &&
                                          productForm.errors?.products &&
                                          productForm.touched?.products[index]
                                            ?.price &&
                                          Boolean(
                                            productForm.errors?.products[index]
                                              ?.price,
                                          )
                                        }
                                        helpertext={
                                          productForm.touched?.products &&
                                          productForm.errors?.products &&
                                          productForm.touched?.products[index]
                                            ?.price &&
                                          Boolean(
                                            productForm.errors?.products[index]
                                              ?.price,
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      {products.length - 1 !== index ? (
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          {" "}
                                          <Delete />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.push({
                                              product_name: "",
                                              quantity: "",
                                              price: "",
                                            })
                                          }
                                        >
                                          <Add />{" "}
                                        </IconButton>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))}
                            </div>
                          );
                        }}
                      />
                    </FormikProvider>
                  </Grid>
                </Grid>
              </>
            )}
            {activeStep === 1 && (
              <form onSubmit={userInfoForm.handleSubmit}>
                <Grid container spacing={2}>
                  {CustomerInfoColumns.map((column, i) => {
                    if (
                      column.type === "text" ||
                      column.type === "number" ||
                      column.type === "textarea"
                    ) {
                      return (
                        <Grid item xs={12} sm={column.sm} key={i + 2}>
                          <TextField
                            fullWidth
                            name={column.name}
                            label={column.label}
                            variant="outlined"
                            type={column.type}
                            value={userInfoForm.values[column.name]}
                            onChange={userInfoForm.handleChange}
                            multiline={
                              column.type === "textarea" ? true : false
                            }
                            minRows={column.type === "textarea" ? 3 : 1}
                            error={
                              userInfoForm.touched[column.name] &&
                              Boolean(userInfoForm.errors[column.name])
                            }
                            helpertext={
                              userInfoForm.touched[column.name] &&
                              userInfoForm.errors[column.name]
                            }
                          />
                        </Grid>
                      );
                    }
                    if (column.type === "select") {
                      return (
                        <Grid item xs={12} sm={column.sm} key={i + 2}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-simple-select-label">
                              {column.label}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={column.label}
                              value={userInfoForm.values[column.name]}
                              onChange={userInfoForm.handleChange(column.name)}
                              type={column.type}
                              error={
                                userInfoForm.touched[column.name] &&
                                Boolean(userInfoForm.errors[column.name])
                              }
                              helpertext={
                                userInfoForm.touched[column.name] &&
                                userInfoForm.errors[column.name]
                              }
                            >
                              {column.options.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      );
                    }
                    return "";
                  })}
                </Grid>
              </form>
            )}
            {activeStep === 2 && (
              <form onSubmit={PaymentInfoForm.handleSubmit}>
                <Grid container spacing={2}>
                  {PaymentInfoColumns.map((column, i) => {
                    if (
                      column.type === "text" ||
                      column.type === "number" ||
                      column.type === "textarea"
                    ) {
                      return (
                        <Grid item xs={12} sm={column.sm} key={i + 3}>
                          <TextField
                            fullWidth
                            name={column.name}
                            label={column.label}
                            variant="outlined"
                            type={column.type}
                            value={PaymentInfoForm.values[column.name]}
                            onChange={PaymentInfoForm.handleChange}
                            multiline={
                              column.type === "textarea" ? true : false
                            }
                            minRows={column.type === "textarea" ? 3 : 1}
                            error={
                              PaymentInfoForm.touched[column.name] &&
                              Boolean(PaymentInfoForm.errors[column.name])
                            }
                            helpertext={
                              PaymentInfoForm.touched[column.name] &&
                              PaymentInfoForm.errors[column.name]
                            }
                          />
                        </Grid>
                      );
                    }
                    if (column.type === "select") {
                      return (
                        <Grid item xs={12} sm={column.sm} key={i + 3}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="demo-simple-select-label">
                              {column.label}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={column.label}
                              value={PaymentInfoForm.values[column.name]}
                              onChange={PaymentInfoForm.handleChange(
                                column.name,
                              )}
                              type={column.type}
                              error={
                                PaymentInfoForm.touched[column.name] &&
                                Boolean(PaymentInfoForm.errors[column.name])
                              }
                              helpertext={
                                PaymentInfoForm.touched[column.name] &&
                                PaymentInfoForm.errors[column.name]
                              }
                            >
                              {column.options.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      );
                    }
                    if (column.type === "date") {
                      return (
                        <Grid item xs={12} sm={6} key={i} >
                          <FormControl fullWidth variant="outlined">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <FormControl fullWidth>
                                <DatePicker
                                  value={dates[column.name]}
                                  onChange={(v) => dateHandler(v, column.name)}
                                  variant="outlined"
                                  label={column.label}
                                  fullWidth
                                  autoOk
                                />
                              </FormControl>
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </Grid>
                      );
                    }
                    return ""
                  })}
                </Grid>
                {/* <Grid item xs={12} >
                                        <p>Total : 4500</p>
                                    </Grid> */}

                <Grid
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      onClick={() => PaymentInfoForm.handleSubmit()}
                    >
                      Update Order
                    </Button>
                  }
                </Grid>
              </form>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Grid container justifyContent="space-between">
                <Grid item xs={9}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  {/* <Box sx={{ flex: '1 1 auto' }} />
                                    {isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                            Skip
                                        </Button>
                                    )} */}

                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        )}
      </Box>

      {/* <form onSubmit={PaymentInfo.handleSubmit}>
            // <Grid container spacing={2}>
            //     {columns.map((column) => {
            //         if (column.type === 'text' || column.type === 'number' || column.type === 'textarea') {
            //             return (
            //                 <Grid item xs={12} sm={column.sm}>
            //                     <TextField
            //                         fullWidth
            //                         name={column.name}
            //                         label={column.label}
            //                         variant="outlined"
            //                         type={column.type}
            //                         value={productForm.values[column.name]}

            //                         onChange={formik.handleChange}
            //                         multiline={column.type === 'textarea' ? true : false}
            //                         minRows={column.type === 'textarea' ? 3 : 1}
            //                         error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
            //                         helpertext={formik.touched[column.name] && formik.errors[column.name]}
            //                     />
            //                 </Grid>
            //             )
            //         }
            //         if (column.type === 'select') {
            //             return (
            //                 <Grid item xs={12} sm={column.sm}>
            //                     <FormControl fullWidth variant='outlined'>
            //                         <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>
            //                         <Select
            //                             labelId="demo-simple-select-label"
            //                             id="demo-simple-select"
            //                             label={column.label}
            //                             value={formik.values[column.name]}
            //                             onChange={formik.handleChange(column.name)}
            //                             type={column.type}

            //                             error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
            //                             helpertext={formik.touched[column.name] && formik.errors[column.name]}


            //                         >
            //                             {
            //                                 column.name === 'product_name' ? products.map((product, index) => <MenuItem key={index} value={product._id}>{product.product_name}</MenuItem>) :
            //                                     column.options.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
            //                             }

            //                         </Select>
            //                     </FormControl>
            //                 </Grid>
            //             )
            //         }


            //     })}




            // </Grid>



            <Grid
                style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {
                    mode === 'ADD' && (<Button
                        variant="contained"
                        type='submit'
                        size="medium"
                        color="secondary"
                    >
                        Make An Order
                    </Button>)
                }


            </Grid>



            */}
    </>
  );
}

export default OrderView;
