import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import API from "../../services/API";
import { ToastContainer, toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(2, 4, 3),
    height: "100%",
  },
}));
function formatDate(date) {
  return new Date(date).toLocaleString("en-IN", {timeZone: "Asia/Kolkata"})
}

function PubTicketView(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { phone } = useParams();

  const handleSubmit = (row) => {
    if (row) {
      var a = document.getElementById(row._id).value;

      row.notes = [...row.notes, { note: a }];
      handleUpdate(row);
      document.getElementById(row._id).value = "";
    }
  };

  const fetchTickets = () => {
    API.get(`/ticket/all?phone=${phone}`)

      .then((response) => {
        if (response.data.success) {
          if (response?.data?.data?.length <= 0) {
            toast.warning("Data not Found");
          }
          setData([...response.data.data]);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Error");
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleUpdate = (values) => {
    API.put(`/ticket/${values._id}`, values)
      .then((response) => {
        if (response.data.success) {
          toast.success("New Note Added Successfully");
          fetchTickets();
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  useEffect(() => {
    fetchTickets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.paper}
    >
      <Grid item xs={12} sm={10} lg={8} md={8}>
        <PageTitle title="View Ticket" />
        <div>
          <br />

          <div style={{ height: "100vh" }}>
            {data?.map((row) => {
              return (
                <Accordion key={row._id}
                  style={{
                    marginBottom: "20px",
                    backgroundColor: "#343A44",
                    color: "white",
                    padding: "10px"
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={row._id+'_panel1a-content'}
                    id={row._id+'_accordian_header'}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={6}>
                        Name : {row.customer_name}
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        Phone : {row.phone}
                      </Grid>
                      <Grid item xs={4} sm={6}>
                        Status : {row.status}
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        Date : {formatDate(row.complaint_date)}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        {row?.notes?.map((noteObj, noteI) => {
                          return (
                            <>
                              <hr />
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ paddingBottom: "20px" }}
                                key={noteObj._id}
                              >
                                Note : {noteObj.note}
                                <br />
                                Time : {formatDate(noteObj.created_at)}
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12} sm={12} id={row._id+'custom_note'}>
                        <TextField
                          variant="outlined"
                          placeholder="Enter New Note"
                          height="15px"
                          id={row._id}
                          fullWidth
                          multiline
                          minRows={5}
                        ></TextField>
                        <Grid
                          style={{
                            width: "100%",
                            marginTop: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => handleSubmit(row)}
                            variant="contained"
                            type="submit"
                            size="medium"
                            color="primary"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
        <ToastContainer />
      </Grid>
    </Grid>
  );
}

export default PubTicketView;
