import React from 'react';
import PageTitle from "../../components/PageTitle/PageTitle";
import ModalForm2 from "./components/ModalForm2";
import API from "../../services/API";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function DemoView() {
  const history = useHistory();
  const handleUpdate = (values) => {
    API.put(`/demo/${values._id}`, values)
      .then((response) => {
        if (response.data.success) {
          toast.success("Updated Successfully");
          history.push({ pathname: "/app/demo" });
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };
  return (
    <>
      <ToastContainer />
      <PageTitle title="Edit Demo" />
      <ModalForm2 handleUpdate={handleUpdate} />
    </>
  );
}

export default DemoView;
