import { Grid, MuiThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React from 'react'
// import {ToastContainer, toast} from 'react-toastify';
import PageTitle from '../../components/PageTitle/PageTitle';



const columns = [
    {
        name: "product_name",
        label: "Products",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "product_code",
        label: "Cost",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "product_sku",
        label: "SKU",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "product_qty",
        label: "Product Quantity",
        options: {
            filter: true,
            sort: false,
        },
    },
    {
        name: "product_mrp",
        label: "Product MRP",
        options: {
            filter: true,
            sort: false,
        },
    },


];

function products() {

    const options = {
        filterType: "checkbox",
        onRowsDelete: (e) => {
         
        }
    };
  return (
    <>
    <PageTitle
        title="Products"
    />
    {/* <ToastContainer /> */}
    <Grid container spacing={3}>
        <Grid item xs={6}>
            {/*  {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
                <MUIDataTable
                    title={"Products List"}
                    data={[]}
                    columns={[
                        ...columns,
                        {
                            name: "action",
                            label: "Action",
                            options: {
                                filter: true,
                                sort: false,
                            },
                        },
                    ]}
                    options={options}
                />
            </MuiThemeProvider>

        </Grid>
        <Grid item md={6}>
     
        </Grid>
    </Grid>




</>
)
}

export default products