import { Button, Chip, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },

    item: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px"
    },
    dnd: {
        border: "3px dashed #ccbbbb",
        minWidth: "100%",
        minHeight: "180px",
        borderRadius: '10px',
        backgroundColor: "#EBF1FB",
        margin: '10px 0px'
    },
    hover: {
        border: "5px dashed #ccbbbb",
        minWidth: "100%",
        minHeight: "180px",
        borderRadius: '10px',
        backgroundColor: "#EBF1FB",
        margin: '10px 0px'
    },

}));

function ImportModal(props) {

    const { importExcel } = props

    const classes = useStyles();


    const [onDrag, setOndrag] = useState(false)
    const [files, setFiles] = useState([])
    const dragOver = (e) => {
        e.preventDefault();

        if (!onDrag) {
            setOndrag(true)
        }
    }

    const dragEnter = (e) => {
        e.preventDefault();
        if (!onDrag) {
            setOndrag(true)
        }
    }

    const dragLeave = (e) => {
        e.preventDefault();
        if (onDrag) {
            setOndrag(false)
        }

    }

    const fileDrop = (e) => {
        e.preventDefault();
        if (onDrag) {
            setOndrag(false)
        }
        setFiles([...e.dataTransfer.files[0]]);
    }

    const browseButtonHandler = (e) => {
        setFiles([e.target.files[0]]);
        console.log(files);
    }

    const deleteFile = (index) => {
        setFiles([]);
    }

    const handleSubmit = () => {
        if (files.length) {
            importExcel(files[0])
        }

    }





    return (
        <>
            <Grid item xs={12}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                className={onDrag ? classes.hover : classes.dnd}>
                <Grid
                    container
                    direction='column'

                    style={{ height: '100%', justifyContent: 'center', textAlign: 'center' }}

                >

                    <Grid item style={{ marginTop: '50px' }} >
                        <Typography variant="h5" >  Drag your documents,Photos,<br /> here to start uploading </Typography>
                    </Grid>
                    <Grid item>
                        <input
                            // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={browseButtonHandler}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                                Browse Files
                            </Button>
                        </label>
                    </Grid>

                </Grid>
            </Grid>

            <Grid container spacing={1}>
                {
                    files.map((file, index) => {
                        return (
                            <Grid item key={index}>
                                <Chip
                                    label={file.name ? file.name : file}

                                    onDelete={() => deleteFile(index)}

                                    variant="outlined"
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>


            <Grid
                style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >

                <Button
                    variant="contained"
                    type='submit'
                    size="medium"
                    color="secondary"
                    onClick={handleSubmit}
                >
                    Import
                </Button>

            </Grid>
        </>
    )
}

export default ImportModal