import { Divider } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import MakeARequestForm from "./components/MakeARequestForm";
import { useHistory } from "react-router-dom";
import API from "../../../services/API";
const INITIAL_VALUES = {
  assemble_id: "",
  // assemble_code: "",
  request_qty: "",
  total_cost: "",
  vendor_id: "",
  raw_material: [],
  assemble_items: [],
};
const INITIAL_VALUES_STATUS = {
  return_qty: "",
  status: "",
  remarks: "",
  _id: "",
};

const CONSTANTS = {
  ADD: "add",
  VIEW: "view",
};

function MakeARequest() {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [fields, setFields] = useState({ ...INITIAL_VALUES });
  const [mode, setMode] = useState(CONSTANTS.ADD);
  // const [statusModal, setStatusModal] = useState(false);
  const [statusFields, setStatusFields] = useState({
    ...INITIAL_VALUES_STATUS,
  });
  const [assemble, setAssemble] = useState([]);
  const [equipment, setEquipment] = useState({});
  const [vendors, setVendores] = useState([]);
  const [availabelAssemble, setAvailabelAssemble] = useState([]);
  const [availabelAssembledUnits, setAvailabelAssembledUnits] = useState([]);

  const handlestatusModalOpen = (position) => {
    setStatusFields({ ...statusFields, _id: data[position]._id });
    // setStatusModal(true);
  };

  const onChange = (e) => {
    if (e.target.name === "assemble_name") {
      let results = assemble.find((item) => item._id === e.target.value);

      setFields({
        ...fields,
        ...results,
        request_qty: 1,
        assemble_id: e.target.value,
        // total_cost: results.assemble_cost,
      });
    } else if ([e.target.name] === "request_qty") {
      setFields({
        ...fields,
        [e.target.name]: e.target.value,
        total_cost: parseInt(e.target.value) * parseInt(fields.assemble_cost),
      });
    } else {
      setFields({ ...fields, [e.target.name]: e.target.value });
    }
  };

  const makeRequest = () => {
    let updateDateRawmaterial = [];
    let updateDateAssembleItems = [];

    if (fields.vendor_id && fields.assemble_id) {
      if (fields.request_qty > 1) {
        fields.raw_material.forEach((item) => {
          updateDateRawmaterial.push({
            ...item,
            item_qty:
              fields.request_qty > 1
                ? parseInt(fields.request_qty) * parseInt(item.item_qty)
                : parseInt(item.item_qty),
          });
        });

        fields.assemble_items.forEach((item) => {
          updateDateAssembleItems.push({
            ...item,
            item_qty:
              fields.request_qty > 1
                ? parseInt(fields.request_qty) * parseInt(item.item_qty)
                : parseInt(item.item_qty),
          });
        });
      }
      API.post("/assemble-request/add", {
        ...fields,
        raw_material: updateDateRawmaterial,
        assemble_items: updateDateAssembleItems,
      })
        .then((response) => {
          toast.success("Created Successfully!");
          setData([
            ...data,
            { ...response.data.AssembleRequest, created_by: "admin" },
          ]);
          history.push("/app/assemble/AssembleRequest"); 
        })
        .catch((err) => {
          toast.dark("Vendor or Assemble should be selected.");
        });
    } else {
      toast.dark("Vendor or Assemble should be selected.");
    }
  };
  // const handleView = (index) => {
  //   console.log(">>>>>>>>>>>>>>>>>>>>>>>");
  //   console.log(data);
  //   console.log(">>>>>>>>>>>>>>>>>>>>>>>");
  //   let filteredData = data[index];
  //   setFields({
  //     ...filteredData,
  //     raw_material: filteredData.assemble_id.raw_material,
  //     assemble_items: filteredData.assemble_id.assemble_items,
  //     assemble_name: filteredData.assemble_id._id,
  //     // assemble_code: filteredData.assemble_id.assemble_code,
  //     vendor: filteredData.vendor_id,
  //     vendor_id: filteredData.vendor_id._id,
  //   });
  //   setMode(CONSTANTS.VIEW);
  // };

  // const updateRequest = (values) => {
  //   // setStatusModal(false);
  //   API.put(`/assemble-request/${values._id}`, values)
  //     .then((response) => {
  //       toast.success("Assemble Request Successfully Updated");
  //       let findIndex = data.findIndex(
  //         (item) => item._id === response.data.AssembleRequest._id,
  //       );
  //       data[findIndex] = response.data.AssembleRequest;
  //       setData([...data]);
  //     })
  //     .catch((err) => {
  //       toast.dark("Vendor or Assemble should be selected.");
  //     });
  // };

  useEffect(() => {
    if (fields.raw_material.length > 0) {
      API.post("/assemble-request/check")
        .then((response) => {
          console.log(response.data);
          setAvailabelAssemble(response.data.AssembleRequest);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fields.raw_material.length]);

  useEffect(() => {
    if (fields?.assemble_items.length > 0) {
      API.post("/assemble-request/check-assembled-unit")
        .then((response) => {
          console.log(response.data);
          setAvailabelAssembledUnits(response.data.AssembleRequest);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fields.assemble_items.length]);

  let allAssembleReqt = () => {
    API.get("/assemble-request/all")
      .then((response) => {
        setData(response.data.AssembleRequest);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    allAssembleReqt();

    API.get("/assemble/all")
      .then((response) => {
        setAssemble(response.data.assemble);
      })
      .catch((error) => {
        console.log(error);
      });
    API.get("/vendor/all")
      .then((response) => {
        setVendores(response.data.vendors);
      })
      .catch((error) => {
        console.log(error);
      });

    API.get("/equipment/all")
      .then((response) => {
        const result = response.data.equipment.reduce((acc, item) => {
          acc[item._id] = item.equipment_name;
          return acc;
        }, {});
        setEquipment(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <PageTitle title="Create New Assemble Request" />

      {/* <Grid
              justifyContent="space-between" // Add it here :)
              container
              spacing={10}
            >
              <Grid item>
                <h2 id="simple-modal-title">View Request</h2>
              </Grid>
            </Grid>
   */}
      <Divider style={{ margin: " 20px 0px" }} />
      <MakeARequestForm
        fields={fields}
        onChange={onChange}
        mode={mode}
        CONSTANTS={CONSTANTS}
        makeRequest={makeRequest}
        assemble={assemble}
        equipment={equipment}
        vendors={vendors}
        availabelAssemble={availabelAssemble}
        availabelAssembledUnits={availabelAssembledUnits}
      />
    </>
  );
}

export default MakeARequest;
