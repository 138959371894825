import React from "react";

import Select from "react-select";

export default function Dashboard(props) {
    const {options, selectedOptions, setSelectedOptions} = props;
    const handleSelect = (data) =>{
        setSelectedOptions(data);
      };
  return (<>
      <Select
        defaultValue={selectedOptions}
        styles={{'width':"100%",'padding':'20px'}}
        isMulti
        name="colors"
        options={options}
        value={selectedOptions}
        onChange={handleSelect}
        className="basic-multi-select"
        classNamePrefix="select"
        isSearchable={true}
      />
    </>
  );
}
