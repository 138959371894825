import { Button, TextField, Grid, FormControl } from '@material-ui/core';
import React, { useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';


// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//         },
//     },
//     input: {
//         display: 'none',
//     },

//     item: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         padding: "20px"
//     },
//     dnd: {
//         border: "3px dashed #ccbbbb",
//         minWidth: "100%",
//         minHeight: "180px",
//         borderRadius: '10px',
//         backgroundColor: "#EBF1FB",
//         margin: '10px 0px'
//     },
//     hover: {
//         border: "5px dashed #ccbbbb",
//         minWidth: "100%",
//         minHeight: "180px",
//         borderRadius: '10px',
//         backgroundColor: "#EBF1FB",
//         margin: '10px 0px'
//     },

// }));

function ImporMonthtModal({ UpdateMonthDate, handleClose, mode }) {

    console.log("mode")
    console.log(mode)

    // const classes = useStyles();
    const [fromDate, setFromDate] = useState(new Date(Date.now() - 5 * 60 * 60 * 1000 * 24).toDateString())
    const [toDate, setToDate] = useState(new Date().toDateString())

    const dateFromHandler = (values) => {
        setFromDate(values);
        console.log(values)
    }
    const dateToHandler = (values) => {
        setToDate(values);
        console.log(values)
    }

    const handleSubmit = () => {
        console.log("ajajj--hello")
        UpdateMonthDate("", fromDate, toDate)

        handleClose()

    }
    // const deleteFile = (index) => {
    //     setFromDate(new Date().toDateString());
    //     setToDate(new Date().toDateString());
    // }

    return (
        <>
            <Grid item xs={12} sm={6} margin="dense" >

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl fullWidth >
                        <DatePicker

                            variant='outlined'
                            label="From Date"
                            fullWidth

                            value={fromDate}
                            onChange={dateFromHandler}
                            renderInput={(params) => <TextField variant='outlined'
                                {...params} />}
                        />
                    </FormControl>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: "10px" }} >

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl fullWidth >
                        <DatePicker

                            variant='outlined'
                            label="To Date"
                            fullWidth

                            value={toDate}
                            onChange={dateToHandler}
                            renderInput={(params) => <TextField variant='outlined'
                                {...params} />}
                        />
                    </FormControl>
                </MuiPickersUtilsProvider>
            </Grid>
            {/* <Grid container spacing={1}>
                <Grid item>
                    <Chip
                        label={"date"}

                        onDelete={() => deleteFile()}

                        variant="outlined"
                    />
                </Grid>
            </Grid> */}

            <Grid
                style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >

                <Button
                    variant="contained"
                    type='submit'
                    size="medium"
                    color="secondary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>

            </Grid>
        </>
    )
}

export default ImporMonthtModal