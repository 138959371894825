import React, { useEffect } from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import * as Yup from "yup";
import { useFormik } from "formik";
import Chip from '@material-ui/core/Chip';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useState } from 'react';
// import API from '../../../../services/API';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },

    item: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px"
    },
    dnd: {
        border: "3px dashed #ccbbbb",
        minWidth: "100%",
        minHeight: "180px",
        borderRadius: '10px',
        backgroundColor: theme.palette.background.light,
        margin: '10px 0px'
    },
    hover: {
        border: "5px dashed #ccbbbb",
        minWidth: "100%",
        minHeight: "180px",
        borderRadius: '10px',
        backgroundColor: "#EBF1FB",
        margin: '10px 0px'
    },

}));


const columns = [

    {
        name: 'equipment_id',
        label: 'Equipment',
        type: 'select',
        sm: 6

    },
    {
        name: 'purchase_qty',
        label: 'Quantity',
        type: 'number',
        sm: 6
    },
    {
        name: 'purchase_unit',
        label: 'Unit',
        type: 'select',
        options: ['kg', 'piece', 'litre', 'ml', 'other'],
        sm: 6

    },
    {
        name: 'purchase_cost',
        label: 'Cost',
        type: 'number',
        sm: 6
    },
    {
        name: 'vendor_id',
        label: 'Vendor',
        type: 'select',
        options: ['vendor1', 'vendor2', 'vendor3'],
        sm: 6
    },
    {
        name: 'purchase_date',
        label: 'Purchase Date',
        type: 'date',
        sm: 6
    },
    {
        name: 'purchase_remark',
        label: 'Remark',
        type: 'textarea',
        sm: 12
    }

]


const EquipmentSchema = Yup.object().shape({
    equipment_id: Yup.string()
        .required("Equipment Name is required"),
    purchase_qty: Yup.string()
        .required("Quantity  is required"),
    purchase_cost: Yup.string()
        .required("Cost  is required"),
    purchase_unit: Yup.string()
        .required("Unit is required"),
    vendor_id: Yup.string()
        .required("Vendor  is required"),
    purchase_date: Yup.string()
        .required("Purchase Date is required"),
    purchase_remark: Yup.string()
        .required("Purchase Remark is required"),
});





function ModalForm(props) {

    const { CONSTANTS, modal, fields, handleSave, handleUpdate,equipment,vendor } = props

    const classes = useStyles();

    const [onDrag, setOndrag] = useState(false)
    const [files, setFiles] = useState([])
    const [date, setDate] = useState(new Date().toDateString())
 



    let formik = useFormik({
        initialValues: fields,
        validationSchema: EquipmentSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmit(values)
        },
    });



    const handleSubmit = (values) => {


        if (modal === CONSTANTS.ADD) {
            handleSave({ ...values,files, purchase_date: new Date(date).toDateString() })
        } else if (modal === CONSTANTS.EDIT) {
            handleUpdate({ ...values, files, purchase_date: new Date(date).toDateString() })
        }

    }

    const dragOver = (e) => {
        e.preventDefault();

        if (!onDrag) {
            setOndrag(true)
        }
    }

    const dragEnter = (e) => {
        e.preventDefault();
        if (!onDrag) {
            setOndrag(true)
        }
    }

    const dragLeave = (e) => {
        e.preventDefault();
        if (onDrag) {
            setOndrag(false)
        }

    }

    const fileDrop = (e) => {
        e.preventDefault();
        if (onDrag) {
            setOndrag(false)
        }
        setFiles([...files, ...e.dataTransfer.files]);
    }

    const browseButtonHandler = (e) => {
        setFiles([...files, ...e.target.files]);
        console.log(files);
    }

    const deleteFile = (index) => {
        files.splice(index, 1)
        setFiles([...files]);
    }

    const dateHandler = (values) => {
        setDate(values);
    }


    useEffect(() => {
        if (fields?.files?.length > 0) {
            setFiles([...fields.files]);
        }
        if (fields.purchase_date) {
            setDate(fields.purchase_date);
        }
        
        return () => {
            formik.resetForm()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields])



    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                {columns.map((column) => {
                    if (column.type === 'text' || column.type === 'number' || column.type === 'textarea') {
                        return (
                            <Grid item xs={12} sm={column.sm}>
                                <TextField
                                    fullWidth
                                    name={column.name}
                                    label={column.label}
                                    variant="outlined"
                                    type={column.type}
                                    value={formik.values[column.name]}
                                    disabled={modal === CONSTANTS.VIEW}
                                    onChange={formik.handleChange}
                                    multiline={column.type === 'textarea' ? true : false}
                                    minRows={column.type === 'textarea' ? 3 : 1}
                                    error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                    helperText={formik.touched[column.name] && formik.errors[column.name]}
                                />
                            </Grid>
                        )
                    }
                    if (column.type === 'select') {
                        return (
                            <Grid item xs={12} sm={column.sm}>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel id="demo-simple-select-label">{column.label}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label={column.label}
                                        value={formik.values[column.name]}
                                        onChange={formik.handleChange(column.name)}
                                        type={column.type}
                                        disabled={modal === CONSTANTS.VIEW}
                                        error={formik.touched[column.name] && Boolean(formik.errors[column.name])}
                                        helperText={formik.touched[column.name] && formik.errors[column.name]}


                                    >
                                        {
                                            column.name === 'equipment_id' ? equipment.map((item, index) => <MenuItem key={index} value={item._id}>{item.equipment_name}</MenuItem>) : null
                                        }
                                        {
                                            column.name === 'vendor_id' ? vendor.map((item, index) => <MenuItem key={index} value={item._id}>{item.vendor_name}</MenuItem>) : null
                                        }
                                        {
                                            column.name === 'purchase_unit' ? column?.options?.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>) : null
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    }

                    if (column.type === 'date') {
                        return (
                            <>
                                <Grid item xs={12} sm={column.sm} >

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormControl fullWidth >
                                            <DatePicker
                                                variant='outlined'
                                                label="Purchase Date"
                                                fullWidth
                                                disabled={modal === CONSTANTS.VIEW}
                                                value={date}
                                                onChange={dateHandler}
                                                renderInput={(params) => <TextField variant='outlined'
                                                    {...params} />}
                                            />
                                        </FormControl>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </>

                        )
                    }
                    return ""
                })}


                {
                    modal === CONSTANTS.ADD && (<Grid item xs={12}
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        className={onDrag ? classes.hover : classes.dnd}>
                        <Grid
                            container
                            direction='column'
                            alignItems='center'

                            style={{ height: '100%', justifyContent: 'center', textAlign: 'center', backgroundColor: "#343A44" }}

                        >

                            <Grid item >
                                <Typography variant="h5" >  Drag your documents,Photos,<br /> here to start uploading </Typography>
                            </Grid>
                            <Grid item>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={browseButtonHandler}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" color="primary" component="span">
                                        Browse Files
                                    </Button>
                                </label>
                            </Grid>

                        </Grid>
                    </Grid>)
                }


            </Grid>
            <Grid container spacing={1}>
                {
                    files.map((file, index) => {
                        return (
                            <Grid item key={index}>
                                <Chip
                                    label={file.name ?file.name : file }

                                    onDelete={() => {

                                        if (modal !== CONSTANTS.VIEW) {
                                            deleteFile(index)
                                        }
                                    }
                                    }

                                    variant="outlined"
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
            {
                (modal !== CONSTANTS.VIEW) && (<Grid
                    style={{
                        width: "100%",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >

                    <Button
                        variant="contained"
                        type='submit'
                        size="medium"
                        color="primary"
                    >
                        {modal === CONSTANTS.ADD ? 'Add ' : "Edit"}
                    </Button>

                </Grid>)
            }


        </form>
    )
}

export default ModalForm