import {
  Button,
  Chip,
  Modal,
  MuiThemeProvider,
  makeStyles,
  Divider,
  Grid,
  IconButton,
} from "@material-ui/core";
import { Add, Visibility } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ModalForm from "./components/ModalForm";
import StatusModal from "./components/StatusModal";
import API from "../../../services/API";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 360,
    maxWidth: 1500,
    maxHeight: "80vh",
    backgroundColor: theme.palette.background.light,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    overflowY: "scroll",
  },
}));

const COLUMNS = [
  {
    name: "assemble_name",
    label: "Assemble Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  // {
  //   name: "assemble_code",
  //   label: "Assemble Code",
  //   options: {
  //     filter: true,
  //     sort: false,
  //   },
  // },
  {
    name: "request_qty",
    label: "Request Quantity",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "total_cost",
    label: "Cost",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "vendor_name",
    label: "Vendor",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "requested_date",
    label: "Date",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "created_by",
    label: "Created By",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: false,
    },
  },
];

const INITIAL_VALUES = {
  assemble_id: "",
  // assemble_code: "",
  request_qty: "",
  total_cost: "",
  vendor_id: "",
  raw_material: [],
  assemble_items: [],
};
const INITIAL_VALUES_STATUS = {
  return_qty: "",
  status: "",
  remarks: "",
  _id: "",
};

const CONSTANTS = {
  ADD: "add",
  VIEW: "view",
};

function AssembleRequest() {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState({ ...INITIAL_VALUES });
  const [mode, setMode] = useState(CONSTANTS.ADD);
  const [statusModal, setStatusModal] = useState(false);
  const [statusFields, setStatusFields] = useState({
    ...INITIAL_VALUES_STATUS,
  });
  const [assemble, setAssemble] = useState([]);
  const [equipment, setEquipment] = useState({});
  const [vendors, setVendores] = useState([]);
  const [availabelAssemble, setAvailabelAssemble] = useState([]);
  const [availabelAssembledUnits, setAvailabelAssembledUnits] = useState([]);

  const handleOpen = () => {
    // setFields({ ...INITIAL_VALUES });
    // setMode(CONSTANTS.ADD);
    // setOpen(true);
    history.push("/app/assemble/new-request");
  };

  const options = {
    filterType: "checkbox",
    onRowsDelete: (e) => {
      handleDelete(e);
    },
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlestatusModalOpen = (position) => {
    setStatusFields({ ...statusFields, _id: data[position]._id });
    setStatusModal(true);
  };

  const handlestatusModalClose = () => setStatusModal(false);

  const onChange = (e) => {
    if (e.target.name === "assemble_name") {
      let results = assemble.find((item) => item._id === e.target.value);

      setFields({
        ...fields,
        ...results,
        request_qty: 1,
        assemble_id: e.target.value,
        // total_cost: results.assemble_cost,
      });
    } else if ([e.target.name] === "request_qty") {
      setFields({
        ...fields,
        [e.target.name]: e.target.value,
        total_cost: parseInt(e.target.value) * parseInt(fields.assemble_cost),
      });
    } else {
      setFields({ ...fields, [e.target.name]: e.target.value });
    }
  };

  const makeRequest = () => {
    let updateDateRawmaterial = [];
    let updateDateAssembleItems = [];

    if (fields.vendor_id && fields.assemble_id) {
      if (fields.request_qty > 1) {
        fields.raw_material.forEach((item) => {
          updateDateRawmaterial.push({
            ...item,
            item_qty:
              fields.request_qty > 1
                ? parseInt(fields.request_qty) * parseInt(item.item_qty)
                : parseInt(item.item_qty),
          });
        });

        fields.assemble_items.forEach((item) => {
          updateDateAssembleItems.push({
            ...item,
            item_qty:
              fields.request_qty > 1
                ? parseInt(fields.request_qty) * parseInt(item.item_qty)
                : parseInt(item.item_qty),
          });
        });
      }
      API.post("/assemble-request/add", {
        ...fields,
        raw_material: updateDateRawmaterial,
        assemble_items: updateDateAssembleItems,
      })
        .then((response) => {
          setData([
            ...data,
            { ...response.data.AssembleRequest, created_by: "admin" },
          ]);
          setOpen(false);
        })
        .catch((err) => {
          toast.dark("Vendor or Assemble should be selected.");
        });
    } else {
      toast.dark("Vendor or Assemble should be selected.");
    }
  };
  const handleView = (index) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>");
    console.log(data);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>");
    let filteredData = data[index];
    setFields({
      ...filteredData,
      raw_material: filteredData.assemble_id.raw_material,
      assemble_items: filteredData.assemble_id.assemble_items,
      assemble_name: filteredData.assemble_id._id,
      // assemble_code: filteredData.assemble_id.assemble_code,
      vendor: filteredData.vendor_id,
      vendor_id: filteredData.vendor_id._id,
    });
    setMode(CONSTANTS.VIEW);
    setOpen(true);
  };

  const updateRequest = (values) => {
    setStatusModal(false);
    API.put(`/assemble-request/${values._id}`, values)
      .then((response) => {
        toast.success("Assemble Request Successfully Updated");
        let findIndex = data.findIndex(
          (item) => item._id === response.data.AssembleRequest._id,
        );
        data[findIndex] = response.data.AssembleRequest;
        setData([...data]);
      })
      .catch((err) => {
        toast.dark("Vendor or Assemble should be selected.");
      });
  };

  useEffect(() => {
    if (fields.raw_material.length > 0) {
      API.post("/assemble-request/check")
        .then((response) => {
          console.log(response.data);
          setAvailabelAssemble(response.data.AssembleRequest);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fields.raw_material.length]);

  useEffect(() => {
    if (fields?.assemble_items.length > 0) {
      API.post("/assemble-request/check-assembled-unit")
        .then((response) => {
          console.log(response.data);
          setAvailabelAssembledUnits(response.data.AssembleRequest);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [fields.assemble_items.length]);

  const getRows = () => {
    return data.map((row, i) => {
      return {
        ...row,
        requested_date: new Date(row.requested_date).toDateString(),
        assemble_name: row?.assemble_id?.assemble_name,
        // assemble_code: row?.assemble_id?.assemble_code,
        vendor_name: row?.vendor_id?.vendor_name,
        request_qty: (
          <>
            <div>
              {row.status === "pending" ? (
                row.request_qty
              ) : (
                <>
                  <small>Requested: ( {row.request_qty} ) </small>
                  <br />
                  <small>Approved: ( {row.request_qty-row.return_qty} ) </small>
                  <br />
                  <small>Return: ( {row.return_qty} )</small>
                </>
              )}
            </div>
          </>
        ),
        status: (
          <Chip
            label={row.status}
            clickable
            color={row.status === "pending" ? "secondary" : "primary"}
            onClick={() => {
              return row.status === "pending" ? handlestatusModalOpen(i) : null;
            }}
          />
        ),
        action: (
          <>
            <Button
              variant="contained"
              onClick={() => {
                handleView(i);
              }}
              style={{ marginRight: "10px", backgroundColor:"#FF9001" }}
            >
              <Visibility />
            </Button>
          </>
        ),
      };
    });
  };

  const handleDelete = (e) => {
    const array = e.data.map(item => data[item.index]._id);
    API.post(`/assemble-request/deletemany`, { assembleRequestIds: array })
      .then((response) => {
        if (response.data.success) {
          toast.dark("Assemble Request deleted successfully");
        } else {
          toast.warning(response.data.message);
        }
        allAssembleReqt();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  let allAssembleReqt = () => {
    API.get("/assemble-request/all")
      .then((response) => {
        setData(response.data.AssembleRequest);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    allAssembleReqt();

    API.get("/assemble/all")
      .then((response) => {
        setAssemble(response.data.assemble);
      })
      .catch((error) => {
        console.log(error);
      });
    API.get("/vendor/all")
      .then((response) => {
        setVendores(response.data.vendors);
      })
      .catch((error) => {
        console.log(error);
      });

    API.get("/equipment/all")
      .then((response) => {
        const result = response.data.equipment.reduce((acc, item) => {
          acc[item._id] = item.equipment_name;
          return acc;
        }, {});
        
        setEquipment(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <PageTitle
        title="Assemble Request"
        button={
          <Button
            variant="contained"
            onClick={handleOpen}
            size="medium"
            color="primary"
            startIcon={<Add />}
          >
            Make A Request
          </Button>
        }
      />

       {/* <MuiThemeProvider theme={getMuiTheme()}> */}
            <MuiThemeProvider>
        <MUIDataTable
          title="Assemble Request"
          data={getRows()}
          columns={[
            ...COLUMNS,
            {
              name: "action",
              label: "Action",
              options: {
                filter: true,
                sort: false,
              },
            },
          ]}
          options={options}
        />
      </MuiThemeProvider>

      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">View Request</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handleClose} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

          <Divider style={{ margin: " 20px 0px" }} />
          <ModalForm
            fields={fields}
            onChange={onChange}
            mode={mode}
            CONSTANTS={CONSTANTS}
            makeRequest={makeRequest}
            assemble={assemble}
            equipment={equipment}
            vendors={vendors}
            availabelAssemble={availabelAssemble}
            availabelAssembledUnits={availabelAssembledUnits}
          />
        </div>
      </Modal>

      <Modal
        open={statusModal}
        // onClose={handlestatusModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={10}
          >
            <Grid item>
              <h2 id="simple-modal-title">View Request</h2>
            </Grid>

            <Grid item>
              <IconButton onClick={handlestatusModalClose} variant="text">
                <CloseIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

          <Divider style={{ margin: " 20px 0px" }} />
          <StatusModal
            updateRequest={updateRequest}
            statusFields={statusFields}
          />
        </div>
      </Modal>
    </>
  );
}

export default AssembleRequest;
