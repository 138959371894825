import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import SaveIcon from "@mui/icons-material/Save";
import { ToastContainer, toast } from "react-toastify";
import API from "../../services/API";
import { useParams } from "react-router-dom";
import OrderTabs from "./components/OrderTabs";

const productList = [
  {
    label: "Blue",
    value: "blue",
  },
  {
    label: "Grey",
    value: "grey",
  },
  {
    label: "Red",
    value: "red",
  },
  {
    label: "Yellow",
    value: "yellow",
  },
];

const CustomerInfoColumns = [
  {
    name: "name",
    label: "Customer Name",
    type: "text",
    sm: 6,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    sm: 6,
  },
  {
    name: "phone",
    label: "Phone",
    type: "number",
    sm: 6,
  },
  {
    name: "city",
    label: "City",
    type: "text",
    sm: 6,
  },
  {
    name: "state",
    label: "State",
    type: "text",
    sm: 6,
  },
  {
    name: "pincode",
    label: "Pincode",
    type: "text",
    sm: 6,
  },
  {
    name: "address",
    label: "Address",
    type: "textarea",
    sm: 12,
  },
];

const ShippingInfoColumns = [
  {
    name: "courier_company",
    label: "Courier Company",
    type: "select",
    options: [
      "Ecome express",
      "Xpress bees",
      "Kerry indev",
      "DTDC",
      "Delivery",
      "Maruti",
      "blue dart",
      "Amazon surface",
      "Amazon",
      "Flipkart",
    ],
    sm: 6,
  },
  {
    name: "doc_no",
    label: "Doc No",
    type: "text",
    sm: 6,
  },
  {
    name: "payment_method",
    label: "Payment Method",
    type: "select",
    options: ["cod", "prepaid"],
    sm: 6,
  },
  {
    name: "advance_payment",
    label: "Advance Payment",
    type: "number",
    sm: 6,
  },
  {
    name: "pickup_date",
    label: "Pickup Date",
    type: "date",
    sm: 6,
  },

  {
    name: "delivered_date",
    label: "Delivered Date",
    type: "date",
    sm: 6,
  },
  {
    name: "note",
    label: "Note",
    type: "textarea",
    sm: 12,
  },
];

const StatusInfoColumns = [
  {
    name: "status",
    label: "Order Status",
    type: "select",
    options: [
      "Processing",
      "Delivered",
      "RTO Delivered",
      "Replacement",
      "Refunded",
    ],
    sm: 6,
  },
  {
    name: "order_date",
    label: "Order Date",
    type: "date",
    sm: 6,
  },
  {
    name: "amount_paid",
    label: "Total Paid Amount ",
    type: "number",
    sm: 6,
  },
  {
    name: "refund_date",
    label: "Refund Date",
    type: "date",
    sm: 6,
  },
  {
    name: "replacement_date",
    label: "Replacement Date",
    type: "date",
    sm: 6,
  },
];

function OrderEdit() {
  let { orderId } = useParams();
  // Create a ref
  const orderTabRef = useRef();
  const [data, setData] = useState({});

  const handleSubmit = async () => {
    orderTabRef.current.handleSubmit()
  }
  const fetchOrder = async (orderId) => {
    await API.get(`/order/${orderId}`)
      .then((response) => {
        if (response.data.code === 200) {
          let res = response.data.data;
          setData({ ...res });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  const handleUpdate = async (values) => {
    await API.patch(`/order/${values._id}`, values)
      .then((response) => {
        if (response.data.success) {
          toast.success("Updated Successfully");
          fetchOrder(orderId);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finally");
      });
  };

  useEffect(() => {
    fetchOrder(orderId);
  }, [orderId]);

  return (
    <>
      <ToastContainer />
      <PageTitle
        title="Edit Order"
        button={
          <>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </>
        }
      />

      <OrderTabs
        ref={orderTabRef}
        orderObject={data}
        handleUpdate={handleUpdate}
        productList={productList}
        CustomerInfoColumns={CustomerInfoColumns}
        ShippingInfoColumns={ShippingInfoColumns}
        StatusInfoColumns={StatusInfoColumns}
      />
    </>
  );
}

export default OrderEdit;
