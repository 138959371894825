import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";

const columns = [
  {
    name: "product_name",
    label: "Product",
    type: "select",
    options: ["White Chesto", "Blue Chesto", "Gray Chesto"],
    sm: 6,
  },
  {
    name: "qty",
    label: "Quantity",
    type: "number",
    sm: 6,
  },
  {
    name: "price",
    label: "Price",
    type: "number",
    sm: 6,
  },
  {
    name: "payment_method",
    label: "Payment Method",
    type: "select",
    options: ["Cash", "Credit Card", "Debit Card", "Paypal", "Other"],
    sm: 6,
  },
  {
    name: "billing_customer_name",
    label: "customer Name",
    type: "text",
    sm: 6,
  },
  {
    name: "billing_phone",
    label: "customer Phone",
    type: "number",
    sm: 6,
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    sm: 6,
  },
  {
    name: "address_line_1",
    label: "Address Line 1",
    type: "text",
    sm: 6,
  },
  {
    name: "address_line_2",
    label: "Address Line 2",
    type: "text",
    sm: 6,
  },
  {
    name: "pin_code",
    label: "Pin Code",
    type: "number",
    sm: 6,
  },
  {
    name: "remark",
    label: "Remark",
    type: "textarea",
    sm: 12,
  },
];

const EquipmentSchema = Yup.object().shape({
  equipment_name: Yup.string()
    .min(3, "Equipment Name must be 3 characters at minimum")
    .required("Equipment Name is required"),

  price: Yup.string()
    .min(1, "Price must be 3 characters at minimum")
    .required("Price is required"),
  qty: Yup.string().required("Quantity  is required"),
  categories: Yup.string().required("Categories  is required"),
  unit: Yup.string().required("Unit is required"),
  Vendor_id: Yup.string().required("Vendor id is required"),
  purchase_date: Yup.string().required("Purchase Date is required"),
});

function OrderModal(props) {
  const { fields } = props;

  let formik = useFormik({
    initialValues: fields,
    validationSchema: EquipmentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {columns.map((column) => {
          if (
            column.type === "text" ||
            column.type === "number" ||
            column.type === "textarea"
          ) {
            return (
              <Grid item xs={12} sm={column.sm}>
                <TextField
                  fullWidth
                  name={column.name}
                  label={column.label}
                  variant="outlined"
                  type={column.type}
                  value={formik.values[column.name]}
                  onChange={formik.handleChange}
                  multiline={column.type === "textarea" ? true : false}
                  minRows={column.type === "textarea" ? 3 : 1}
                  error={
                    formik.touched[column.name] &&
                    Boolean(formik.errors[column.name])
                  }
                  helperText={
                    formik.touched[column.name] && formik.errors[column.name]
                  }
                />
              </Grid>
            );
          }
          if (column.type === "select") {
            return (
              <Grid item xs={12} sm={column.sm}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    {column.label}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={column.label}
                    value={formik.values[column.name]}
                    onChange={(e, value) => {
                      console.log(e, value);
                      formik.handleChange(column.name);
                    }}
                    type={column.type}
                    error={
                      formik.touched[column.name] &&
                      Boolean(formik.errors[column.name])
                    }
                    helperText={
                      formik.touched[column.name] && formik.errors[column.name]
                    }
                  >
                    {column.options.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          }

          return "";
        })}
      </Grid>

      <Grid
        style={{
          width: "100%",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          type="submit"
          size="medium"
          color="secondary"
        >
          {/* {modal === CONSTANTS.ADD ? 'Add ' : "Edit"} */}
          Make An Order
        </Button>
      </Grid>
    </form>
  );
}

export default OrderModal;
